// TODO fix disabled eslint
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Warning } from '@material-ui/icons';
import { Callout } from 'motif';
import Loader from '../../Loader';
import FormLoader from './FormLoader';
import { getQuestionnaireResponses } from '../../../actions/questionnaire-response';
import { usePracticeContext } from '../../App/practiceContext';
import { getPatientProfile } from '../../../actions/patient';
import * as Analytics from '../../../actions/analytics';
import { flattenFormItems } from '../../Helpers/utils';

const usePatient = () => {
  const [patient, setPatient] = useState({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    getPatientProfile()
      .then((p) => {
        setPatient(p);
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        setErrors(errors => [...errors, `Can't load form: ${err}`]);
      });
  }, []);

  return {
    patient,
    loading,
    errors,
  };
};

const useQuestionnaireResponse = ({ id: patientId }) => {
  const { publicProfile } = usePracticeContext();
  const [questionnaireResponse, setQuestionnaireResponse] = useState(undefined);
  const [errors, setErrors] = useState([]);
  const emptyQuestionnaireResponse = {
    patientId,
    item: [],
  };

  useEffect(() => {
    if (!publicProfile) return;
    if (!patientId) return;
    let isSubscribed = true;

    getQuestionnaireResponses(publicProfile.tenantId, patientId)
      .then((questionaireResponses) => {
        if (!isSubscribed) return null;
        if (questionaireResponses.length === 0) {
          // no existing questionnaireResponse, make a new one
          setQuestionnaireResponse(emptyQuestionnaireResponse);
        } else {
          if (questionaireResponses.length > 1) {
            setErrors([...errors, 'Multiple questionnaire responses found.']);
          }

          setQuestionnaireResponse(questionaireResponses[0]);
        }
      }).catch((err) => {
        if (!isSubscribed) return null;
        setErrors(errors => [...errors, `Couldnt load response: ${err}`]);
      });

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicProfile, patientId]);

  return { questionnaireResponse, errors };
};


/**
 * ResponseLoader fetches the questionnaire based on an ID provided by the route
 */
const ResponseLoader = ({
  formItems, flattenFormItemsCursor,
}) => {
  const { patient, errors: patientErrors } = usePatient();
  const { questionnaireResponse, errors: qrErrors } = useQuestionnaireResponse(patient);

  React.useEffect(() => {
    if (flattenFormItemsCursor === -1) {
      return;
    }
    if (flattenFormItems(formItems)[flattenFormItemsCursor]) {
      const eventName = flattenFormItems(formItems)[flattenFormItemsCursor].text.split(' ').join(' ');
      Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.FORM, eventName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flattenFormItemsCursor]);

  if (patientErrors.length > 0 || qrErrors.length > 0) {
    return (
      <>
        {
          [...patientErrors, ...qrErrors].map(err => (
            <Callout
              type="error"
              title={err}
              key={err}
              icon={<Warning />}
            />
          ))
        }
      </>
    );
  }

  if (!questionnaireResponse || !patient || !patient.id) {
    return <Loader />;
  }

  return (
    <div>
      <FormLoader
        questionnaireResponse={questionnaireResponse}
        patient={patient}
        patientId={patient.id}
      />
    </div>
  );
};

ResponseLoader.propTypes = {
  formItems: PropTypes.instanceOf(Array),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  flattenFormItemsCursor: PropTypes.number,
};

ResponseLoader.defaultProps = {
  formItems: [],
  flattenFormItemsCursor: 0,
};

const mapStateToProps = state => ({
  formItems: state.page.formItems,
  flattenFormItemsCursor: state.page.flatten_form_items_cursor,
});

export default connect(mapStateToProps)(ResponseLoader);
