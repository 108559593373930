import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import EnsureLoggedIn from './EnsureLoggedIn';
import AppBar from '../Layout/AppBar';

const ProtectedRoute = ({ render: Component, ...rest }) => (
  <Route
    {...rest}
    render={renderProps => (
      <AppBar {...renderProps}>
        <EnsureLoggedIn location={renderProps.location}>
          <Component {...renderProps} />
        </EnsureLoggedIn>
      </AppBar>
    )}
  />
);
ProtectedRoute.propTypes = {
  render: PropTypes.func.isRequired,
};

export default ProtectedRoute;
