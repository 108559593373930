// TODO - fix disabled eslint
/* eslint-disable consistent-return */
import moment from 'moment';
import { FORMATS, ROOT_SCHEMA, PAGE_LINKID } from '../../Helpers/constants';

const currentPageExtensionUrl = 'http://www.geniesolutions.com.au/QuestionnaireResponseCurrentPage.json';
const currentPanelExtensionUrl = 'http://www.geniesolutions.com.au/QuestionnaireResponseCurrentPanel.json';

const byLinkId = linkId => item => item.linkId === linkId;

const valueType = (type) => {
  switch (type) {
    case 'boolean': return 'valueBoolean';
    case 'decimal': return 'valueDecimal';
    case 'integer': return 'valueInteger';
    case 'date': return 'valueDate';
    case 'dateTime': return 'valueDateTime';
    case 'time': return 'valueTime';
    case 'string': return 'valueString';
    case 'text': return 'valueString';
    case 'url': return 'valueUri';
    case 'choice': return 'valueCoding';
    case 'attachment': return 'valueAttachment';
    case 'reference': return 'valueReference';
    case 'quantity': return 'valueQuantity';

    // eslint-disable-next-line no-console
    default: console.error(`Unknown QuestionnaireResponse type: ${type}`);
  }
};

const findExtension = (questionnaireResponse, url) => {
  if (!questionnaireResponse.extension) return {};
  return questionnaireResponse.extension.find(ext => ext.url === url) || {};
};

export const findCurrentPage = questionnaireResponse => findExtension(questionnaireResponse, currentPageExtensionUrl).valueInteger;
export const findCurrentPanel = questionnaireResponse => findExtension(questionnaireResponse, currentPanelExtensionUrl).valueInteger;

/**
 * Transform formData for a single question into FHIR item responses
 * @param  {Object} formData
 * @param  {Object} questionnaire
 * @return {Array}               Array of FHIR QuestionnaireResponse items
 */
const formAnswerToFhir = (formData, questionnaireItem) => {
  if (!formData || (!formData[questionnaireItem.text] && questionnaireItem.type !== 'boolean')) return [];
  let fieldValue = formData[questionnaireItem.text];
  if (questionnaireItem.type === 'date' && fieldValue) {
    // ensure date format is ISO_DATE
    const fieldMomentValue = moment(fieldValue, [FORMATS.ISO_DATE, FORMATS.DATE]);
    fieldValue = fieldMomentValue.isValid() ? fieldMomentValue.format(FORMATS.ISO_DATE) : '';
  }

  const questionnaireResponseItem = {
    linkId: questionnaireItem.linkId,
  };

  if (questionnaireItem.type === 'group') {
    if (questionnaireItem.repeats) {
      const multipleResponseItems = [];

      let answerItem;
      fieldValue.forEach((resp) => {
        answerItem = {
          linkId: questionnaireItem.linkId,
          item: [],
        };
        questionnaireItem.item.forEach((qi) => {
          answerItem.item.push(...formAnswerToFhir(resp, qi));
        });
        multipleResponseItems.push(answerItem);
      });
      return multipleResponseItems;
    }
    questionnaireItem.item.forEach((qi) => {
      questionnaireResponseItem.item = [
        ...(questionnaireResponseItem.item || []),
        ...formAnswerToFhir(formData[questionnaireItem.text], qi),
      ];
    });
  } else if (questionnaireItem.repeats && Array.isArray(fieldValue)) {
    questionnaireResponseItem.answer = fieldValue.map(fv => ({ [valueType(questionnaireItem.type)]: fv }));
  } else {
    questionnaireResponseItem.answer = [{ [valueType(questionnaireItem.type)]: fieldValue }];
  }
  return [questionnaireResponseItem];
};

/**
 * Convert an array of FHIR answers to a single question into a JS object
 * Supports recursion through a tree of `group` items.
 * @param  {Array} questionnaireResponseItems  Array of responses to a single question (all have the same linkId)
 * @param  {Object} questionnaireItem
 * @return {Object}                            Object with key of questionnaire item text, values of questionnaireResponse values, nested objects for groups
 */
export const fhirAnswerToForm = (questionnaireResponseItems, questionnaireItem) => {
  const formData = {};

  if (questionnaireItem.repeats) {
    formData[questionnaireItem.text] = [];

    if (questionnaireItem.type === 'group') {
      questionnaireResponseItems.forEach((qri, index) => {
        questionnaireItem.item.forEach((qi) => {
          formData[questionnaireItem.text][index] = {
            ...formData[questionnaireItem.text][index],
            ...fhirAnswerToForm(qri.item.filter(byLinkId(qi.linkId)), qi),
          };
        });
      });
    } else {
      questionnaireResponseItems.forEach((qri) => {
        qri.answer.forEach((qria) => {
          formData[questionnaireItem.text].push(qria[valueType(questionnaireItem.type)]);
        });
      });
    }
  } else {
    questionnaireResponseItems.forEach((questionnaireResponseItem) => {
      if (questionnaireItem.type === 'group') {
        questionnaireItem.item.forEach((qi) => {
          formData[questionnaireItem.text] = {
            ...formData[questionnaireItem.text],
            ...fhirAnswerToForm(questionnaireResponseItem.item.filter(byLinkId(qi.linkId)), qi),
          };
        });
      } else if (questionnaireItem.type === 'date') {
        // date values include time, e.g. 2019-01-02T00:00:00.000Z, but DatePicker expects ISO_DATE only
        formData[questionnaireItem.text] = moment(questionnaireResponseItem.answer[0].valueDate, FORMATS.ISO_8601).format(FORMATS.ISO_DATE);
      } else {
        formData[questionnaireItem.text] = questionnaireResponseItem.answer[0][valueType(questionnaireItem.type)];
      }
    });
  }
  return formData;
};

/**
 * This will dynamically match questionnaire items with form fields.
 * It assumes all questionnaire items have `text` properties with the same name as form fields `name` props.
 * @param  {Object} questionnaireResponse
 * @param  {Object} questionnaire
 * @param  {Object} formDefaultValues     These are added to the returned formObject, and may be overwritten by the FHIR values
 * @return {Object}                       Keys are field names, values are values
 */
export const fhirToForm = (questionnaireResponse, questionnaire, formDefaultValues) => {
  if (!questionnaireResponse || !questionnaire) return {};

  const initialFormData = {
    ...formDefaultValues,
    status: questionnaireResponse.status, // status is not a questionnaire item, but must be retained
    current_page: findCurrentPage(questionnaireResponse),
    current_panel: findCurrentPanel(questionnaireResponse),
  };

  const getFormDataForQuestionnaireResponse = (formData, questionnaireItem) => {
    const responseItems = questionnaireResponse.item.filter(byLinkId(questionnaireItem.linkId));
    if (!responseItems.length) return formData;
    const formDataForResponses = fhirAnswerToForm(responseItems, questionnaireItem);
    return { ...formData, ...formDataForResponses };
  };

  const formDataForQuestionnaireResponse = questionnaire.item.reduce(getFormDataForQuestionnaireResponse, {});
  return { ...initialFormData, ...formDataForQuestionnaireResponse };
};

/**
 * Convert form data object into FHIR-compatible json payload.
 * It assumes all formData keys corrspond to questionnaire items `text` properties.
 * @param  {Object} formData
 * @param  {Object} questionnaire
 * @return {Object}
 */
export const formToFhir = (formData, questionnaire, patientId) => {
  if (!formData || !questionnaire || !patientId) return;

  const payload = {
    authored: '2019-04-26T13:40:49+10:00',
    status: formData.status,
    questionnaire: questionnaire.url,
    source: { reference: `Patient/${patientId}` },
    extension: [
      {
        url: 'http://www.geniesolutions.com.au/QuestionnaireResponseCurrentPage.json',
        valueInteger: formData.current_page,
      },
      {
        url: 'http://www.geniesolutions.com.au/QuestionnaireResponseCurrentPanel.json',
        valueInteger: formData.current_panel,
      },
    ],
    item: questionnaire.item.reduce((acc, questionnaireItem) => [...acc, ...formAnswerToFhir(formData, questionnaireItem)], []),
  };

  return payload;
};

/**
 * Shape the form schema based on questionaire
 * and public profile
 *
 * @param {Object} questionnaire Questionnaire object, the core questionnaire will stay here
 * @param {Object} publicProfile public profile from practice preference
 *
 * @returns {Object}
 */

export const processFormItems = (questionnaire, publicProfile) => {
  const formItems = [...ROOT_SCHEMA.item];

  const {
    enableTelehealthSection = false,
    enableAttachmentUpload = false,
    enableCustomQuestionsSection = false,
    financialConsentShortText = '',
    financialConsentMainText = '',
  } = publicProfile;

  // Medical page
  const genieInstanceId = questionnaire.identifier && questionnaire.identifier.find(iden => iden.system === 'genieInstanceId');
  const clinicalQuestions = questionnaire.item.find(item => item.linkId === PAGE_LINKID.medicalPage);
  if (clinicalQuestions && enableCustomQuestionsSection && genieInstanceId.value !== '_GLOBAL') {
    // Find enabled clinical questions
    // If enableWhen does not exists or answer value does not exists,
    // this will be default to display the item
    const clinicalQuestionEnabledItems = clinicalQuestions.item.map((cq) => {
      const { enableWhen } = cq;
      if (!enableWhen) {
        return cq;
      }

      const display = enableWhen.find(ew => ew.question === 'display');
      if (display.valueBoolean === undefined) {
        return cq;
      }

      if (display.valueBoolean) {
        return cq;
      }

      return undefined;
    }).filter(enabledQuestion => enabledQuestion !== undefined);

    const medicalItem = {
      type: 'group',
      linkId: 'medical',
      text: 'Medical',
      item: [
        {
          type: 'group_generate',
          linkId: 'medical_information',
          text: 'Medical Information',
          item: clinicalQuestionEnabledItems,
        },
      ],
    };
    formItems.push(medicalItem);
  }

  // Additional info
  const additionalInfoItems = [];
  if (enableTelehealthSection) {
    additionalInfoItems.push({
      linkId: 'telehealth',
      type: 'group',
      text: 'Telehealth',
    });
  }

  if (enableAttachmentUpload) {
    additionalInfoItems.push({
      linkId: 'additional_attachments',
      type: 'group',
      text: 'Additional Attachments',
    });
  }

  additionalInfoItems.push({
    linkId: 'privacy_panel',
    type: 'group',
    text: 'Privacy Consent',
  });

  // When Dynamo returns, sometimes it can have a string which has a space.
  // This allows us to correctly check if it's empty. :)
  if (Boolean(financialConsentShortText.trim()) && Boolean(financialConsentMainText.trim())) {
    additionalInfoItems.push({
      linkId: 'fee_panel',
      type: 'group',
      text: 'Financial Consent',
    });
  }

  if (additionalInfoItems.length !== 0) {
    formItems.push({
      type: 'group',
      linkId: 'additional_info',
      text: 'Additional info',
      item: additionalInfoItems,
    });
  }

  return formItems;
};
