import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import pageReducer from './page';
import practicePreferencesReducer from './practice-preferences';

const rootReducer = combineReducers({
  router: routerReducer,
  form: formReducer,
  page: pageReducer,
  practicePreferences: practicePreferencesReducer,
});

export default rootReducer;
