import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ProgressSteps from '../common/ProgressSteps';
import PageComponent from '../common/pageComponentContainer';
import PatientDemographicsComplete from './components/ThankYou';
import styles from '../common/styles.module.scss';

const PatientDemographicsForm = ({
  onSubmit,
  initialValues,
  formData,
  submitButtonState,
  currentPage,
  formItems,
}) => {
  const isComplete = (currentPage === formItems.length) || initialValues.status === 'completed';
  const totalPages = formItems.length;
  const steps = formItems.map((p, index) => ({
    name: p.text,
    isCurrent: index === currentPage,
    isComplete: index < currentPage,
  }));

  const formPanels = isComplete ? [] : formItems[currentPage].item;

  return (
    <div className={styles.formWithActionBar}>
      {isComplete
        ? <PatientDemographicsComplete />
        : (
          <Fragment>
            <ProgressSteps steps={steps} />
            <PageComponent
              initialValues={initialValues}
              onSubmit={onSubmit}
              formData={formData}
              submitButtonState={submitButtonState}
              totalPages={totalPages}
              formPanels={formPanels}
            />
          </Fragment>
        )
      }
    </div>
  );
};

PatientDemographicsForm.propTypes = {
  onSubmit: PropTypes.func,
  formData: PropTypes.instanceOf(Object),
  initialValues: PropTypes.instanceOf(Object),
  currentPage: PropTypes.number,
  formItems: PropTypes.instanceOf(Array),
  submitButtonState: PropTypes.string,
};

PatientDemographicsForm.defaultProps = {
  onSubmit: () => {},
  formData: {},
  initialValues: {},
  formItems: [],
  currentPage: undefined,
  submitButtonState: undefined,
};

export default PatientDemographicsForm;
