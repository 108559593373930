import {
  emailRule,
  passwordRule,
  requiredOnlyPasswordRule,
  datePickerDobRule,
  agreeConditionsRule,
  confirmationCodeRule,
  verificationCodeRule,
} from './rules';

const invalidRule = rule => !rule.valid;

const groupByField = (fields) => {
  if (fields.length === 0) return undefined;
  return fields.reduce((acc, { field, message }) => ({ ...acc, ...{ [field]: (acc[field] || []).concat({ message }) } }), {});
};

/**
 * Applies the rules to the specified form input
 */
const validateFormFieldRule = (fieldName, formInput, rule) => {
  const field = fieldName;

  let message;
  let valid = true;

  if (rule.validRegex && (!formInput[field] || !formInput[field].match(rule.validRegex))) {
    valid = false;
  } else if (rule.invalidRegex && (formInput[field] && formInput[field].match(rule.invalidRegex))) {
    valid = false;
  } else if (rule.func && !rule.func(formInput)) {
    valid = false;
  }

  if (!valid) {
    message = rule.message; // eslint-disable-line prefer-destructuring
  }

  return { valid, field, message };
};

/**
 * Validate the form input with the specified rule
 */
const validateFormField = (formInput, fieldRule) => {
  const field = fieldRule.name;
  if (fieldRule.required && (!formInput[field] || formInput[field].length === 0)) {
    return ({ valid: false, field, message: `${fieldRule.description} is required` });
  } else if (fieldRule.rules) {
    const validatedField = fieldRule.rules.map(rule => validateFormFieldRule(field, formInput, rule));
    return validatedField.filter(invalidRule);
  }
  return { valid: true, field };
};

/**
 * Validate a form field value with the specified rule
 * Returns an error message string, or undefined
 */
export const validateFormValue = (value, fieldName, fieldRule) => {
  if (fieldRule.required && (!value)) {
    return `${fieldRule.description} is required`;
  } else if (fieldRule.rules) {
    const validatedField = fieldRule.rules.map(rule => validateFormFieldRule(fieldName, { [fieldName]: value }, rule));
    const invalidRules = validatedField.filter(invalidRule);

    if (invalidRules.length === 0) {
      return undefined;
    }
    return invalidRules.reduce((acc, r) => `${acc}${r.message}\n`, '');
  }
  return undefined;
};

/**
 * Validates a form with inputs (formInputs) and the rules to apply (fieldRules)
 */
export const validateForm = (formInput, fieldRules) => {
  const validate = (invalidRules, fieldRule) => invalidRules.concat(validateFormField(formInput, fieldRule));
  const validatedFields = fieldRules.reduce(validate, []);
  return validatedFields.filter(invalidRule);
};

/**
 * Validates the SignIn form with specified rules
 */
export const validateSignIn = (formInput) => {
  const fieldRules = [emailRule, requiredOnlyPasswordRule];
  const invalidInput = validateForm(formInput, fieldRules);
  return {
    valid: invalidInput.length === 0,
    errors: groupByField(invalidInput),
  };
};

/**
 * Validates the SignUp form with specfied rules.
 */
export const validateSignUp = (formInput) => {
  const fieldRules = [
    datePickerDobRule,
    agreeConditionsRule,
  ];

  const invalidInput = validateForm(formInput, fieldRules);
  return {
    valid: invalidInput.length === 0,
    errors: groupByField(invalidInput),
  };
};

export const isBase64 = (str) => {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return base64regex.test(str);
};

export const validateConfirmSignUp = (formInput) => {
  const fieldRules = [
    confirmationCodeRule,
  ];

  const invalidInput = validateForm(formInput, fieldRules);
  return {
    valid: invalidInput.length === 0,
    errors: groupByField(invalidInput),
  };
};

/**
 * Forgot password submit form has a `code` and a new `password`
 */
export const validateForgotPasswordSubmit = (formInput) => {
  const fieldRules = [
    verificationCodeRule,
    passwordRule,
  ];

  const invalidInput = validateForm(formInput, fieldRules);
  return {
    valid: invalidInput.length === 0,
    errors: groupByField(invalidInput),
  };
};

/**
 * Forgot password Send form has a `username` only
 */
export const validateForgotPasswordSend = (formInput) => {
  const fieldRules = [
    emailRule,
  ];

  const invalidInput = validateForm(formInput, fieldRules);
  return {
    valid: invalidInput.length === 0,
    errors: groupByField(invalidInput),
  };
};
