import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import Hidden from '@material-ui/core/Hidden';
import { FormGrid, Typography } from 'motif';
import SuburbField from '../SuburbField';
import { renderYesNoSwitch, renderInput } from '../../../../Helpers/reduxFormMotif';
import {
  validNotRequiredPhoneNumber,
  validName,
  maxLength,
  required,
} from '../../../../Validator/rules';

const UsualGP = ({ formData, change }) => {
  if (!formData.usual_gp) return null;
  const hasUsualGP = formData.usual_gp.has_usual_gp;

  const handleHasUsualGPChange = (changeState) => {
    if (changeState) return;
    // void the medicare card info if the user doesn't have a card
    Object.keys(formData.usual_gp).forEach(key => change(`usual_gp.${key}`, null));
  };

  const handleUsualGPSuburbSelect = ({ locality } = {}) => {
    change('usual_gp.suburb', locality);
  };

  return (
    <FormSection name="usual_gp">
      <FormGrid container>
        <FormGrid item xs={12}>
          <Typography>
            Do you have a usual GP?
          </Typography>
        </FormGrid>
        <FormGrid item xs={12}>
          <Field
            component={renderYesNoSwitch}
            name="has_usual_gp"
            onChange={handleHasUsualGPChange}
          />
        </FormGrid>
        { hasUsualGP &&
          <Fragment>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="first_name"
                component={renderInput}
                label="GP first name"
                validate={[maxLength(30), validName]}
              />
            </FormGrid>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="last_name"
                component={renderInput}
                label="GP last name"
                validate={[maxLength(30), validName]}
              />
            </FormGrid>
            <Hidden only="xs"><FormGrid item sm={4} /></Hidden>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="practice_name"
                component={renderInput}
                label="Practice name"
                required
                validate={[required]}
              />
            </FormGrid>
            <FormGrid item xs={12} sm={4}>
              <SuburbField
                name="suburb"
                validate={[required]}
                onSelect={handleUsualGPSuburbSelect}
                searchInputProps={{
                        label: 'Suburb',
                        required: true,
                    }}
              />
            </FormGrid>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="phone_number"
                component={renderInput}
                label="Phone"
                type="tel"
                validate={[validNotRequiredPhoneNumber]}
              />
            </FormGrid>
          </Fragment>
        }
        <Hidden only="xs"><FormGrid item sm={6} /></Hidden>
      </FormGrid>
    </FormSection>
  );
};
UsualGP.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
};

UsualGP.defaultProps = {
  formData: {},
};

export default UsualGP;
