import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import moment from 'moment';
import Hidden from '@material-ui/core/Hidden';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import {
  FormGrid,
  TertiaryButton,
} from 'motif';
import { renderInput, renderDatePicker } from '../../../../Helpers/reduxFormMotif';
import { isMobile } from '../../../../Helpers/phoneNumbers';
import formStyles from '../../../common/styles.module.scss';
import {
  firstNameRule,
  lastNameRule,
  notRequiredPickerDobRule,
  phoneNumberRule,
  required,
} from '../../../../Validator/rules';
import { validateFormValue } from '../../../../Validator/index';
import { FORMATS } from '../../../../Helpers/constants';

const maxDOB = moment();
const minDOB = moment().subtract(120, 'years');
const validFirstName = value => validateFormValue(value, 'given_name', firstNameRule);
const validLastName = value => validateFormValue(value, 'family_name', lastNameRule);
const validDob = value => validateFormValue(value, 'birthdate', notRequiredPickerDobRule);
const validPhoneNumber = value => validateFormValue(value, 'phone_number', phoneNumberRule);

const renderEmergencyContact = ({ fields, change }) => {
  const handleChangeContactNumber = contact => (value) => {
    const useTypeForPhoneNumber = isMobile(value) ? 'mobile' : 'home';
    change(`${contact}.phone[0].use`, useTypeForPhoneNumber);
  };

  return (
    <Fragment>
      {fields.map((contact, index, allFields) => (
        <Fragment key={contact}>
          <FormGrid item xs={12} sm={4}>
            <Field
              name={`${contact}.given_name`}
              component={renderInput}
              label="First name"
              validate={[required, validFirstName]}
              autoFocus
              required
            />
          </FormGrid>
          <FormGrid item xs={12} sm={4}>
            <Field
              name={`${contact}.family_name`}
              component={renderInput}
              label="Last name"
              validate={[required, validLastName]}
              required
            />
          </FormGrid>
          <Hidden only="xs"><FormGrid item sm={4} /></Hidden>

          <FormGrid item xs={12} sm={4}>
            <Field
              name={`${contact}.phone[0].number`}
              component={renderInput}
              label="Phone"
              type="tel"
              validate={[required, validPhoneNumber]}
              onChange={handleChangeContactNumber(contact)}
              required
            />
          </FormGrid>
          <Hidden only="xs">
            <FormGrid item xs={12} sm={4}>
              <Field
                name={`${contact}.birthdate`}
                component={renderDatePicker}
                label="Date of birth"
                inputProps={{ id: `contact-dob-${index}` }}
                minDate={minDOB}
                maxDate={maxDOB}
                validate={[validDob]}
              />
            </FormGrid>
          </Hidden>
          <Hidden smUp>
            <FormGrid item xs={12} sm={4}>
              <Field
                name={`${contact}.birthdate`}
                type="tel"
                component={renderInput}
                label="Date of birth"
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                placeholder={FORMATS.DATE}
                noMargin
                validate={[validDob]}
              />
            </FormGrid>
          </Hidden>
          {(index === fields.length - 1) &&
          <FormGrid item xs={12} sm={4} className={formStyles.actions}>
            {(fields.length > 1) &&
              <span className={formStyles.btn}>
                <TertiaryButton
                  onClick={() => fields.remove(index)}
                  data-name={`remove-contact-${index}`}
                  iconLeft={<Close />}
                >
                  Remove
                </TertiaryButton>
              </span>
            }
            <span className={formStyles.btn}>
              <TertiaryButton
                onClick={() => fields.push({})}
                data-name={`add-contact-${index}`}
                iconLeft={<Add />}
              >
                Add another
              </TertiaryButton>
            </span>
          </FormGrid>
            }
          {(index < allFields.length - 1) &&
          <Fragment>
            <FormGrid item xs={12} sm={8}>
              <hr />
            </FormGrid>
            <Hidden only="xs"><FormGrid item sm={4} /></Hidden>
          </Fragment>
            }
        </Fragment>
        ))}
    </Fragment>
  );
};
renderEmergencyContact.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
  change: PropTypes.func,
};
renderEmergencyContact.defaultProps = {
  change: undefined,
};

export default renderEmergencyContact;
