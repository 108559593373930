// TODO - fix disabled eslint
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import moment from 'moment';
import { FORMATS } from '../Helpers/constants';

const tooLongPhoneRegex = /^((\+?61|0)\d{10,})$/;
const tooShortPhoneRegex = /^((\+?61|0)\d{0,8})$/;
const formatPhoneRegex = /^((0|\+?61)[\d\s]+)$/;

const numbersRegex = /^.*[0-9]+.*$/;
const charactersRegex = /^.*[^a-zA-Z0-9'’`-\s]+.*$/;

const emailFormatRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * A rule must be compliant with the following format:
 * name: "username", (required)
 * description: "Email Address", (required)
 * required: true, (optional)
 * rules: [
 *   {
 *     // a function tht return true if valid, false otherwise
 *     func: (e) => has_length(e, 3) && is_numeric(e),
 *     message: "Invalid phone area code"
 *   },
 *   {
 *     // A validating regex
 *     validRegex: /^.*[A-Z]+.*$/,
 *     message: "Your password must contain at least one uppercase letter"
 *   }
 *   {
 *     // An invalidating regex
 *     invalidRegex: /^.*[0-9]+.*$/,
 *     message: "Your first name must not contain numbers"
 *   }
 * ]
 */

export const emailRule = {
  name: 'username',
  description: 'Email Address',
  required: true,
  rules: [
    { validRegex: emailFormatRegex, message: 'Email Address is invalid' },
    { invalidRegex: /^.{80,}$/, message: 'Maximum 80 characters' },
  ],
};

export const passwordRule = {
  name: 'password',
  description: 'Password',
  required: true,
  rules: [
    { validRegex: /^.{8,30}$/, message: 'Your password must be at least 8 characters' },
    { validRegex: /^.*[A-Z]+.*$/, message: 'Your password must contain at least one uppercase letter' },
    { validRegex: /^.*[a-z]+.*$/, message: 'Your password must contain at least one lowercase letter' },
    { validRegex: /^.*[0-9]+.*$/, message: 'Your password must contain at least one numeral' },
    { validRegex: /^.*[@#$-/:-?{-~!"^_`[\]]+.*$/, message: 'Your password must contain at least one symbol' },
    { validRegex: /^[^\s]+$/, message: 'Your password cannot contain spaces' },
  ],
};

export const requiredOnlyPasswordRule = {
  name: 'password',
  description: 'Password',
  required: true,
};

export const firstNameRule = {
  name: 'given_name',
  description: 'First Name',
  rules: [
    { invalidRegex: numbersRegex, message: 'First name must not contain numbers' },
    { invalidRegex: charactersRegex, message: 'First name must contain letters, single quotes, spaces and dashes only' },
    { invalidRegex: /^.{20,}$/, message: 'Maximum 20 characters' },
  ],
};

export const maidenNameRule = {
  name: 'maiden_name',
  description: 'Maiden Name',
  rules: [
    { invalidRegex: numbersRegex, message: 'Maiden name must not contain numbers' },
    { invalidRegex: charactersRegex, message: 'Maiden name must contain letters, single quotes, spaces and dashes only' },
    { invalidRegex: /^.{30,}$/, message: 'Maximum 30 characters' },
  ],
};

export const preferredNameRule = {
  name: 'preferred_name',
  description: 'Preferred Name',
  rules: [
    { invalidRegex: numbersRegex, message: 'Preferred name must not contain numbers' },
    { invalidRegex: charactersRegex, message: 'Preferred name must contain letters, single quotes, spaces and dashes only' },
    { invalidRegex: /^.{20,}$/, message: 'Maximum 20 characters' },
  ],
};

export const middleNameRule = {
  name: 'middle_name',
  description: 'Middle Name',
  rules: [
    { invalidRegex: numbersRegex, message: 'Middle name must not contain numbers' },
    { invalidRegex: charactersRegex, message: 'Middle name must contain letters, single quotes, spaces and dashes only' },
    { invalidRegex: /^.{20,}$/, message: 'Maximum 20 characters' },
  ],
};

export const lastNameRule = {
  name: 'family_name',
  required: true,
  description: 'Last Name',
  rules: [
    { invalidRegex: numbersRegex, message: 'Last name must not contain numbers' },
    { invalidRegex: charactersRegex, message: 'Last name must contain letters, single quotes, spaces and dashes only' },
    { invalidRegex: /^.{30,}$/, message: 'Maximum 30 characters' },
  ],
};

export const mobilePhoneRule = {
  name: 'phone_number',
  description: 'Mobile Phone Number',
  required: true,
  rules: [
    { invalidRegex: /^((\+?614|04)\d{9,})$/, message: 'Invalid mobile phone number' },
    { invalidRegex: /^((\+?614|04)\d{0,7})$/, message: 'Invalid mobile phone number' },
    { validRegex: /^((04|\+?614)[\d\s]+)$/, message: 'Invalid mobile phone number' },
  ],
};

export const phoneNumberRule = {
  name: 'phone_number',
  description: 'Phone Number',
  required: true,
  rules: [
    { invalidRegex: tooLongPhoneRegex, message: 'Invalid phone number' },
    { invalidRegex: tooShortPhoneRegex, message: 'Invalid phone number' },
    { validRegex: formatPhoneRegex, message: 'Invalid phone number' },
  ],
};

export const dobRule = {
  name: 'birthdate',
  description: 'Date of birth',
  required: true,
  rules: [
    {
      func: (e) => {
        if ((e === null) || (e === '') || (!e.birthdate)) return true;
        const dob = e.birthdate.split('/');
        if (dob.length !== 3) return false;
        const year = parseInt(dob[2]);
        const month = parseInt(dob[1]);
        const day = parseInt(dob[0]);
        return day > 0 && day <= 31 && month > 0 && month <= 12 && year > 1900 && year < 2100;
      },
      message: 'Invalid date of birth',
    },
  ],
};

export const datePickerDobRule = {
  name: 'birthdate',
  description: 'Date of birth',
  required: true,
  rules: [
    {
      func: (e) => {
        if ((e === null) || (e === '') || (!e.birthdate)) return true;
        return moment(e.birthdate, [FORMATS.ISO_DATE, FORMATS.DATE], true).isValid();
      },
      message: 'Invalid date of birth',
    },
    {
      func: (e) => {
        if ((e === null) || (e === '') || (!e.birthdate)) return true;
        return moment(e.birthdate, [FORMATS.ISO_DATE, FORMATS.DATE], true).isBefore(moment());
      },
      message: 'Birth date cannot be a future date',
    },
    {
      func: (e) => {
        if ((e === null) || (e === '') || (!e.birthdate)) return true;
        return moment(e.birthdate, [FORMATS.ISO_DATE, FORMATS.DATE], true).isSameOrAfter(moment().subtract(120, 'years'));
      },
      message: `Birth date cannot be an earlier date than ${moment().subtract(120, 'years').year()}`,
    },
    {
      func: (e) => {
        if ((e === null) || (e === '') || (!e.birthdate)) return true;
        return !moment(e.birthdate, [FORMATS.ISO_DATE, FORMATS.DATE], true).isSame(moment(), 'day');
      },
      message: 'Birth date cannot be todays date',
    },

  ],
};

export const notRequiredPickerDobRule = Object.assign({}, datePickerDobRule, { required: false });

export const agreeConditionsRule = {
  name: 'termsandconditions',
  description: 'Agreeing to the terms and conditions',
  required: true,
  rules: [],
};

export const confirmationCodeRule = {
  name: 'code',
  description: 'Confirmation code',
  required: true,
  rules: [],
};

export const verificationCodeRule = {
  name: 'code',
  description: 'Verification code',
  required: true,
  rules: [],
};

export const medicareCardNumberRule = {
  name: 'medicare_card_number',
  description: 'Medicare Card Number',
  required: true,
  rules: [
    {
      func: (field) => {
        const cardNumber = field.medicare_card_number;
        if (!cardNumber) return false;
        const raw = cardNumber.replace(/\s/g, '');
        if (raw.length !== 10) return false;
        const matches = raw.match(/^(\d{8})(\d)/);
        if (!matches || matches.length < 3) return false;
        const base = matches[1];
        const checkDigit = matches[2];
        const weights = [1, 3, 7, 9, 1, 3, 7, 9];
        let sum = 0;
        for (const i in weights) { sum += base[i] * weights[i]; }
        return (sum % 10) === parseInt(checkDigit, 10);
      },
      message: 'Invalid medicare card number',
    },
  ],
};

export const medicareCardReferenceNumberRule = {
  name: 'medicare_card_reference_number',
  description: 'Medicare Card Reference Number',
  required: true,
};

export const medicareCardExpiryDateRule = {
  name: 'medicare_card_expiry_date',
  description: 'Medicare Card Expiry Date',
  required: true,
  rules: [
    {
      func: (field) => {
        const e = field.medicare_card_expiry_date;
        if (e === '' || e === undefined || e === null || e === '__/____') {
          return true;
        }
        const date = moment(e, FORMATS.MONTH_YEAR, true);
        const now = moment();
        return (date.isValid() && date.isAfter(now.clone().subtract(1, 'month')) && date.isBefore(now.clone().add(10, 'year')));
      },
      message: 'Invalid medicare card expiry date',
    },
  ],
};

export const dvaCardNumberRule = {
  name: 'dva_card.card_number',
  description: 'DVA Card Number',
  required: true,
  rules: [
    // https://meteor.aihw.gov.au/content/index.phtml/itemId/339127
    { validRegex: /^[NVQWST][A-Z\s]{1,3}[0-9]{4,6}[A-Z]?$/, message: 'Invalid DVA Card number' },
    { invalidRegex: /^.{10,}$/, message: 'Maximum 10 characters' },
  ],
};

export const dvaCardDisabilityRule = {
  name: 'dva_card.disability',
  description: 'DVA Card Disability',
  required: true,
  rules: [
    { invalidRegex: /^.{100,}$/, message: 'Maximum 100 characters' },
  ],
};

const postcodeRegex = /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/;

export const validAustralianPostcode = value => (!value || value.toString().length !== 4 || !postcodeRegex.test(value.toString()) ? 'Not a valid Australian postcode' : undefined);

export const required = value => (!value || value.length === 0 ? 'Field cannot be empty' : undefined);

export const requiredWithMessage = message => value => (!value || value.length === 0 ? message : undefined);

export const minLength = min => value => (value && value.length < min ? `Must be ${min} characters or more` : undefined);

export const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);

export const validDate = value => (!moment(value, [FORMATS.ISO_DATE, FORMATS.DATE], true).isValid() ? 'Date must be entered in a valid format (DD/MM/YYYY)' : undefined);

export const validNotRequiredPhoneNumber = value => (!value ||
  (!tooLongPhoneRegex.test(value) && !tooShortPhoneRegex.test(value) && formatPhoneRegex.test(value)) ? undefined : 'Invalid phone number (leave blank if unknown)');

export const validName = value => (!value || (value && !numbersRegex.test(value) && !charactersRegex.test(value)) ? undefined : 'name must contain letters, single quotes, spaces and dashes only');

export const validNotRequiredEmail = value => (!value || (value && emailFormatRegex.test(value)) ? undefined : 'must enter a valid email address');

export const concessionCardExpiryRule = {
  name: 'concession_card_expiry_date',
  description: 'Concession Expiry Date',
  required: true,
  rules: [
    { invalidRegex: /^_{2}\/_{2}\/_{4}$/, message: 'Concession card expiry is required' },
    {
      func: (field) => {
        const e = field.concession_card_expiry_date;
        if (e === '' || e === undefined || e === null || e === '__/__/____') {
          return true;
        }
        const date = moment(e, [FORMATS.ISO_DATE, FORMATS.DATE], true);
        const now = moment();
        return (date.isValid() && date.isAfter(now.clone().subtract(1, 'month')) && date.isBefore(now.clone().add(10, 'year')));
      },
      message: 'Invalid concession card expiry date',
    },
  ],
};

export const privateHealthInsuranceNumberRule = {
  name: 'private_health.membership_number',
  description: 'Membership Number',
  required: true,
  rules: [{
    invalidRegex: /^.{20,}$/, message: 'Maximum 20 characters',
  }],
};

export const privateHealthInsuranceSelectRule = (validOptions) => {
  // eslint-disable-next-line no-param-reassign
  validOptions = validOptions.map(option => option.value);

  return {
    name: 'private_health.fund_name',
    description: 'Fund Name',
    required: true,
    rules: [{
      func: field => validOptions.indexOf(field['private_health.fund_name']) >= 0,
      message: 'A health fund must be selected',
    }],
  };
};
