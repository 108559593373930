import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Help from '@material-ui/icons/Help';
import Close from '@material-ui/icons/Close';
import { IconButton, Icon, PrimaryButton } from 'motif';
import inputStyles from '../../../Helpers/input.module.scss';
import styles from './styles.module.scss';
import { preventDefault } from '../../../Auth/helpers';

/**
 * Show a help icon as an InputAdornment with a title, text and (optional) image
 * provided in a Drawer (on mobile) or a Tooltip on desktop
 * @param {string} title
 * @param {string} content
 * @param {string} imageSrc Root-relative path to transparent img file, e.g. `/img/medicare_number.png`
 */
const InputHint = ({ title, content, imageSrc }) => {
  // To support both the Drawer and Tooltip this open state needs to be either:
  // set explicitly, open=true, Tooltip/Drawer are locked open
  // not set, open=undefined, Tooltip is uncontrolled, Drawer is closed
  // if the Tooltip is explicitly opened, a close button is shown, otherwise it will close on its own
  const [open, setOpen] = useState(undefined);
  const handleOpen = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (open) {
      setOpen(undefined);
    } else {
      setOpen(true);
    }
  };
  const handleClose = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setOpen(undefined);
  };

  const getContent = () => (
    <div className={styles.content}>
      {open &&
        <Hidden xsDown>
          <IconButton onClick={handleClose} className={styles.close}><Close /></IconButton>
        </Hidden>
      }
      <Grid container spacing={8}>
        <Grid item xs>
          <h6 className={styles.title}>{title}</h6>
          <p className={styles.body}>{content}</p>
        </Grid>
        {imageSrc &&
          <Grid item>
            <img
              className={styles.imgRefNumber}
              src={imageSrc}
              alt={title}
            />
          </Grid>
        }
      </Grid>
    </div>
  );

  return (
    <InputAdornment position="end" className={inputStyles.endAdornment}>
      <Hidden xsDown>
        <Tooltip
          title={getContent()}
          id="popover"
          classes={{
            popper: styles.tooltipRoot,
            tooltip: styles.tooltip,
          }}
          {...(open ? { open } : {})}
          PopperProps={(open ? { open } : {})}
          interactive
        >
          <IconButton onTouchStart={preventDefault(handleOpen)}>
            <Icon component={Help} color="grey" />
          </IconButton>
        </Tooltip>
      </Hidden>
      <Hidden smUp>
        <IconButton onClick={handleOpen}>
          <Help />
        </IconButton>
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          PaperProps={{ classes: { root: styles.drawerRoot } }}
          id="popover"
        >
          {getContent()}
          <PrimaryButton onClick={handleClose} fullWidth>OK</PrimaryButton>
        </Drawer>
      </Hidden>
    </InputAdornment>
  );
};

InputHint.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  imageSrc: PropTypes.string,
};

InputHint.defaultProps = {
  title: '',
  content: '',
  imageSrc: undefined,
};

export default InputHint;
