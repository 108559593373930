import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import Hidden from '@material-ui/core/Hidden';
import { FormGrid, Typography, layout } from 'motif';
import moment from 'moment';
import { FORMATS } from '../../../../Helpers/constants';
import { validateFormValue } from '../../../../Validator/index';
import { renderInvertedYesNoSwitch, renderInput, renderDatePicker } from '../../../../Helpers/reduxFormMotif';
import {
  medicareCardExpiryDateRule, medicareCardNumberRule, medicareCardReferenceNumberRule,
  required, firstNameRule, lastNameRule, datePickerDobRule,
} from '../../../../Validator/rules';
import InputHint from '../../../common/InputHint';

const validMedicareCardNumber = value => validateFormValue(value, 'medicare_card_number', medicareCardNumberRule);
const validMedicareCardReferenceNumber = value => validateFormValue(value, 'medicare_card_reference_number', medicareCardReferenceNumberRule);
const validExpiryDate = value => validateFormValue(value, 'medicare_card_expiry_date', medicareCardExpiryDateRule);
const maxDOB = moment();
const minDOB = moment().subtract(120, 'years');
const validFirstName = value => validateFormValue(value, 'given_name', firstNameRule);
const validLastName = value => validateFormValue(value, 'family_name', lastNameRule);
const validDob = value => validateFormValue(value, 'birthdate', datePickerDobRule);

const AccountHolder = ({ formData, change }) => {
  const hasAccountHolder = formData.account_holder.has_account_holder;

  const handleHasAccountHolderChange = (changeState) => {
    if (changeState) return;
    // void the account_holder info if the user doesn't have a card
    Object.keys(formData.account_holder).forEach(key => change(`account_holder.${key}`, null));
  };

  return (
    <FormSection name="account_holder">
      <FormGrid container className={layout.marginTop}>
        <FormGrid item xs={12}>
          <Typography variant="subtitle1">
            Is the patient responsible for their bills (for medicare rebates)?
          </Typography>
        </FormGrid>
        <FormGrid item xs={12}>
          <Field
            component={renderInvertedYesNoSwitch}
            name="has_account_holder"
            onChange={handleHasAccountHolderChange}
          />
        </FormGrid>
        { hasAccountHolder &&
          <Fragment>
            <FormGrid item xs={12}>
              <Typography>
                Account Holder (Parents/Guardian)
              </Typography>
            </FormGrid>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="given_name"
                component={renderInput}
                label="First name"
                validate={[required, validFirstName]}
                autoFocus
                required
              />
            </FormGrid>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="family_name"
                component={renderInput}
                label="Last name"
                validate={[required, validLastName]}
                required
              />
            </FormGrid>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="birthdate"
                component={renderDatePicker}
                label="Date of birth"
                inputProps={{ id: 'account-holder-dob' }}
                minDate={minDOB}
                maxDate={maxDOB}
                validate={[validDob]}
              />
            </FormGrid>
            <FormGrid item md={5} sm={6} xs={12}>
              <Field
                name="medicare_card_number"
                component={renderInput}
                label="Medicare number"
                required
                mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/]}
                discardMask
                noMargin
                validate={[validMedicareCardNumber]}
                endAdornment={(
                  <InputHint
                    title="Medicare Number"
                    content="You should be able to find this number on the front of your card."
                    imageSrc="/img/medicare_number.png"
                  />
                )}
              />
            </FormGrid>
            <FormGrid item md={3} sm={5} xs={12}>
              <Field
                name="medicare_card_reference_number"
                component={renderInput}
                label="Reference number"
                required
                mask={[/^[1-9]$/]}
                discardMask
                noMargin
                validate={[validMedicareCardReferenceNumber]}
                endAdornment={(
                  <InputHint
                    title="Reference Number"
                    content="You should be able to find this number next to your name."
                    imageSrc="/img/medicare_ref.png"
                  />
                )}
              />
            </FormGrid>
            <FormGrid item sm={4} xs={12}>
              <Field
                name="medicare_card_expiry_date"
                component={renderInput}
                label="Expiry date"
                required
                mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                placeholder={FORMATS.MONTH_YEAR}
                noMargin
                validate={[validExpiryDate]}
              />
            </FormGrid>
          </Fragment>
        }
        <Hidden only="xs"><FormGrid item sm={6} /></Hidden>
      </FormGrid>
    </FormSection>
  );
};
AccountHolder.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func,
};
AccountHolder.defaultProps = {
  formData: undefined,
  change: undefined,
};

export default AccountHolder;

