import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import Hidden from '@material-ui/core/Hidden';
import { FormGrid } from 'motif';
import {
  renderInput,
  renderSelect,
  renderDateMask,
} from '../../../../Helpers/reduxFormMotif';
import { validateFormValue } from '../../../../Validator/index';
import {
  firstNameRule,
  lastNameRule,
  datePickerDobRule,
  emailRule,
  maidenNameRule,
  preferredNameRule,
  middleNameRule,
} from '../../../../Validator/rules';
import renderPhoneNumbers from './phoneNumbers';
import { FORMATS } from '../../../../Helpers/constants';

const validFirstName = value => validateFormValue(value, 'given_name', firstNameRule);
const validLastName = value => validateFormValue(value, 'family_name', lastNameRule);
const validMiddleName = value => validateFormValue(value, 'middle_name', middleNameRule);
const validPreferredName = value => validateFormValue(value, 'preferred_name', preferredNameRule);
const validMaidenName = value => validateFormValue(value, 'maiden_name', maidenNameRule);
const validDob = value => validateFormValue(value, 'birthdate', datePickerDobRule);
const validEmail = value => validateFormValue(value, 'username', emailRule);

// eslint-disable-next-line react/prefer-stateless-function
class PersonalDetailsForm extends Component {
  render() {
    const { change } = this.props;
    return (
      <FormGrid container spacingV={8}>

        <FormGrid item xs={12} sm={2}>
          <Field
            name="title"
            component={renderSelect}
            label="Title"
            data={[
              { name: 'Mr', value: 'Mr' },
              { name: 'Miss', value: 'Miss' },
              { name: 'Mrs', value: 'Mrs' },
              { name: 'Ms', value: 'Ms' },
            ]}
            hideEmpty
          />
        </FormGrid>
        <Hidden only="xs"><FormGrid item sm={10} /></Hidden>

        <FormGrid item xs={12} sm={4}>
          <Field
            name="given_name"
            component={renderInput}
            label="First name"
            validate={[validFirstName]}
            autoComplete="given-name"
          />
        </FormGrid>
        <FormGrid item xs={12} sm={4}>
          <Field
            name="family_name"
            component={renderInput}
            label="Last name"
            required
            validate={[validLastName]}
            autoComplete="family-name"
          />
        </FormGrid>
        <Hidden only="xs"><FormGrid item sm={4} /></Hidden>

        <FormGrid item xs={12} sm={4}>
          <Field
            name="middle_name"
            component={renderInput}
            label="Middle name"
            validate={[validMiddleName]}
            autoComplete="additional-name"
          />
        </FormGrid>
        <FormGrid item xs={12} sm={4}>
          <Field
            name="preferred_name"
            component={renderInput}
            label="Preferred name"
            validate={[validPreferredName]}
            autoComplete="nickname"
          />
        </FormGrid>
        <FormGrid item xs={12} sm={4}>
          <Field
            name="maiden_name"
            component={renderInput}
            label="Maiden name"
            validate={[validMaidenName]}
          />
        </FormGrid>

        <Hidden only="xs">
          <FormGrid item xs={12} sm={4}>
            <Field
              name="birthdate"
              component={renderInput}
              label="Date of birth"
              disabled
              helperText="Contact practice for date of birth enquiries"
              inputProps={{ id: 'dob_input' }}
            />
          </FormGrid>
        </Hidden>

        <Hidden smUp>
          <FormGrid item xs={12} sm={4}>
            <Field
              dateFormat="DD/MM/YYYY"
              name="birthdate"
              type="tel"
              component={renderDateMask}
              label="Date of birth"
              required
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              placeholder={FORMATS.DATE}
              noMargin
              validate={[validDob]}
            />
          </FormGrid>
        </Hidden>

        <FormGrid item xs={12} sm={2}>
          <Field
            name="gender"
            component={renderSelect}
            label="Gender"
            data={[
              { name: 'Male', value: 'male' },
              { name: 'Female', value: 'female' },
              { name: 'Other', value: 'other' },
              { name: 'Unknown', value: 'unknown' },
            ]}
            hideEmpty
          />
        </FormGrid>

        <Hidden only="xs"><FormGrid item sm={5} /></Hidden>

        <FieldArray
          name="phone"
          component={renderPhoneNumbers}
          change={change}
        />

        <FormGrid item xs={12} sm={4}>
          <Field
            name="email"
            component={renderInput}
            label="Email"
            type="email"
            autoComplete="email"
            required
            validate={[validEmail]}
          />
        </FormGrid>

      </FormGrid>
    );
  }
}
PersonalDetailsForm.propTypes = {
  change: PropTypes.func,
};
PersonalDetailsForm.defaultProps = {
  change: undefined,
};

export default PersonalDetailsForm;
