import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'react-router-redux';
import { createBrowserHistory as createHistory } from 'history';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';

const enableLogger = false;

export const history = createHistory();
export const routerMiddleware = createRouterMiddleware(history);

const finalCreateStore = (initialState) => {
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = (process.env.NODE_ENV === 'production' ? null : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = createStore(
    rootReducer,
    { ...initialState },
    composeEnhancers(enableLogger
      ? applyMiddleware(routerMiddleware, thunk, logger)
      : applyMiddleware(routerMiddleware, thunk)),
  );

  return store;
};

export default finalCreateStore;
