import { SET_PAGE, SET_PANEL, SET_PAGE_COUNT, SET_FORM_ITEMS, SET_ANALYTIC_CURSOR } from '../actions/page';

const INITIAL_STATE = { page_count: 0, current_page: 0, current_panel: 0 };

const page = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAGE_COUNT:
      return {
        ...state,
        page_count: action.page_count,
      };

    case SET_PAGE:
      return {
        ...state,
        current_page: (typeof action.page === 'number') ? action.page : 0,
      };

    case SET_PANEL:
      return {
        ...state,
        current_panel: (typeof action.panel === 'number') ? action.panel : 0,
      };

    case SET_FORM_ITEMS:
      return {
        ...state,
        formItems: action.formItems,
      };

    case SET_ANALYTIC_CURSOR:
      return {
        ...state,
        flatten_form_items_cursor: action.flatten_form_items_cursor,
      };

    default:
      return state;
  }
};

export default page;
