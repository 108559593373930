import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGrid } from 'motif';
import Privacy from './privacy';

const PrivacyPanel = ({ formData }) => (
  <Fragment>
    <FormGrid container>
      <FormGrid item xs={12}>
        <Privacy formData={formData} />
      </FormGrid>
    </FormGrid>
  </Fragment>
);

PrivacyPanel.propTypes = {
  formData: PropTypes.instanceOf(Object),
};

PrivacyPanel.defaultProps = {
  formData: {},
};

export default PrivacyPanel;
