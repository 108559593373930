import { API } from '../api';
import config from '../config';

const patientPortalApi = new API(config.PATIENT_PORTAL_API_URL_WITHOUT_PATH);

export const verifyPatientIdentify = (patientId, birthDate) => patientPortalApi.post('/api/v1/Patients/verify', { patientId, birthDate }, false);

export const getPatientRegistered = patientId => patientPortalApi.get(`/api/v1/Patients/${patientId}/registered`, false);

export const getPatientProfile = () => patientPortalApi.get('/api/v1/Patients/me');
