/* eslint-disable react/prop-types */
import React from 'react';
import { Input, Select, TypeableSelect, SearchSelectInput, DatePicker, LabelCheckbox, YesNoSwitch } from 'motif';
import DateMask from '../form/common/dateMask';
import { inputStyleOverrides, selectStyleOverrides, datePickerStyleOverrides, searchSelectInputOverrides, multilineInputStyleOverrides } from './input';

const renderMotifComponent = (Component, overrides) => ({
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <Component
    {...input}
    onChange={e => input.onChange(e.target.value)}
    onBlur={e => input.onBlur(e.target.value)}
    error={touched && invalid}
    helperText={touched ? error : undefined}
    {...custom}
    {...((typeof overrides === 'function') ? overrides({ input, ...custom }) : overrides)}
  />
);

export const renderInputMultiline = renderMotifComponent(Input, multilineInputStyleOverrides);
export const renderInput = renderMotifComponent(Input, inputStyleOverrides);
export const renderSelect = renderMotifComponent(Select, selectStyleOverrides);
export const renderTypeableSelect = renderMotifComponent(TypeableSelect, inputStyleOverrides);
export const renderSearchSelectInput = renderMotifComponent(SearchSelectInput, searchSelectInputOverrides);
export const renderDatePicker = renderMotifComponent(DatePicker, datePickerStyleOverrides);
export const renderDateMask = renderMotifComponent(DateMask, inputStyleOverrides);

export const renderCheckBox = ({
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <LabelCheckbox
    {...input}
    onChange={e => input.onChange(e.target.value)}
    onBlur={e => input.onBlur(e.currentTarget.value)}
    error={touched && invalid}
    helperText={touched ? error : undefined}
    {...custom}
  />
);

export const renderYesNoSwitch = ({
  input,
  ...custom
}) => (
  <YesNoSwitch
    {...input}
    onChange={e => input.onChange(e.target.checked)}
    checked={input.value}
    {...custom}
  />
);

/**
 * This is the same to YesNoSwitch but renders No for value true and Yes for value false
 */
export const renderInvertedYesNoSwitch = ({
  input,
  ...custom
}) => renderYesNoSwitch({
  input: {
    ...input,
    onBlur: () => {}, // onFocus and onBlur cause issue in Chrome
    onFocus: () => {}, // onFocus and onBlur cause issue in Chrome
    value: !input.value,
    onChange: value => input.onChange(!value),
  },
  ...custom,
});
