import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Typography, Hyperlink } from 'motif';
import styles from './styles.module.scss';

const ThankYou = ({ practiceLogoURL, practiceTitle, practiceSocialMediaFacebookURL }) => (
  <div>
    <Panel
      title="Thank you for completing your form"
      variant="grey"
      contentProps={{ className: styles.root }}
    >
      <Typography variant="body1">
        We have received your details and hope that this will make your visit to our practice a more pleasant and relaxing experience. We look forward to seeing you soon.
      </Typography>
      {practiceLogoURL && (
        <img id="practice-logo" src={practiceLogoURL} alt={practiceTitle} width="300" />
      )}
      {practiceSocialMediaFacebookURL && (
        <Typography>
          <Hyperlink id="facebook-link" href={practiceSocialMediaFacebookURL} target="_blank" noUnderline>
            <img src="/img/FindUs-On-Facebook.svg" alt="Don't forget to follow us on Facebook" width="140" />
          </Hyperlink>
        </Typography>
      )}
    </Panel>
  </div>
);
ThankYou.propTypes = {
  practiceLogoURL: PropTypes.string,
  practiceTitle: PropTypes.string,
  practiceSocialMediaFacebookURL: PropTypes.string,
};

ThankYou.defaultProps = {
  practiceLogoURL: undefined,
  practiceTitle: undefined,
  practiceSocialMediaFacebookURL: undefined,
};

export default ThankYou;
