const errorMessageMapping = (err) => {
  // eslint-disable-next-line no-prototype-builtins
  const message = err.hasOwnProperty('message') ? err.message : err;

  if (/incorrect.*username.*password/i.test(message) || /user.*does.*not.*exist/i.test(message)) {
    return 'Your email or password is invalid, please try again';
  }

  if (/Network.*Error/.test(message)) {
    return 'Check your network connection';
  }

  if (/No.*current.*user/.test(message)) {
    return 'Could not authenticate, please login again';
  }

  if (/Username.*client.*id.*combination.*not.*found./.test(message)) {
    return 'We are unable to match your email to an existing patient record. Please try again';
  }

  return message;
};

export default errorMessageMapping;
