import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  layout,
  LabelCheckbox,
  Hyperlink,
} from 'motif';
import config from '../../../../../config';

export const AgreeToTermsField = ({
  practiceSlug, agreedToTerms, changeAgreedToTerms,
}) => {
  const termsAndConditionsLink = `${config.PATIENT_PORTAL_UI_SCHEME}://${practiceSlug}.${config.PATIENT_PORTAL_UI_DOMAIN}/terms/v1/privacy`;

  return (
    <LabelCheckbox
      data-cy="upload-attachment-agreed-to-terms"
      value={agreedToTerms ? 'yes' : ''}
      helperText="You must accept to upload"
      onChange={() => changeAgreedToTerms(!agreedToTerms)}
      disabled={agreedToTerms}
      label={(
        <Typography data-cy="upload-attachment-terms-text" className={layout.fullWidth}>
          I consent to the transmission, upload and storage of these documents and accept the&nbsp;
          <Hyperlink href={termsAndConditionsLink} target="_blank" noUnderline>Terms & Conditions</Hyperlink>
        </Typography>
          )}
    />
  );
};

AgreeToTermsField.propTypes = {
  practiceSlug: PropTypes.string.isRequired,
  agreedToTerms: PropTypes.bool.isRequired,
  changeAgreedToTerms: PropTypes.func.isRequired,
};
