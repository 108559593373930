import React from 'react';
import { Typography, FormGrid } from 'motif';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import { renderCheckBox, renderInput } from '../../../../Helpers/reduxFormMotif';
import {
  validNotRequiredPhoneNumber,
  maxLength,
  required,
  validNotRequiredEmail,
} from '../../../../Validator/rules';

const InsuranceClaim = ({ formData, change }) => {
  if (!formData.insurance_claim) return null;
  const hasInsuranceClaim = formData.insurance_claim.has_claim;

  const handleIsInsuranceClaimChange = () => {
    if (!change) return;
    Object.keys(formData.insurance_claim).forEach(key => change(`insurance_claim.${key}`, null));
  };

  return (
    <FormSection name="insurance_claim">
      <Field
        label="Third party/work cover claim"
        component={renderCheckBox}
        name="has_claim"
        onChange={handleIsInsuranceClaimChange}
      />

      { hasInsuranceClaim &&
      <FormGrid container>
        <FormGrid item md={5} sm={6} xs={12}>
          <Field
            name="insurer"
            component={renderInput}
            label="Insurer"
            noMargin
          />
        </FormGrid>
        <FormGrid item md={5} sm={6} xs={12}>
          <Field
            name="injury"
            component={renderInput}
            label="Injury"
            noMargin
            required
            validate={[required]}
          />
        </FormGrid>
        <FormGrid item md={5} sm={4} xs={12}>
          <Field
            name="claim_number"
            component={renderInput}
            label="Claim number"
            noMargin
          />
        </FormGrid>
        <FormGrid item md={5} sm={5} xs={12}>
          <Field
            name="employer"
            component={renderInput}
            label="Employer"
            noMargin
          />
        </FormGrid>
        <FormGrid item xs={12}>
          <Typography>
            Case manager details
          </Typography>
        </FormGrid>
        <FormGrid item md={5} sm={5} xs={12}>
          <Field
            name="full_name"
            component={renderInput}
            label="Full name"
            noMargin
          />
        </FormGrid>
        <FormGrid item md={5} sm={5} xs={12}>
          <Field
            name="phone_number"
            component={renderInput}
            label="Phone number"
            noMargin
            validate={[validNotRequiredPhoneNumber]}
          />
        </FormGrid>
        <FormGrid item md={5} sm={5} xs={12}>
          <Field
            name="email"
            component={renderInput}
            label="Email"
            noMargin
            validate={[maxLength(80), validNotRequiredEmail]}
          />
        </FormGrid>
      </FormGrid>
    }
    </FormSection>
  );
};

InsuranceClaim.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
};

InsuranceClaim.defaultProps = {
  formData: {},
};

export default InsuranceClaim;
