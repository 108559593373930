import { useEffect, useState } from 'react';
import cookies from 'js-cookie';
import PropTypes from 'prop-types';
import * as sentry from '../App/sentry';
import { useAppContext } from '../App/context';

const CheckLoginStatus = ({ children }) => {
  const [checked, setChecked] = useState(false);
  const { setAuthTokens, cognitoUser } = useAppContext();

  // Check if auth token exists in cookie
  useEffect(() => {
    const cognitoIdToken = cookies.get('cognitoIdToken');
    const cognitoRefreshToken = cookies.get('cognitoRefreshToken');
    const rolesToken = cookies.get('rolesToken');
    if (cognitoIdToken) {
      setAuthTokens({ cognitoIdToken, cognitoRefreshToken, rolesToken });
    }
    setChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set login user to sentry
  useEffect(() => {
    if (cognitoUser) {
      sentry.setUser(cognitoUser.sub);
    }
  }, [cognitoUser]);

  if (!checked) {
    return null;
  }
  return children;
};
CheckLoginStatus.propTypes = {
  children: PropTypes.node.isRequired,
};
export default CheckLoginStatus;
