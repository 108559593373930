import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGrid } from 'motif';
import Attachments from './attachments';

const AttachmentPanel = ({
  formData,
  change,
  handleSubmit,
  attachmentStepButtonState,
  updateAttachmentStepButtonState,
}) => (
  <Fragment>
    <FormGrid container>
      <FormGrid item xs={12}>
        <Attachments
          attachments={formData.attachments}
          change={change}
          handleSubmit={handleSubmit}
          attachmentStepButtonState={attachmentStepButtonState}
          updateAttachmentStepButtonState={updateAttachmentStepButtonState}
        />
      </FormGrid>
    </FormGrid>
  </Fragment>
);

AttachmentPanel.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateAttachmentStepButtonState: PropTypes.func.isRequired,
  attachmentStepButtonState: PropTypes.bool.isRequired,
};

AttachmentPanel.defaultProps = {
  formData: {},
};

export default AttachmentPanel;
