import { useState } from 'react';
import createUseContext from 'constate'; // State Context Object Creator
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import config from '../../config';
import { getSlugFromUrl, getPracticeIdFromRegisterPath } from '../Helpers/url';
import { Error as ErrorEmitter } from '../Errors';

export const usePractice = () => {
  const [publicProfile, setPublicProfile] = useState(undefined);
  const [publicProfileLoading, setPublicProfileLoading] = useState(false);

  const fetchPublicProfileRequest = async (tenantIdOrSlug) => {
    if (publicProfile || publicProfileLoading) {
      return;
    }
    setPublicProfileLoading(true);
    try {
      const { data } = await axios({
        method: 'get',
        url: `${config.PRACTICE_PREFERENCES_API_URL_WITHOUT_PATH}${config.PRACTICE_PREFERENCES_BASE_PATH}/publicProfiles/${tenantIdOrSlug}`,
        json: true,
      });
      setPublicProfile(data);
      setPublicProfileLoading(false);
    } catch (err) {
      setPublicProfileLoading(false);
      ErrorEmitter.show(`Failed to load practice profile. ${err}`);
      throw err;
    }
  };

  const fetchPublicProfile = () => {
    // fallback to get practice profile from slug(subdomain)
    const slug = getSlugFromUrl();
    if (slug) {
      fetchPublicProfileRequest(slug);
      return;
    }
    // fallback to get practice profile from practiceId inside register link
    const practiceId = getPracticeIdFromRegisterPath();
    if (practiceId) {
      fetchPublicProfileRequest(practiceId);
      return;
    }
    // should not reach here
    ErrorEmitter.show('no tenant ID or slug found');
    Sentry.captureException(new Error('no tenant ID or slug found'));
  };

  return {
    publicProfile, setPublicProfile, fetchPublicProfile,
  };
};
export const usePracticeContext = createUseContext(usePractice);
