import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGrid } from 'motif';
import Telehealth from './telehealth';

const TelehealthPanel = ({ formData, change }) => (
  <Fragment>
    <FormGrid container>
      <FormGrid item xs={12}>
        <Telehealth formData={formData} change={change} />
      </FormGrid>
    </FormGrid>
  </Fragment>
);

TelehealthPanel.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
};

TelehealthPanel.defaultProps = {
  formData: {},
};

export default TelehealthPanel;
