import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import {
  FormGrid,
} from 'motif';
import SectionAddress from './sectionAddress';
import { renderCheckBox } from '../../../../Helpers/reduxFormMotif';

class ResidentialAddressForm extends Component {
  resetPostalAddress() {
    const { change } = this.props;
    change('patient_form', 'postal_address', null);
  }

  handleSelectSuburb = section => ({ locality, state, postcode } = {}) => {
    const { change } = this.props;
    change('patient_form', `${section}.suburb`, locality);
    change('patient_form', `${section}.state`, state);
    change('patient_form', `${section}.postcode`, postcode);
  }

  render() {
    const { formData } = this.props;
    return (
      <FormGrid container spacingV={8}>
        <FormSection name="street_address">
          <SectionAddress
            formData={formData.street_address}
            onSelectSuburb={this.handleSelectSuburb('street_address')}
          />
        </FormSection>

        <FormGrid item xs={12}>
          <Field
            label="Use above as my postal address"
            component={renderCheckBox}
            name="reuse_street_address"
            onChange={() => { this.resetPostalAddress(); }}
          />
        </FormGrid>

        {!formData.reuse_street_address && (
          <FormSection name="postal_address">
            <SectionAddress
              formData={formData.postal_address}
              onSelectSuburb={this.handleSelectSuburb('postal_address')}
            />
          </FormSection>
        )}

      </FormGrid>
    );
  }
}

ResidentialAddressForm.propTypes = {
  change: PropTypes.func.isRequired,
  formData: PropTypes.instanceOf(Object),
};

ResidentialAddressForm.defaultProps = {
  formData: {},
};

export default ResidentialAddressForm;
