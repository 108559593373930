import React from 'react';
import PropTypes from 'prop-types';
import { RenderPreviewText } from '../Layout/TextPreview';
import { usePracticeContext } from '../App/practiceContext';
import { getFinancialConsent } from '../../models/practicePreferencesModel';
import styles from '../Layout/TextPreview/styles.module.scss';

const Fee = ({ preview = true, versionNumber }) => {
  const { publicProfile } = usePracticeContext();
  if (!publicProfile) { return null; }
  const consent = getFinancialConsent(publicProfile, versionNumber);
  return (
    <div>
      <RenderPreviewText
        preview={preview}
        shortText={consent.financialConsentShortText}
        mainText={consent.financialConsentMainText}
        style={styles.legalAgreementText}
      />
    </div>
  );
};
Fee.propTypes = {
  preview: PropTypes.bool,
  versionNumber: PropTypes.string,
};
Fee.defaultProps = {
  preview: undefined,
  versionNumber: undefined,
};

export default Fee;
