import { API } from '../api';
import config from '../config';

const SET_PRACTICE_PREFERENCES = 'SET_PRACTICE_PREFERENCES';
const SET_PRACTICE_PREFERENCES_ERROR = 'SET_PRACTICE_PREFERENCES_ERROR';

const practicePreferencesAPI = new API(config.PRACTICE_PREFERENCES_API_URL_WITHOUT_PATH);

const getPracticePreferences = tenantId => async (dispatch) => {
  try {
    const preferences = await practicePreferencesAPI.get(`${config.PRACTICE_PREFERENCES_BASE_PATH}/${tenantId}`, {}, true);
    dispatch({ type: SET_PRACTICE_PREFERENCES, preferences });
  } catch (error) {
    dispatch({ type: SET_PRACTICE_PREFERENCES_ERROR, error });
  }
};

export {
  SET_PRACTICE_PREFERENCES_ERROR,
  SET_PRACTICE_PREFERENCES,
  getPracticePreferences,
};

