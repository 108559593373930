
const getVersionedConsent = (allVersions, versionNumber, backwardCompatibleDefaultVersion) => {
  if (!allVersions || allVersions.length === 0) {
    return backwardCompatibleDefaultVersion;
  }
  if (!versionNumber || versionNumber === 'latest') {
    // default to return latest version
    return allVersions[allVersions.length - 1];
  }
  const versionIndex = parseInt(versionNumber.replace('v', ''), 10) - 1;
  if (Number.isNaN(versionIndex) || versionIndex < 0) {
    throw new Error(`Invalid version number: ${versionNumber}`);
  }
  if (versionIndex >= allVersions.length) {
    throw new Error(`Specified version not found: ${versionNumber}`);
  }
  return allVersions[versionIndex];
};

export const getPrivacyConsent = (preferencesOrPublicProfile = {}, versionNumber) =>
  getVersionedConsent(preferencesOrPublicProfile.privacyConsentVersions, versionNumber, {
    privacyConsentMainText: preferencesOrPublicProfile.privacyConsentMainText,
    privacyConsentShortText: preferencesOrPublicProfile.privacyConsentShortText,
  });

export const getFinancialConsent = (preferencesOrPublicProfile = {}, versionNumber) =>
  getVersionedConsent(preferencesOrPublicProfile.financialConsentVersions, versionNumber, {
    financialConsentMainText: preferencesOrPublicProfile.financialConsentMainText,
    financialConsentShortText: preferencesOrPublicProfile.financialConsentShortText,
  });

export const getTelehealthConsent = (preferencesOrPublicProfile = {}, versionNumber) =>
  getVersionedConsent(preferencesOrPublicProfile.telehealthConsentVersions, versionNumber, {
    telehealthConsentMainText: preferencesOrPublicProfile.telehealthConsentMainText,
    telehealthConsentShortText: preferencesOrPublicProfile.telehealthConsentShortText,
  });

const getLatestConsentVersionNumber = (versions) => {
  if (!versions || versions.length === 0) {
    return 'v1';
  }
  return `v${versions.length}`;
};
export const getLatestPrivacyConsentVersionNumber = (preferencesOrPublicProfile = {}) => getLatestConsentVersionNumber(preferencesOrPublicProfile.privacyConsentVersions);
export const getLatestFinancialConsentVersionNumber = (preferencesOrPublicProfile = {}) => getLatestConsentVersionNumber(preferencesOrPublicProfile.financialConsentVersions);
export const getLatestTelehealthConsentVersionNumber = (preferencesOrPublicProfile = {}) => getLatestConsentVersionNumber(preferencesOrPublicProfile.telehealthConsentVersions);
