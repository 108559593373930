import React from 'react';
import { usePracticeContext } from '../App/practiceContext';
import * as Analytics from '../../actions/analytics';
import { Error } from '../Errors';

const ContactPractice = () => {
  const { publicProfile } = usePracticeContext();
  const { tenantName, tenantPhoneNumber } = publicProfile || {};
  return (
    <span>
      We are unable to match your date of birth to an existing patient record.
      Please contact {tenantName} on <a href={`tel:${tenantPhoneNumber}`}>{tenantPhoneNumber}</a>.
    </span>
  );
};
export default ContactPractice;

export const notConfirmed = () => {
  Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.REGISTER, 'Verification Failed');
  Error.show(<ContactPractice />);
};

export const invalidRegistrationLink = () => {
  Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.REGISTER, 'Invalid Registration Link');
  Error.show(<ContactPractice />);
};

export const unableToVerify = (error) => {
  Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.REGISTER, 'Unable To Verify', { error });
  Error.show(<ContactPractice />);
};
