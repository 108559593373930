import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import createStore, { history } from '../../createStore';
import MainContainer from '../Layout/MainContainer';
import theme from '../Layout/theme';
import { Routes } from './routes';
import styles from './styles.module.scss';
import { CalloutError } from '../Errors';
import * as sentry from './sentry';
import { useAppContext } from './context';
import { usePracticeContext } from './practiceContext';
import CheckLoginStatus from './CheckLoginStatus';
import ErrorBoundary from './ErrorBoundary';

sentry.init();
const store = createStore();

const AppWithRouter = () => (
  <Router>
    <useAppContext.Provider>
      <CheckLoginStatus>
        <usePracticeContext.Provider>
          <div className={styles.root}>
            <Provider store={store}>
              <MuiThemeProvider theme={theme}>
                <ConnectedRouter store={store} history={history}>
                  <MainContainer>
                    <CalloutError />
                    <ErrorBoundary>
                      <Routes />
                    </ErrorBoundary>
                  </MainContainer>
                </ConnectedRouter>
              </MuiThemeProvider>
            </Provider>
          </div>
        </usePracticeContext.Provider>
      </CheckLoginStatus>
    </useAppContext.Provider>
  </Router>
);

export default AppWithRouter;
