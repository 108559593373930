export const SET_PAGE_COUNT = 'SET_PAGE_COUNT';
export const SET_PAGE = 'SET_PAGE';
export const SET_PANEL = 'SET_PANEL';
export const SET_FORM_ITEMS = 'SET_FORM_ITEMS';
export const SET_ANALYTIC_CURSOR = 'SET_ANALYTIC_CURSOR';

export const setPage = page => (dispatch) => {
  dispatch({ type: SET_PAGE, page });
};

export const setPanel = panel => (dispatch) => {
  dispatch({ type: SET_PANEL, panel });
};

export const setPageCount = pageCount => (dispatch) => {
  dispatch({ type: SET_PAGE_COUNT, page_count: pageCount });
};

export const setFormItems = formItems => (dispatch) => {
  dispatch({ type: SET_FORM_ITEMS, formItems });
};

export const setflattenFormItemsCursor = cursor => (dispatch) => {
  dispatch({ type: SET_ANALYTIC_CURSOR, flatten_form_items_cursor: cursor });
};
