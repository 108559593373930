import React from 'react';
import PersonalDetailsForm from '../demographics/components/PersonalDetailsForm';
import ResidentialAddressForm from '../demographics/components/ResidentialAddressForm';
import EmergencyContactForm from '../demographics/components/EmergencyContactForm';
import InsuranceBillingForm from '../demographics/components/InsuranceBillingForm';
import UsualGPForm from '../demographics/components/UsualGPForm';
import TelehealthPanel from '../demographics/components/TelehealthForm';
import PrivacyPanel from '../demographics/components/PrivacyForm';
import FeePanel from '../demographics/components/FeeForm';
import AttachmentPanel from '../demographics/components/AttachmentForm';
import DynamicPanel from '../demographics/components/DynamicForm';
import InputHint from './InputHint';

export const panelMap = [
  { linkId: 'usual_gp', formComponent: UsualGPForm },
  { linkId: 'insurance_billing', formComponent: InsuranceBillingForm },
  { linkId: 'contact_details', formComponent: PersonalDetailsForm },
  { linkId: 'residential_address', formComponent: ResidentialAddressForm },
  { linkId: 'emergency_contact', formComponent: EmergencyContactForm },
  { linkId: 'additional_attachments', formComponent: AttachmentPanel },
  { linkId: 'privacy_panel', formComponent: PrivacyPanel },
  { linkId: 'fee_panel', formComponent: FeePanel },
  { linkId: 'medical_information', formComponent: DynamicPanel, text: 'clinical_questions' },
  {
    linkId: 'telehealth',
    formComponent: TelehealthPanel,
    actions: (
      <div style={{ marginTop: 10 }}>
        <InputHint
          title="Telehealth"
          content="Telehealth consultations are video consultations between a patient and a specialist who are not in the same location. Medicare rebate may apply for telehealth consultations."
        />
      </div>
    ),
  },
];
