import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {
  layout,
  PrimaryButton,
} from 'motif';
import { LinearProgress } from '@material-ui/core';
import { CloudUpload as UploadIcon } from '@material-ui/icons';
import styles from './styles.module.scss';

export const AttachmentDropzone = ({
  onDrop, uploadInProgress, disabled, disabledMessage,
}) => (
  <Dropzone
    onDrop={onDrop}
    maxSize={50000000}
    accept="image/jpg,image/jpeg,application/pdf"
    disableClick
    multiple
    disabled={disabled}
  >
    {({ getRootProps, getInputProps }) => (
      <div id="dropzone" {...getRootProps({ className: styles.dropzone })}>
        <input data-id="patient-portal-attachment" {...getInputProps()} />
        <PrimaryButton
          disabled={disabled}
          data-id="patient-portal-attachment-primary-button"
        >
          {disabled ? disabledMessage : 'Choose File'}
        </PrimaryButton>
        {!disabled &&
          <div>
            <div>
              <div className={layout.marginTop}>or</div>
            </div>
            <div>
              <div className={layout.marginTop}>Drag files here</div>
              <UploadIcon className={layout.marginTop} />
            </div>
            {uploadInProgress &&
            <LinearProgress
              color="secondary"
            />}
          </div>
        }
      </div>
    )}
  </Dropzone>
);

AttachmentDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  uploadInProgress: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
};

AttachmentDropzone.defaultProps = {
  uploadInProgress: false,
  disabled: false,
  disabledMessage: undefined,
};
