import React from 'react';
import PropTypes from 'prop-types';
import { Callout } from 'motif';
import { Warning } from '@material-ui/icons';

const PreferencesError = ({ loadingFailure, loadingError }) => {
  if (!loadingFailure) return (null);

  return (
    <Callout
      type="error"
      title={`Can't load practice preferences for this subdomain: ${loadingError}`}
      key="practice-preferences-error"
      icon={<Warning />}
    />
  );
};
PreferencesError.propTypes = {
  loadingFailure: PropTypes.bool,
  loadingError: PropTypes.string,
};
PreferencesError.defaultProps = {
  loadingFailure: undefined,
  loadingError: undefined,
};

export default PreferencesError;
