import * as Sentry from '@sentry/browser';
import config from '../../config';

export const init = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.Environment,
    release: config.PATIENT_PORTAL_UI_SENTRY_RELEASE_VERSION,
  });

  Sentry.configureScope((scope) => {
    scope.setTag('application', 'patient-portal-ui');
  });
};

export const setUser = (userId) => {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: userId });
  });
};
