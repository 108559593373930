import moment from 'moment';
import { FORMATS } from './constants';

export const lessThan18YearsOld = '< 18 years old';
export const between18To34YearsOld = '18 – 34 years old';
export const between35To49YearsOld = '35 – 49 years old';
export const between50To69YearsOld = '50 – 69 years old';
export const moreThan70YearsOld = '> 70 years old';
export const unknownAgeDemographic = 'undefined';

export const now = () => moment().format(FORMATS.ISO_8601);

export const calculateAge = (birthDate) => {
  if (!birthDate || !birthDate.length) return 0;
  const dob = moment(birthDate, [FORMATS.DATE, FORMATS.ISO_DATE]).toDate();
  const nowDate = new Date();
  const dobMonth = dob.getMonth();
  const nowMonth = nowDate.getMonth();

  let years = nowDate.getFullYear() - dob.getFullYear();

  if (nowMonth < dobMonth || (nowMonth === dobMonth && nowDate.getDate() < dob.getDate())) {
    years -= 1;
  }

  return years;
};

export const getAgeDemographic = (dob) => {
  if (!dob || !dob.length) return unknownAgeDemographic;
  const age = calculateAge(dob);
  let range = unknownAgeDemographic;
  if (age < 18) range = lessThan18YearsOld;
  if (age >= 18 && age < 35) range = between18To34YearsOld;
  if (age >= 35 && age < 50) range = between35To49YearsOld;
  if (age >= 50 && age < 70) range = between50To69YearsOld;
  if (age >= 70) range = moreThan70YearsOld;
  return range;
};
