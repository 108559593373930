/**
 * Flatten form item pages and panels to an array of panels
 *
 * @param {*} formItems the form item array
 * @returns {array} array of panels
 */
export const flattenFormItems = formItems => formItems.reduce((acc, curr) => [...acc, ...curr.item], []);

/**
 * Get index of panel, we want to turn panel index to
 * 0 when panel is  not exist in the page, this will
 * default the form to the first panel
 *
 * @param {{currentPageFormItems: array, currentPanel: number}}
 * @returns {number} the index of panel
 */
export const getExistPanelIndex = ({ currentPageFormItems, currentPanel }) => {
  const panel = currentPageFormItems.item[currentPanel];
  if (!panel) {
    return 0;
  }

  return currentPanel;
};

/**
 * Use currentPage and currentPanel to find the index
 * of the panel in flattened array
 *
 * @param {{formItems: array, currentPage: number, currentPanel: number}}
 * @returns {number}
 */
export const findPanelIndexInFlattenFormItem = ({
  formItems, currentPage, currentPanel,
}) => flattenFormItems(formItems).findIndex((item) => {
  if (!formItems[currentPage]) {
    return false;
  }

  const panelIndex = getExistPanelIndex({ currentPageFormItems: formItems[currentPage], currentPanel });

  const panel = formItems[currentPage].item[panelIndex];

  return item.linkId === panel.linkId;
});
