import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Hidden from '@material-ui/core/Hidden';
import { FormGrid } from 'motif';
import SuburbField from '../SuburbField';
import { renderInput, renderSelect } from '../../../../Helpers/reduxFormMotif';
import { requiredWithMessage, maxLength, validAustralianPostcode } from '../../../../Validator/rules';

const SectionAddress = ({ onSelectSuburb, formData }) => (
  <React.Fragment>
    <FormGrid item xs={12} sm={8}>
      <Field
        name="street_1"
        component={renderInput}
        label="Street 1"
        autoComplete="street-address"
        validate={[requiredWithMessage('Street 1 is required'), maxLength(50)]}
        required
      />
    </FormGrid>
    <Hidden only="xs"><FormGrid item sm={4} /></Hidden>

    <FormGrid item xs={12} sm={4}>
      <Field
        name="street_2"
        label="Street 2"
        component={renderInput}
        autoComplete="address-line1"
        validate={[maxLength(32)]}
      />
    </FormGrid>
    <FormGrid item xs={12} sm={4}>
      <SuburbField
        name="suburb"
        state={(formData || {}).state}
        validate={[requiredWithMessage('Suburb is required'), maxLength(32)]}
        onSelect={onSelectSuburb}
        searchInputProps={{
          label: 'Suburb',
          autoComplete: 'address-level2',
          required: true,
        }}
      />
    </FormGrid>
    <Hidden only="xs"><FormGrid item sm={4} /></Hidden>

    <FormGrid item xs={12} sm={3}>
      <Field
        name="state"
        component={renderSelect}
        label="State"
        data={[
          { name: 'QLD', value: 'QLD' },
          { name: 'NSW', value: 'NSW' },
          { name: 'VIC', value: 'VIC' },
          { name: 'WA', value: 'WA' },
          { name: 'NT', value: 'NT' },
          { name: 'TAS', value: 'TAS' },
          { name: 'SA', value: 'SA' },
          { name: 'ACT', value: 'ACT' },
        ]}
        hideEmpty
        autoComplete="address-level1"
        validate={[requiredWithMessage('State is required')]}
        required
      />
    </FormGrid>
    <FormGrid item xs={12} sm={3}>
      <Field
        name="postcode"
        component={renderInput}
        label="Postcode"
        autoComplete="postal-code"
        validate={[requiredWithMessage('Postcode is required'), validAustralianPostcode]}
        required
      />
    </FormGrid>
    <Hidden only="xs"><FormGrid item sm={6} /></Hidden>
  </React.Fragment>
);
SectionAddress.propTypes = {
  onSelectSuburb: PropTypes.func,
  formData: PropTypes.instanceOf(Object),
};
SectionAddress.defaultProps = {
  onSelectSuburb: undefined,
  formData: undefined,
};

export default SectionAddress;
