import { connect } from 'react-redux';
import AppBar from './component';
import { setPage, setPanel, setflattenFormItemsCursor } from '../../../actions/page';

const mapStateToProps = state => ({
  pageCount: state.page.page_count,
  currentPage: state.page.current_page,
  flattenFormItemsCursor: state.page.flatten_form_items_cursor,
});

const mapDispatchToProps = {
  setPage,
  setPanel,
  setflattenFormItemsCursor,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
