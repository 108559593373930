import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Base64 } from 'js-base64';
import cookies from 'js-cookie';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { Callout } from 'motif';
import { Warning } from '@material-ui/icons';

import config from '../../config';
import Loader from '../Loader';
import { useAppContext } from '../App/context';
import { usePracticeContext } from '../App/practiceContext';
import * as Analytics from '../../actions/analytics';

const getTokens = (code, state = {}, tenantId) => axios.post(config.AUTH_ROLE_INTEGRATION_OAUTH2_TOKENS_URL, {
  code,
  tenantId,
  redirectUri: config.AUTH_ROLE_INTEGRATION_OAUTH2_REDIRECT_URL,
  state,
});

const OAuth2Callback = ({ history, location }) => {
  const { setAuthTokens } = useAppContext();
  const { publicProfile } = usePracticeContext();
  const [errors, setErrors] = React.useState([]);
  const handleCallback = async ({ code, state }) => {
    try {
      const {
        redirectTo = '/', practiceId, patientId, action,
      } = state;

      const tenantId = `${publicProfile.locationId}-${publicProfile.tenantId}`;
      const { data: tokens } = await getTokens(code, state, tenantId);
      const { cognitoIdToken, cognitoRefreshToken, rolesToken } = tokens;
      const decodedCognitoIdToken = jwt.decode(cognitoIdToken);

      Analytics.identifyUser(decodedCognitoIdToken.sub);

      if (action && action === 'signup') {
        Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.REGISTER, 'Registered');
        Analytics.sendSessionProperties({
          practice_id: practiceId,
          patient_id: patientId,
        });
      }

      // use id token expires as expires for all tokens
      const expires = new Date(decodedCognitoIdToken.exp * 1000);
      cookies.set('cognitoIdToken', cognitoIdToken, { expires });
      cookies.set('cognitoRefreshToken', cognitoRefreshToken, { expires });
      cookies.set('rolesToken', rolesToken, { expires });
      setAuthTokens(tokens);
      history.replace(redirectTo);
    } catch (err) {
      setErrors([...errors, `Failed to authorize user: ${err}`]);
      throw err;
    }
  };
  useEffect(() => {
    if (!publicProfile) {
      return;
    }
    const params = qs.parse(location.search);
    const state = JSON.parse(Base64.decode(params.state));
    const { code } = params;
    handleCallback({ code, state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, getTokens, publicProfile]);

  if (errors && errors.length !== 0) {
    return (
      <>
        {
          errors && errors.map(err => (
            <Callout
              type="error"
              title={err}
              key={err}
              icon={<Warning />}
            />
          ))
        }
      </>
    );
  }

  return (
    <Loader />
  );
};
OAuth2Callback.propTypes = {
  /** location object to allow access to url query string */
  location: PropTypes.shape({
    // code and state parameters are expected in search, state is expected to be a Base64 en
    search: PropTypes.string.isRequired,
  }).isRequired,
  /** history object to allow replace url */
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default OAuth2Callback;
