import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, buttonStates } from 'motif';
import styles from './styles.module.scss';

const ActionBar = ({
  submitButtonState,
  firstPage,
  lastPage,
  onBack,
  onNext,
  idSuffix,
}) => (
  <div className={styles.actionBar}>
    <ButtonGroup
      primary={{
        label: lastPage ? 'Submit' : 'Save & Next',
        key: 'next',
        state: submitButtonState,
        onClick: onNext,
        blockingAction: true,
        id: `next-${idSuffix}`,
      }}
      {...(firstPage ? {} : {
        tertiary: {
          label: 'Back',
          key: 'back',
          onClick: onBack,
          blockingAction: true,
          id: `back-${idSuffix}`,
        },
      })}
    />
  </div>
);

ActionBar.propTypes = {
  firstPage: PropTypes.bool,
  lastPage: PropTypes.bool,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  idSuffix: PropTypes.string,
  submitButtonState: PropTypes.oneOf(Object.values(buttonStates)),
};

ActionBar.defaultProps = {
  firstPage: true,
  lastPage: false,
  onBack: undefined,
  onNext: undefined,
  idSuffix: '',
  submitButtonState: buttonStates.default,
};

export default ActionBar;
