import { isObject } from 'lodash';
import { stringify } from 'telejson';

import config from '../config';

export const ANALYTICS_FEATURES = {
  GENERIC_EVENT: '[Generic Event]',
  REGISTER: '[Register]',
  FORM: '[Form]',
  SIGN_OUT: '[Sign Out]',
};

const isDynatraceEnabled = () => Boolean(window.dtrum);

export const MAX_ACTION_PROPERTIES = 6;
export const MAX_ACTION_PROPERTY_STRING_LENGTH = 100;

export const convertAttributesToActionPropertiesArray = (feature, name, attributes) => {
  if (attributes === undefined) {
    return [[], ''];
  }

  if (!isObject(attributes) || Array.isArray(attributes)) {
    window.console.warn('Dynatrace event attributes ignored. trackEvent expects attributes to be an Object');
    return [[], ''];
  }
  const keys = Object.keys(attributes);
  return [keys.map((actionPropertyKey) => {
    const actionProperty = attributes[actionPropertyKey];

    const stringVersion = typeof actionProperty !== 'string' ? stringify(actionProperty) : actionProperty;
    if (!stringVersion) return '';

    if (stringVersion.length > MAX_ACTION_PROPERTY_STRING_LENGTH) {
      window.console.warn(`Dynatrace event actionProperty truncated to ${MAX_ACTION_PROPERTY_STRING_LENGTH} characters: `, feature, name, actionProperty);
    }
    return stringVersion.slice(0, MAX_ACTION_PROPERTY_STRING_LENGTH);
  }), stringify(keys)];
};

export const generateActionProperties = (feature, actionPropertiesArray = [], keysString = '') =>
  actionPropertiesArray.reduce((actionProperties, actionProperty, index) => ({
    ...actionProperties,
    [`property${index + 1}`]: actionProperty,
  }), {
    feature,
    property_keys: keysString,
  });

export const trackEvent = async (feature = ANALYTICS_FEATURES.GENERIC_EVENT, name, attributes) => {
  const [actionPropertiesArray, keysString] = convertAttributesToActionPropertiesArray(feature, name, attributes);
  if (actionPropertiesArray.length > MAX_ACTION_PROPERTIES) {
    window.console.warn(`Dynatrace event NOT tracked: Too many attribute keys, maximum is ${MAX_ACTION_PROPERTIES} `, feature, name);
    return;
  }

  const actionProperties = generateActionProperties(feature, actionPropertiesArray, keysString);

  if (!isDynatraceEnabled()) return;

  if (config.DYNATRACE_DEBUG_LOGGING) {
    window.console.log('Dynatrace event tracked:  ', feature, name, actionProperties);
  }

  const dtrumId = await window.dtrum.enterAction(name);
  window.dtrum.addActionProperties(dtrumId, null, null, actionProperties);
  window.dtrum.leaveAction(dtrumId);
};

export const identifyUser = (userReference) => {
  if (!isDynatraceEnabled()) return;

  if (!userReference) return;
  window.dtrum.identifyUser(userReference);
};

export const sendSessionProperties = (attributes = {}) => {
  if (!isDynatraceEnabled()) return;

  window.dtrum.sendSessionProperties(null, null, attributes);
};

export const endSession = () => {
  if (!isDynatraceEnabled()) return;

  window.dtrum.endSession();
};
