import EventEmitter from 'eventemitter3';

const emitter = new EventEmitter();

const show = (message) => {
  emitter.emit('calloutError/error', message);
};

const clear = () => {
  emitter.emit('calloutError/clear');
};

export { emitter, show, clear };
