import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Panel,
} from 'motif';
import * as Analytics from '../../../actions/analytics';

import ActionBar from './actionBar';
import { findPanelIndexInFlattenFormItem } from '../../Helpers/utils';
import { findCurrentPage, findCurrentPanel } from './transforms';
import styles from './styles.module.scss';
import { panelMap } from './panelMap';

const NO_PANEL_SELECTED = -1;

const PageComponent = ({
  formData, currentPage, currentPanel, setPage, setPanel, totalPages, handleSubmit, submitButtonState, change, practicePreferences, setflattenFormItemsCursor, flattenFormItemsCursor, formItems, formPanels,
}) => {
  const formComponents = React.useMemo(() => formPanels.map((panel) => {
    const panelComponent = panelMap.find(p => p.linkId === panel.linkId);
    if (!panelComponent) {
      return undefined;
    }
    return {
      ...panelComponent,
      title: panel.text,
      questionnaireItems: panel.item || [],
    };
  }).filter(panel => panel !== undefined), [formPanels]);

  const allRefs = formComponents.map(() => React.createRef());

  // attachment uploads
  const [attachmentStepButtonState, updateAttachmentStepButtonState] = useState(false);

  const scrollPanelToTop = (panel) => {
    if (!allRefs[panel]) {
      return;
    }

    const panelRef = allRefs[panel].current;
    if (!panelRef) return;
    // The panel headers are hidden on first render so this needs to be done
    // after they are rendered
    panelRef.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollPanelToTop(currentPanel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPanel, formComponents]);

  const backHandler = (index, panelName) => () => {
    if (index === 0) {
      setPanel(formItems[currentPage - 1].item.length - 1);
      setPage(currentPage - 1);
    } else {
      setPanel(currentPanel - 1);
    }

    setflattenFormItemsCursor(flattenFormItemsCursor - 1);
    Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.FORM, 'Clicked Back', { panel: panelName });
  };

  const nextHandler = (lastPage, panelName) => () => {
    const submitResponse = handleSubmit();
    // Redux-form's handleSubmit will either return:
    // - an object of validation failures, or
    // - whatever we return from our onSubmit (a Promise)

    // submit has passed validation
    if (submitResponse && !!submitResponse.then) {
      submitResponse.then((response) => {
        // after a successful submit, make sure redux matches what came back from the API
        setPanel(findCurrentPanel(response));
        setPage(findCurrentPage(response));
        setflattenFormItemsCursor(flattenFormItemsCursor + 1);

        if (!lastPage) {
          Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.FORM, 'Form Saved', { panel: panelName });
        } else {
          const patientId = response.source.reference.split('/')[1];
          const trackedProperties = {
            tenant_id: practicePreferences.locationId,
            tenant_name: practicePreferences.tenantName,
            patient_id: patientId,
          };
          Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.FORM, 'Practice Submission Form Completed');
          Analytics.identifyUser(patientId);
          Analytics.sendSessionProperties(trackedProperties);
        }
      });
    }
  };

  const panelBtnClickHandler = index => (state) => {
    if (currentPanel === index && !state) {
      setPanel(NO_PANEL_SELECTED);
    }

    if (currentPanel === NO_PANEL_SELECTED || currentPanel !== index) {
      setPanel(index);
      let initialAnalyticIndex = 0;
      if (currentPage !== undefined && index !== undefined) {
        initialAnalyticIndex = findPanelIndexInFlattenFormItem({
          formItems,
          currentPage,
          currentPanel: index,
        });
      }
      setflattenFormItemsCursor(initialAnalyticIndex);
    }

    updateAttachmentStepButtonState(false);
  };

  return (
    <Fragment>
      {
        formComponents.map((component, index) => {
          const {
            enable = true, formComponent: FormComponent, text, questionnaireItems,
          } = component;
          const lastPage = currentPage === totalPages - 1 && index === formComponents.length - 1;
          const firstPage = index === 0 && currentPage === 0;

          return (
            <div className={styles.panelContainer} key={`${component.title}-${currentPanel}`} style={enable ? {} : { display: 'none' }}>
              <div className={styles.panelSelector} ref={allRefs[index]} />
              <Panel
                title={component.title}
                headerProps={{ id: `${component.title.toLowerCase().replace(' ', '-')}-button` }}
                variant="grey"
                expandable={currentPanel !== index}
                lockExpandedValue={index > currentPanel ? false : undefined}
                actions={component.actions}
                expandDefault={currentPanel === index}
                expandButtonVisible={false}
                onExpandToggle={panelBtnClickHandler(index)}
              >
                {currentPanel === index ? (
                  <div>
                    <FormComponent
                      formData={formData}
                      change={change}
                      questionnaireItems={questionnaireItems}
                      handleSubmit={handleSubmit}
                      text={text} // text to select item for dynamic form component
                      updateAttachmentStepButtonState={updateAttachmentStepButtonState}
                      attachmentStepButtonState={attachmentStepButtonState}
                      default
                    />
                    {
                      !attachmentStepButtonState && <ActionBar
                        submitButtonState={submitButtonState}
                        firstPage={firstPage}
                        lastPage={lastPage}
                        onBack={backHandler(index, component.title)}
                        onNext={nextHandler(lastPage, component.title)}
                        idPrefix={index.toString()}
                      />
                    }
                  </div>) : <div />}
              </Panel>
            </div>
          );
        })
      }
    </Fragment>
  );
};
PageComponent.propTypes = {
  formData: PropTypes.instanceOf(Object),
  currentPage: PropTypes.number,
  currentPanel: PropTypes.number,
  setPage: PropTypes.func,
  setPanel: PropTypes.func,
  setflattenFormItemsCursor: PropTypes.func,
  flattenFormItemsCursor: PropTypes.number,
  totalPages: PropTypes.number,
  handleSubmit: PropTypes.func,
  submitButtonState: PropTypes.string,
  change: PropTypes.func,
  practicePreferences: PropTypes.instanceOf(Object),
  formItems: PropTypes.instanceOf(Array),
  formPanels: PropTypes.instanceOf(Array).isRequired,
};
PageComponent.defaultProps = {
  formData: undefined,
  currentPage: undefined,
  currentPanel: undefined,
  setPage: undefined,
  setPanel: undefined,
  setflattenFormItemsCursor: undefined,
  flattenFormItemsCursor: undefined,
  totalPages: undefined,
  handleSubmit: undefined,
  submitButtonState: undefined,
  change: undefined,
  practicePreferences: undefined,
  formItems: [],
};

export default PageComponent;
