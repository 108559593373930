import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Hidden from '@material-ui/core/Hidden';
import {
  FormGrid,
} from 'motif';
import { Field, FormSection } from 'redux-form';
import { renderInput, renderCheckBox, renderDatePicker } from '../../../../Helpers/reduxFormMotif';
import { toUpperCase } from '../../../../Helpers/strings';
import { validateFormValue } from '../../../../Validator';
import { maxLength, concessionCardExpiryRule } from '../../../../Validator/rules';
import { FORMATS } from '../../../../Helpers/constants';

const validExpiryDate = value => validateFormValue(value, 'concession_card_expiry_date', concessionCardExpiryRule);

const maxExpiry = moment().add(10, 'years');
const minExpiry = moment();

const ConcessionCard = ({ formData, change }) => {
  const resetConcessionCard = () => {
    if (change) {
      change('concession_card.card_number', null);
      change('concession_card.expiry_date', null);
    }
  };

  return (
    <FormSection name="concession_card">
      <Field
        label="Concession"
        component={renderCheckBox}
        name="has_concession_card"
        onChange={resetConcessionCard}
      />
      {(formData.concession_card && formData.concession_card.has_concession_card) &&
        <FormGrid container>
          <FormGrid item md={4} sm={7} xs={12}>
            <Field
              component={renderInput}
              name="card_number"
              label="Card number"
              normalize={toUpperCase}
              validate={[maxLength(18)]}
            />
          </FormGrid>
          <Hidden only="xs">
            <FormGrid item md={3} sm={5} xs={12}>
              <Field
                component={renderDatePicker}
                name="expiry_date"
                label="Expiry date"
                minDate={minExpiry}
                maxDate={maxExpiry}
                validate={[validExpiryDate]}
                inputProps={{ id: 'concession_expiry' }}
                required
              />
            </FormGrid>
          </Hidden>

          <Hidden smUp>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="expiry_date"
                type="tel"
                component={renderInput}
                label="Expiry date"
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                placeholder={FORMATS.DATE}
                noMargin
                validate={[validExpiryDate]}
              />
            </FormGrid>
          </Hidden>
        </FormGrid>
      }
    </FormSection>
  );
};

ConcessionCard.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func,
};

ConcessionCard.defaultProps = {
  formData: {},
  change: undefined,
};

export default ConcessionCard;
