import axios from 'axios';
import * as url from 'url';
import cookies from 'js-cookie';

const DEBUG = false;

const logger = (message, object) => {
  if (!DEBUG) return;
  // eslint-disable-next-line no-console
  console.log(message, object);
};

export class API {
  baseUri;

  constructor(baseUri) {
    this.baseUri = baseUri;
  }

  get(endpoint, data, requestAuth) {
    return this.request(endpoint, 'get', data, requestAuth);
  }

  post(endpoint, data, requestAuth) {
    return this.request(endpoint, 'post', data, requestAuth);
  }

  upload(endpoint, file, onUploadProgress) {
    const formData = new FormData();
    formData.append('file', file);
    const options = {
      method: 'POST',
      url: url.resolve(this.baseUri, endpoint),
      data: formData,
      onUploadProgress,
    };

    return this.addRequestHeaders(options)
      .then((optionsWithHeaders) => {
        const axiosOptions = { ...optionsWithHeaders };
        axiosOptions.headers = { ...axiosOptions.headers, 'Content-Type': 'multipart/form-data' };
        return axios(axiosOptions);
      })
      .then((response) => {
        logger('success', response);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        logger(`Error calling ${endpoint}: ${err.message}`);
        return Promise.reject(err);
      });
  }

  put(endpoint, data, requestAuth) {
    return this.request(endpoint, 'put', data, requestAuth);
  }

  delete(endpoint, requestAuth) {
    return this.request(endpoint, 'delete', null, requestAuth);
  }

  addRequestHeaders = async (options, requestAuth = true) => {
    if (!requestAuth) return options;
    const cognitoIdToken = cookies.get('cognitoIdToken');
    const rolesToken = cookies.get('rolesToken');
    return {
      ...options,
      headers: {
        Authorization: `Bearer ${cognitoIdToken}`,
        'X-GENIE-ROLES': rolesToken,
      },
    };
  }

  request = (endpoint, verb, params = {}, requestAuth) => {
    let options = {
      method: verb.toUpperCase(),
      url: url.resolve(this.baseUri, endpoint),
    };

    options = verb.match('put|post|patch') ? { ...options, data: params } : { ...options, params };

    return this.addRequestHeaders(options, requestAuth)
      .then((optionsWithHeaders) => {
        logger('call with options', optionsWithHeaders);
        return axios(optionsWithHeaders);
      })
      .then((response) => {
        logger('success', response);
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        logger(`Error calling ${endpoint}: ${err.message}`);
        return Promise.reject(err);
      });
  }
}
