import { useState } from 'react';
import createUseContext from 'constate'; // State Context Object Creator
import jwt from 'jsonwebtoken';

const useApp = () => {
  const [tokens, setTokens] = useState(undefined);
  const [patientId, setPatientId] = useState(undefined);
  const [cognitoUser, setCognitoUser] = useState(undefined);

  const setAuthTokens = ({ cognitoIdToken, cognitoRefreshToken, rolesToken }) => {
    const decodedCognitoIdToken = jwt.decode(cognitoIdToken);
    const decodedRolesToken = jwt.decode(rolesToken);
    setTokens({ cognitoIdToken, cognitoRefreshToken, rolesToken });
    setCognitoUser(decodedCognitoIdToken);
    const { patientId: decodedPatientId } = decodedRolesToken;
    setPatientId(decodedPatientId);
  };

  return {
    isLoggedIn: !!cognitoUser,
    cognitoUser,
    authTokens: tokens,
    setAuthTokens,
    patientId,
  };
};
export const useAppContext = createUseContext(useApp);
