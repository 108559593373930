import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { getPracticePreferences as getPracticePreferencesAction } from '../../../actions/practice-preferences';
import { useAppContext } from '../../App/context';
import { usePracticeContext } from '../../App/practiceContext';

const MainContainer = ({ children, getPracticePreferences }) => {
  const { isLoggedIn, cognitoUser } = useAppContext();
  const { publicProfile, fetchPublicProfile } = usePracticeContext();
  useEffect(() => {
    if (isLoggedIn && publicProfile) {
      getPracticePreferences(publicProfile.tenantId);
    }
  }, [isLoggedIn, publicProfile, getPracticePreferences]);

  useEffect(() => {
    fetchPublicProfile(cognitoUser);
  }, [cognitoUser, fetchPublicProfile]);

  return (
    <div className={styles.root}>
      {children}
    </div>
  );
};

MainContainer.propTypes = {
  children: PropTypes.node,
  getPracticePreferences: PropTypes.func.isRequired,
};

MainContainer.defaultProps = {
  children: null,
};

const mapDispatchToProps = {
  getPracticePreferences: getPracticePreferencesAction,
};

export default connect(null, mapDispatchToProps)(MainContainer);
