import React from 'react';
import PropTypes from 'prop-types';
import { RenderPreviewText } from '../Layout/TextPreview';
import { usePracticeContext } from '../App/practiceContext';
import { getTelehealthConsent } from '../../models/practicePreferencesModel';
import styles from '../Layout/TextPreview/styles.module.scss';

const Telehealth = ({ preview = true, versionNumber }) => {
  const { publicProfile } = usePracticeContext();
  if (!publicProfile) { return null; }
  const consent = getTelehealthConsent(publicProfile, versionNumber);
  return (
    <div>
      <RenderPreviewText
        preview={preview}
        shortText={consent.telehealthConsentShortText}
        mainText={consent.telehealthConsentMainText}
        style={styles.legalAgreementText}
      />
    </div>
  );
};
Telehealth.propTypes = {
  preview: PropTypes.bool,
  versionNumber: PropTypes.string,
};
Telehealth.defaultProps = {
  preview: undefined,
  versionNumber: undefined,
};

export default Telehealth;
