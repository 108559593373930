import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGrid } from 'motif';
import Fee from './fee';

const FeePanel = ({ formData }) => (
  <Fragment>
    <FormGrid container>
      <FormGrid item xs={12}>
        <Fee formData={formData} />
      </FormGrid>
    </FormGrid>
  </Fragment>
);

FeePanel.propTypes = {
  formData: PropTypes.instanceOf(Object),
};

FeePanel.defaultProps = {
  formData: {},
};

export default FeePanel;
