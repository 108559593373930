import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { API } from '../../../../../api';
import config from '../../../../../config';
import { Error } from '../../../../Errors';

const patientPortalApi = new API(config.PATIENT_PORTAL_API_URL_WITHOUT_PATH);
const getAttachmentSignedUrl = (fileName, fileType) => patientPortalApi.post(`api/v1/Containers/signedS3PutUrl/${fileName}/${fileType}`);

export const uploadAttachmentToS3 = async (attachment) => {
  const fileTypeToApi = encodeURIComponent(attachment.type);
  try {
    const s3UrlReturn = await getAttachmentSignedUrl(attachment.name, fileTypeToApi);
    const { signedUrl, keyName } = s3UrlReturn;
    const { type } = attachment;
    await axios.put(signedUrl, attachment, {
      headers: {
        'Content-Type': type,
      },
    });
    return keyName;
  } catch (error) {
    Error.show('A problem occurred while uploading your file');
    Sentry.captureException(error);
    throw error;
  }
};
