import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Icon } from 'motif';
import { Edit, Check } from '@material-ui/icons';
import styles from './styles.module.scss';

class ProgressSteps extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  renderMarker(isCurrent, isComplete, index) {
    let icon;
    if (isComplete) {
      icon = <Icon component={Check} size="small" />;
    } else if (isCurrent) {
      icon = <Icon component={Edit} size="small" />;
    } else {
      icon = index + 1;
    }

    return (
      <span className={classNames(styles.marker, { [styles.highlight]: isCurrent || isComplete })}>
        {icon}
      </span>
    );
  }

  renderStep = (step, index, arr) => (
    <Fragment key={step.name}>
      <div className={styles.step}>
        {this.renderMarker(step.isCurrent, step.isComplete, index)}
        <Typography
          component="span"
          color={(step.isCurrent || step.isComplete) ? undefined : 'disabled'}
          className={styles.label}
        >
          {step.name}
        </Typography>
      </div>
      {(index < arr.length - 1) && <hr />}
    </Fragment>
  );

  render() {
    const { steps } = this.props;
    return (
      <div className={styles.root}>
        {steps.map(this.renderStep)}
      </div>
    );
  }
}

ProgressSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    percentage: PropTypes.number,
    isCurrent: PropTypes.bool,
    isComplete: PropTypes.bool,
  })),
};

ProgressSteps.defaultProps = {
  steps: [],
};

export default ProgressSteps;
