import React from 'react';
import propTypes from 'prop-types';
import { FormGrid } from 'motif';
import { Field } from 'redux-form';
import {
  renderInputMultiline,
} from '../../../../Helpers/reduxFormMotif';

export const componentMap = {
  // eslint-disable-next-line react/prop-types
  string: ({ label, groupText, text }) => (
    <Field
      label={label}
      name={`${groupText}.${text}`}
      component={renderInputMultiline}
      multiline
      disableUnderline={false}
      rowsMax={3}
    />
  ),
  // eslint-disable-next-line react/prop-types
  text: ({ label, groupText, text }) => (
    <Field
      label={label}
      name={`${groupText}.${text}`}
      component={renderInputMultiline}
      multiline
      disableUnderline={false}
      rowsMax={3}
    />
  ),
};

const DynamicPanel = ({ text: groupText, questionnaireItems }) => (
  <FormGrid container>
    {questionnaireItems.map(e => (
      <FormGrid item xs={12} key={e.text}>
        {
          componentMap[e.type]({
            label: e.label,
            groupText,
            text: e.text,
          })
        }
      </FormGrid>
    ))}
  </FormGrid>
);

DynamicPanel.propTypes = {
  text: propTypes.string.isRequired,
  questionnaireItems: propTypes.instanceOf(Object).isRequired,
};

export default DynamicPanel;
