import { API } from '../api';

const SUGGEST_LOCALITY = 'SUGGEST_LOCALITY';
const SUGGEST_LOCALITY_ERROR = 'SUGGEST_LOCALITY_ERROR';

const platformAPI = new API(process.env.REACT_APP_PLATFORM_API_URL);

export const suggestLocality = ({ query, state, limit = 10 }) => async (dispatch) => {
  try {
    const localitySuggestions = await platformAPI.post(
      '/validation-service/suggest-locality',
      { q: query, state, limit },
      true,
    );
    dispatch({ type: SUGGEST_LOCALITY, localitySuggestions });
    return localitySuggestions;
  } catch (error) {
    dispatch({ type: SUGGEST_LOCALITY_ERROR, error });
    return undefined;
  }
};
