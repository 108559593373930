import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGrid, Typography, layout } from 'motif';
import MedicareCard from './medicare';
import ConcessionCard from './concessionCard';
import DvaCard from './dvaCard';
import PrivateHealthInsurance from './privateHealthInsurance';
import InsuranceClaim from './insuranceClaim';
import AccountHolder from './AccountHolder';

const InsuranceBillingPanel = ({ formData, change }) => (
  <Fragment>
    <FormGrid container>
      <FormGrid item xs={12}>
        <MedicareCard formData={formData} change={change} />
      </FormGrid>
    </FormGrid>
    <FormGrid container className={layout.marginTop}>
      <FormGrid item xs={12}>
        <Typography variant="subtitle1">Please choose if you have any of the following</Typography>
        <PrivateHealthInsurance formData={formData} change={change} />
        <ConcessionCard formData={formData} change={change} />
        <DvaCard formData={formData} change={change} />
        <InsuranceClaim formData={formData} change={change} />
      </FormGrid>
    </FormGrid>
    <AccountHolder formData={formData} change={change} />
  </Fragment>
);

InsuranceBillingPanel.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
};

InsuranceBillingPanel.defaultProps = {
  formData: {},
};

export default InsuranceBillingPanel;
