import React from 'react';
import { Field, FormSection } from 'redux-form';
import { FormGrid, Typography } from 'motif';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  renderInput,
  renderCheckBox,
} from '../../../../Helpers/reduxFormMotif';
import {
  firstNameRule,
  lastNameRule,
  requiredWithMessage,
  required,
} from '../../../../Validator/rules';
import { validateFormValue } from '../../../../Validator/index';
import PrivacyTerms from '../../../../Static/privacy';
import { usePracticeContext } from '../../../../App/practiceContext';
import { getLatestPrivacyConsentVersionNumber } from '../../../../../models/practicePreferencesModel';

const validFirstName = value => validateFormValue(value, 'given_name', firstNameRule);
const validLastName = value => validateFormValue(value, 'family_name', lastNameRule);

const Privacy = (props) => {
  const { publicProfile } = usePracticeContext();
  const setDateTimeAndVersion = (hasAgreed) => {
    if (!props.formData.privacy) {
      // eslint-disable-next-line no-param-reassign
      props.formData.privacy = {};
    }
    // eslint-disable-next-line no-param-reassign
    props.formData.privacy.date = hasAgreed ? moment().format() : '';
    // eslint-disable-next-line no-param-reassign
    props.formData.privacy.agreed_version = getLatestPrivacyConsentVersionNumber(publicProfile);
  };

  return (
    <FormSection name="privacy">
      <FormGrid container spacingV={8}>
        <FormGrid item xs={12}>
          <PrivacyTerms />
        </FormGrid>
        <FormGrid item xs={12}>
          <Typography component="span">
            Patient/Authorised Person
          </Typography>
        </FormGrid>
        <FormGrid item xs={12} sm={6}>
          <Field
            name="first_name"
            component={renderInput}
            label="First name"
            validate={[required, validFirstName]}
            required
            autoComplete="given-name"
          />
        </FormGrid>
        <FormGrid item xs={12} sm={6}>
          <Field
            name="family_name"
            component={renderInput}
            label="Last name"
            validate={[required, validLastName]}
            required
            autoComplete="family-name"
          />
        </FormGrid>
        <FormGrid item xs={12} sm={6}>
          <Field
            label="I have read and agree to the above*"
            component={renderCheckBox}
            name="has_agreed"
            validate={[requiredWithMessage('You must agree to continue')]}
            onChange={setDateTimeAndVersion}
          />
        </FormGrid>
      </FormGrid>
    </FormSection>

  );
};

Privacy.propTypes = {
  formData: PropTypes.instanceOf(Object),
};

Privacy.defaultProps = {
  formData: {},
};

export default Privacy;
