import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Hidden from '@material-ui/core/Hidden';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import { FormGrid, TertiaryButton } from 'motif';
import {
  renderInput,
  renderSelect,
} from '../../../../Helpers/reduxFormMotif';
import {
  phoneNumberRule,
} from '../../../../Validator/rules';
import { validateFormValue } from '../../../../Validator/index';
import { isMobile } from '../../../../Helpers/phoneNumbers';
import formStyles from '../../../common/styles.module.scss';

const validPhoneNumber = value => validateFormValue(value, 'phone_number', phoneNumberRule);
/* Secondary phone numbers are not required */
const validSecondaryPhoneNumber = (value) => {
  if (!value) return null;
  return validateFormValue(value, 'phone_number', phoneNumberRule);
};

const emptyPhoneRow = { number: '', use: '' };

const renderPhoneNumbers = ({ fields, change }) => {
  const handleChangeNumber = phone => (value) => {
    const useTypeForPhoneNumber = isMobile(value) ? 'mobile' : 'home';
    change(`${phone}.use`, useTypeForPhoneNumber);
  };

  return (
    <FormGrid item xs={12} className={formStyles.repeater}>
      <FormGrid container alignItems="flex-end">
        {fields.map((phone, index, allFields) => (
          <Fragment key={phone}>
            <FormGrid item xs={7} sm={4}>
              <Field
                name={`${phone}.number`}
                component={renderInput}
                label="Phone number"
                autoComplete="tel"
                type="tel"
                required={index === 0}
                validate={[...((index === 0) ? [validPhoneNumber] : [validSecondaryPhoneNumber])]}
                onChange={handleChangeNumber(phone)}
              />
            </FormGrid>
            <FormGrid item xs={5} sm={4}>
              <Field
                name={`${phone}.use`}
                component={renderSelect}
                label="Type"
                data={[
                  { name: 'Home', value: 'home' },
                  { name: 'Work', value: 'work' },
                  { name: 'Temp', value: 'temp' },
                  { name: 'Mobile', value: 'mobile' },
                ]}
                hideEmpty
              />
            </FormGrid>
            {(index === fields.length - 1) &&
              <FormGrid item xs={12} sm={4} className={formStyles.actions}>
                {(fields.length > 1) &&
                  <TertiaryButton
                    onClick={() => fields.remove(index)}
                    data-name={`remove-phone-${index}`}
                    iconLeft={<Close />}
                  >
                    Remove
                  </TertiaryButton>
                }
                <span className={formStyles.btn}>
                  <TertiaryButton
                    onClick={() => fields.push(emptyPhoneRow)}
                    data-name={`add-phone-${index}`}
                    iconLeft={<Add />}
                  >
                    Add
                  </TertiaryButton>
                </span>
              </FormGrid>
            }
            {
              (index < allFields.length - 1) &&
              <Fragment>
                <FormGrid item xs={12} sm={8}>
                  <hr />
                </FormGrid>
                <Hidden only="xs"><FormGrid item sm={4} /></Hidden>
              </Fragment>
            }
          </Fragment>
        ))}
      </FormGrid>
    </FormGrid>
  );
};
renderPhoneNumbers.propTypes = {
  change: PropTypes.func,
  fields: PropTypes.instanceOf(Object),
};
renderPhoneNumbers.defaultProps = {
  change: undefined,
  fields: undefined,
};

export default renderPhoneNumbers;
