import moment from 'moment';

import { isBase64 } from '../Validator';
import { FORMATS } from '../Helpers/constants';

function formatErrorMessages(error) {
  if (!error) return undefined;
  return error.map(e => `${e.message}\n`);
}

const preventDefault = f => (e) => {
  if (e && e.preventDefault) {
    e.preventDefault(); // Protected in if to support test events
  }
  f(e);
};

const decodeHash = (hash) => {
  if (!isBase64(hash)) return { valid: false };
  const arr = atob(hash).split(':');

  if (arr.length !== 3 && arr.length !== 2) return { valid: false };

  return {
    practiceId: arr[0],
    patientId: arr[1],
    sha1MobileNumber: arr.length === 3 ? arr[2] : undefined,
    valid: true,
  };
};

const formatMotifDateToISO = dateString => moment(dateString, [FORMATS.ISO_DATE, FORMATS.DATE]).format(FORMATS.ISO_DATE);

export {
  formatErrorMessages,
  preventDefault,
  decodeHash,
  formatMotifDateToISO,
};
