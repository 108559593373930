import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { renderSearchSelectInput } from '../../../../Helpers/reduxFormMotif';

class SuburbField extends React.Component {
    state = {
      loading: false,
    }

    performRequest = async (query) => {
      const { state, suggestLocality } = this.props;
      this.setState({ loading: true });
      try {
        const resp = await suggestLocality({ query, state });
        const items = resp.data.map(item => ({
          label: `${item.locality} (${item.state})`,
          value: item.locality,
          ...item,
        }));
        this.setState({ loading: false });
        return { data: items };
      } catch (e) {
        this.setState({ loading: false });
        throw e;
      }
    }

    render() {
      const { loading } = this.state;
      const {
        state, suggestLocality, searchInputProps, ...other
      } = this.props;
      return (
        <Field
          component={renderSearchSelectInput}
          performRequest={this.performRequest}
          preserveTextOnSelect
          searchInputProps={{
                    hideSearchIcon: true,
                    endAdornment: loading && <CircularProgress key="spinner" size={28} />,
                    ...searchInputProps,
                }}
          {...other}
        />
      );
    }
}
SuburbField.propTypes = {
  // State of the suburb located in, e.g. QLD
  // this will be passed to locality search to order search result
  state: PropTypes.string,
  suggestLocality: PropTypes.func.isRequired,
  searchInputProps: PropTypes.instanceOf(Object),
};
SuburbField.defaultProps = {
  state: undefined,
  searchInputProps: undefined,
};

export default SuburbField;
