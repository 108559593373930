import styles from './input.module.scss';

export const multilineInputStyleOverrides = {
  classes: {
    root: styles.multilineRoot,
    label: styles.multilineLabel,
  },
};

export const inputStyleOverrides = {
  classes: {
    // Material UI CSS classes
    root: styles.root,
    formControl: styles.formControl,

    // motif custom classes
    label: styles.label,
    labelShrink: styles.labelShrink,
  },
  dynamicLabel: true,
};

export const selectStyleOverrides = {
  classes: {
    // motif custom classes
    root: styles.root,
    inputRoot: styles.formControl,
    selectRoot: styles.selectRoot,
    label: styles.label,
    labelShrink: styles.labelShrink,
  },
  dynamicLabel: true,
};

export const searchSelectInputOverrides = ({ input = {}, ...custom } = {}) => ({
  // give search select input an id to be able to locate popover (will be `${input.name}_popover`)
  id: input.name ? input.name.replace('.', '_') : input.name,
  ...custom,
  searchInputProps: {
    classes: {
      // Material UI CSS classes
      root: styles.root,
      formControl: styles.formControl,

      // motif custom classes
      label: styles.label,
      labelShrink: styles.labelShrink,
    },
    dynamicLabel: true,
    startAdornment: null,
    name: input.name,
    ...custom.searchInputProps,
  },
});

export const datePickerStyleOverrides = {
  classes: {
    // Material UI CSS classes
    root: styles.root,
    formControl: styles.formControl,

    // motif custom classes
    label: styles.label,
    labelShrink: styles.labelShrink,
  },
};
