import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGrid } from 'motif';
import UsualGP from './usualGP';

const UsualGPPanel = ({ formData, change }) => (
  <Fragment>
    <FormGrid container>
      <FormGrid item xs={12}>
        <UsualGP formData={formData} change={change} />
      </FormGrid>
    </FormGrid>
  </Fragment>
);

UsualGPPanel.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
};

UsualGPPanel.defaultProps = {
  formData: {},
};

export default UsualGPPanel;
