import config from '../../config';
import { decodeHash } from '../Auth/helpers';

export const getSlugFromUrl = (url = window.location.href, ppDomain = config.PATIENT_PORTAL_UI_DOMAIN) => {
  if (!url) {
    return undefined;
  }
  const startIndex = url.indexOf(`.${ppDomain}`);
  if (startIndex <= 0) {
    return undefined;
  }
  const start = url.substr(0, startIndex);
  const slug = start.replace('http://', '').replace('https://', '');
  if (!slug || slug === 'www') {
    return undefined;
  }
  return slug;
};

export const getPracticeIdFromRegisterPath = (pathname = window.location.pathname) => {
  if (!pathname.startsWith('/register')) {
    return undefined;
  }
  const hash = pathname.replace('/register/', '');
  return decodeHash(hash).practiceId;
};
