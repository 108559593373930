import { connect } from 'react-redux';
import ThankYou from './component';

const mapStateToProps = state => ({
  practiceLogoURL: state.practicePreferences.logoURL,
  practiceTitle: state.practicePreferences.title,
  practiceSocialMediaFacebookURL: state.practicePreferences.socialMediaFacebookURL,
});

export default connect(mapStateToProps)(ThankYou);
