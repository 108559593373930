import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import PatientDemographicsForm from './component';

const mapStateToProps = state => ({
  formData: getFormValues('patient_form')(state),
  currentPage: state.page.current_page,
  formItems: state.page.formItems,
});

export const defaultValues = {
  phone: [{ number: '', use: 'mobile' }],
  emergency_contact: [{
    given_name: '',
    family_name: '',
    phone_number: '',
    relationship: '',
  }],
  reuse_street_address: true,
  medicare_card: {
    has_medicare_card: true,
  },
  account_holder: {
    has_account_holder: false,
  },
  telehealth: {
    is_telehealth_patient: false,
  },
  private_health: {
    has_private_health: false,
  },
  insurance_claim: {
    has_claim: false,
  },
  usual_gp: {
    has_usual_gp: false,
  },
};

export default connect(mapStateToProps)(PatientDemographicsForm);
