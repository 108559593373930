import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Error } from '../Errors';

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, errorInfo) {
    Error.show(`Unexpected Exception: ${error}`);
    Sentry.captureException({ error, errorInfo });
  }

  render() {
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;

