import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Input,
  Hyperlink,
  PrimaryButton,
  Typography,
  LabelCheckbox,
  FormGrid,
  layout,
} from 'motif';
import {
  Grid,
  Hidden,
} from '@material-ui/core';
import classNames from 'classnames';
import { validateSignUp } from '../Validator';
import { preventDefault, decodeHash, formatMotifDateToISO } from './helpers';
import { inputStyleOverrides } from '../Helpers/input';
import styles from './styles.module.scss';
import { verifyPatientIdentify } from '../../actions/patient';
import * as Analytics from '../../actions/analytics';
import { FORMATS } from '../Helpers/constants';
import { Error } from '../Errors';
import { getLoginUrl } from '../Auth/oauth2';
import { invalidRegistrationLink, notConfirmed, unableToVerify } from './ContactPractice';
import config from '../../config';

const SignUpForm = ({
  match, location, setWindowLocation, publicProfile,
}) => {
  const [validating, setValidating] = useState(false);
  const [formInputs, setFormInputs] = useState({
    birthdate: '',
    termsandconditions: false,
  });
  const [formErrors, setFormErrors] = useState({});

  const handleSignUp = async () => {
    Error.clear();

    const { token } = match.params;

    const { valid: formValid, errors } = validateSignUp(formInputs);
    setFormErrors(errors || {});
    if (!formValid) {
      return;
    }

    const {
      practiceId, patientId, sha1MobileNumber, valid: hashValid,
    } = decodeHash(token);
    if (!hashValid) {
      invalidRegistrationLink();
      return;
    }

    const birthDate = formatMotifDateToISO(formInputs.birthdate);

    try {
      setValidating(true);
      const response = await verifyPatientIdentify(patientId, birthDate);
      setValidating(false);
      const { success } = response;
      if (success) {
        Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.REGISTER, 'Identity Verified');
        setWindowLocation(getLoginUrl({
          state: {
            redirectTo: '/',
            action: 'signup',
            slug: publicProfile.slug,
            sha1MobileNumber, // this is passed to auth-role-integration for creating the Cognito user
            birthDate,
            patientId,
            practiceId,
          },
          location,
          endpoint: 'signup',
        }));
      } else {
        notConfirmed();
      }
    } catch (error) {
      setValidating(false);
      unableToVerify(error);
    }
  };

  const handleFormInputChange = fieldName => (e) => {
    setFormInputs({ ...formInputs, [fieldName]: e.target.value });
  };

  const formInputProps = (fieldName) => {
    const value = formInputs[fieldName];
    const errors = formErrors[fieldName] || [];
    return {
      name: fieldName,
      error: errors.length > 0,
      helperText: errors.map(e => e.message).join('\n'),
      onChange: handleFormInputChange(fieldName),
      value,
    };
  };

  const termsAndConditionsLink = `${config.PATIENT_PORTAL_UI_SCHEME}://${publicProfile.slug}.${config.PATIENT_PORTAL_UI_DOMAIN}/terms/latest/privacy`;

  return (
    <div className={styles.signUpContainer}>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Hidden smDown>
          <Grid item md={6} />
        </Hidden>
        <Grid item xs={12} className={classNames(layout.flex, layout.centerContent)}>
          <form className={styles.smallFormContainer} onSubmit={preventDefault(handleSignUp)} noValidate="novalidate">
            <FormGrid container spacingV={8} className={styles.inputFormGrid}>
              <FormGrid item xs={12}>
                <Typography variant="h5" gutterBottom>Register</Typography>
              </FormGrid>
              <FormGrid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>Welcome to Secure Health Forms</Typography>
              </FormGrid>
            </FormGrid>
            <FormGrid item xs={12}>
              <Input
                {...formInputProps('birthdate')}
                type="text"
                label="Date of Birth"
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                placeholder={FORMATS.DATE}
                {...inputStyleOverrides}
              />
            </FormGrid>
            <FormGrid item xs={12}>
              <LabelCheckbox
                {...formInputProps('termsandconditions')}
                helperText="You must accept to continue"
                label={(
                  <Typography className={layout.fullWidth}>
                    I accept the&nbsp;
                    <Hyperlink href={termsAndConditionsLink} target="_blank" noUnderline>Terms & Conditions</Hyperlink>
                  </Typography>
                )}
              />
            </FormGrid>
            <FormGrid item xs={12} style={{ paddingTop: 10 }} className={layout.alignRight}>
              <PrimaryButton label="Next" type="submit" />
            </FormGrid>
            {validating && (
              <Grid><CircularProgress size={15} /> <span>Verifying your details</span></Grid>
            )}
          </form>
        </Grid>
      </Grid>
    </div >
  );
};
SignUpForm.propTypes = {
  /** location object to allow access to url query string */
  location: PropTypes.shape({
    // code and state parameters are expected in search, state is expected to be a Base64 en
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  publicProfile: PropTypes.instanceOf(Object).isRequired,
  setWindowLocation: PropTypes.func,
};
SignUpForm.defaultProps = {
  setWindowLocation: (url) => { window.location = url; },
};

export default SignUpForm;
