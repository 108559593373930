import qs from 'query-string';
import { Base64 } from 'js-base64';
import config from '../../config';
import { getSlugFromUrl } from '../Helpers/url';

export const getLoginUrl = ({
  state,
  location = { pathname: '/', search: '' },
  endpoint = 'authorize',
  href = window.location.href,
} = {}) => {
  const redirectTo = `${location.pathname}${location.search}`;
  const { COGNITO_USER_POOL_URL } = config;
  const params = {
    client_id: config.COGNITO_USER_POOL_PATIENT_PORTAL_OAUTH2_CLIENT_ID,
    response_type: 'code',
    redirect_uri: config.AUTH_ROLE_INTEGRATION_OAUTH2_REDIRECT_URL,
    state: Base64.encode(JSON.stringify({
      redirectTo,
      slug: getSlugFromUrl(href),
      ...state,
    }), true),
  };
  return `${COGNITO_USER_POOL_URL}/${endpoint}?${qs.stringify(params)}`;
};
