import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  setPanel,
  setPage,
  setflattenFormItemsCursor,
} from '../../../actions/page';
import PageComponent from './pageComponent';

const mapStateToProps = state => ({
  practicePreferences: state.practicePreferences,
  currentPage: state.page.current_page,
  currentPanel: state.page.current_panel,
  flattenFormItemsCursor: state.page.flatten_form_items_cursor,
  formItems: state.page.formItems,
});

const mapDispatchToProps = {
  setPanel,
  setPage,
  setflattenFormItemsCursor,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'patient_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PageComponent));
