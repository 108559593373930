import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from '../App/context';
import { getLoginUrl } from '../Auth/oauth2';
import Loader from '../Loader';

const EnsureLoggedIn = ({ children, location, setWindowLocation }) => {
  const { isLoggedIn } = useAppContext();
  useEffect(() => {
    if (isLoggedIn) {
      return;
    }
    setWindowLocation(getLoginUrl({ location }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <Loader />;
  }
  return children;
};
EnsureLoggedIn.propTypes = {
  /** router location object, will be used to get state for login url */
  location: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
  setWindowLocation: PropTypes.func,
};
EnsureLoggedIn.defaultProps = {
  setWindowLocation: (url) => { window.location = url; },
};
export default EnsureLoggedIn;
