import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  PrimaryButton,
  Typography,
  layout,
  IconButton,
} from 'motif';
import { KeyboardArrowLeft } from '@material-ui/icons';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import cookies from 'js-cookie';
import * as Analytics from '../../../actions/analytics';
import styles from './styles.module.scss';
import * as sentry from '../../App/sentry';
import { getLoginUrl } from '../../Auth/oauth2';
import { useAppContext } from '../../App/context';
import { usePracticeContext } from '../../App/practiceContext';
import { setflattenFormItemsCursor as setflattenFormItemsCursorAction } from '../../../actions/page';
import PreferencesError from './PreferencesError';
import config from '../../../config';

class AppBar extends React.PureComponent {
  // By doing global sign out, all the auth tokens are revoked and the user is signed out from all the devices
  handleClick = async ({
    patientId,
    practiceId,
    slug,
  }) => {
    Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.SIGN_OUT, 'Signed Out');
    sentry.setUser(undefined);
    cookies.remove('cognitoIdToken');
    cookies.remove('cognitoRefreshToken');
    cookies.remove('rolesToken');
    window.location = getLoginUrl({
      endpoint: 'logout',
      state: {
        practiceId,
        patientId,
        slug,
      },
    });
  }

  render() {
    const {
      preferenceLoadingFailure, preferencesLoadingError, children, pageCount, currentPage, setPage, setPanel, setflattenFormItemsCursor, flattenFormItemsCursor, formItems,
    } = this.props;

    const { handleClick } = this;
    return (
      <useAppContext.Context.Consumer>{
        ({ isLoggedIn, patientId }) => (
          <div className={styles.root}>
            <MuiAppBar position="fixed">
              <Toolbar classes={{
                root: classNames({
                  [styles.devAsEnvironment]: !!config.DEV_AS_ENVIRONMENT,
                }),
              }}
              >
                {
                  (isLoggedIn && currentPage > 0 && currentPage < pageCount) &&
                  <IconButton
                    onClick={() => {
                      setPage(currentPage - 1);
                      setPanel(formItems[currentPage - 1].item.length - 1);
                      setflattenFormItemsCursor(flattenFormItemsCursor - 1);
                    }}
                    className={styles.back}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                }
                <Typography variant="h6" color="primaryDark" className={styles.grow}>
                  Secure Health Forms
                </Typography>
                <usePracticeContext.Context.Consumer>{
                  ({ publicProfile }) => (!!publicProfile && isLoggedIn) && <PrimaryButton
                    onClick={() => {
                      handleClick({
                        patientId,
                        practiceId: publicProfile.tenantId,
                        slug: publicProfile.slug,
                      });
                    }}
                    label="Sign out"
                  />
                }
                </usePracticeContext.Context.Consumer>
              </Toolbar>
            </MuiAppBar>
            <PreferencesError loadingFailure={preferenceLoadingFailure} loadingError={preferencesLoadingError} />
            <div className={layout.flex}>
              <div className={styles.contentContainer}>
                <usePracticeContext.Context.Consumer>{
                  ({ publicProfile }) => (!!publicProfile) && children
                }
                </usePracticeContext.Context.Consumer>
              </div>
            </div>
          </div>
        )}
      </useAppContext.Context.Consumer>
    );
  }
}

AppBar.propTypes = {
  children: PropTypes.node,
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  setPage: PropTypes.func,
  preferenceLoadingFailure: PropTypes.bool,
  preferencesLoadingError: PropTypes.string,
  setPanel: PropTypes.func,
  formItems: PropTypes.instanceOf(Array),
  setflattenFormItemsCursor: PropTypes.func,
  flattenFormItemsCursor: PropTypes.number,
};

AppBar.defaultProps = {
  children: undefined,
  currentPage: 0,
  setPage: undefined,
  pageCount: undefined,
  preferenceLoadingFailure: undefined,
  preferencesLoadingError: undefined,
  setPanel: undefined,
  formItems: [],
  setflattenFormItemsCursor: undefined,
  flattenFormItemsCursor: 0,
};

const mapStateToProps = state => ({
  preferenceLoadingFailure: state.practicePreferences.loadingFailure,
  preferencesLoadingError: state.practicePreferences.error,
  formItems: state.page.formItems,
});

const mapDispatchToProps = {
  setflattenFormItemsCursor: setflattenFormItemsCursorAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
