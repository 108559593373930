import { createMuiTheme } from '@material-ui/core/styles';
import themeScss from './theme_export.scss';

/**
 * This is the main theme for Gentu.
 * It just maps the values exported from theme.scss into the JSS
 * that Material UI themes expect.
 * If you need to modify the theme, do that in theme.scss.
 *
 * Usage:
 * import theme from './components/lib/theme';
 * ...
 * <MuiThemeProvider theme={theme}>
 */

const theme = createMuiTheme({
  direction: 'ltr',
  palette: {
    color: themeScss.palettePrimaryMain,
    common: {
      black: themeScss.paletteCommonBlack,
      white: themeScss.paletteCommonWhite,
      transparent: themeScss.paletteCommonTransparent,
      fullBlack: themeScss.paletteCommonFullBlack,
      darkBlack: themeScss.paletteCommonDarkBlack,
      lightBlack: themeScss.paletteCommonLightBlack,
      minBlack: themeScss.paletteCommonMinBlack,
      faintBlack: themeScss.paletteCommonFaintBlack,
      fullWhite: themeScss.paletteCommonFullWhite,
      darkWhite: themeScss.paletteCommonDarkWhite,
      lightWhite: themeScss.paletteCommonLightWhite,
    },
    type: 'light',
    primary: {
      50: themeScss.palettePrimary50,
      100: themeScss.palettePrimary100,
      200: themeScss.palettePrimary200,
      300: themeScss.palettePrimary300,
      400: themeScss.palettePrimary400,
      500: themeScss.palettePrimaryMain,
      600: themeScss.palettePrimary600,
      700: themeScss.palettePrimary700,
      800: themeScss.palettePrimary800,
      900: themeScss.palettePrimary900,
      A100: themeScss.palettePrimaryA100,
      A200: themeScss.palettePrimaryA200,
      A400: themeScss.palettePrimaryA400,
      A700: themeScss.palettePrimaryA700,
      contrastDefaultColor: 'light',
      light: themeScss.palettePrimaryLight,
      main: themeScss.palettePrimaryMain,
      dark: themeScss.palettePrimaryDark,
    },
    secondary: {
      50: themeScss.paletteSecondary50,
      100: themeScss.paletteSecondary100,
      200: themeScss.paletteSecondary200,
      300: themeScss.paletteSecondary300,
      400: themeScss.paletteSecondary400,
      500: themeScss.paletteSecondary500,
      600: themeScss.paletteSecondary600,
      700: themeScss.paletteSecondary700,
      800: themeScss.paletteSecondary800,
      900: themeScss.paletteSecondary900,
      A100: themeScss.paletteSecondaryA100,
      A200: themeScss.paletteSecondaryA200,
      A400: themeScss.paletteSecondaryA400,
      A700: themeScss.paletteSecondaryA700,
      A800: themeScss.paletteSecondaryA800,
      contrastDefaultColor: 'light',
      light: themeScss.paletteSecondaryLight,
      main: themeScss.paletteSecondaryMain,
      dark: themeScss.paletteSecondaryDark,
    },
    error: {
      50: themeScss.paletteError50,
      100: themeScss.paletteError100,
      200: themeScss.paletteError200,
      300: themeScss.paletteError300,
      400: themeScss.paletteError400,
      500: themeScss.paletteError500,
      600: themeScss.paletteError600,
      700: themeScss.paletteError700,
      800: themeScss.paletteError800,
      900: themeScss.paletteError900,
      A100: themeScss.paletteErrorA100,
      A200: themeScss.paletteErrorA200,
      A400: themeScss.paletteErrorA400,
      A700: themeScss.paletteErrorA700,
      contrastDefaultColor: 'light',
      light: themeScss.paletteErrorLight,
      main: themeScss.paletteErrorMain,
      dark: themeScss.paletteErrorDark,
    },
    grey: {
      50: themeScss.paletteGrey50,
      100: themeScss.paletteGrey100,
      200: themeScss.paletteGrey200,
      300: themeScss.paletteGrey300,
      400: themeScss.paletteGrey400,
      500: themeScss.paletteGrey500,
      600: themeScss.paletteGrey600,
      700: themeScss.paletteGrey700,
      800: themeScss.paletteGrey800,
      900: themeScss.paletteGrey900,
      A100: themeScss.paletteGreyA100,
      A200: themeScss.paletteGreyA200,
      A400: themeScss.paletteGreyA400,
      A700: themeScss.paletteGreyA700,
      contrastDefaultColor: 'dark',
    },
    shades: {
      dark: {
        text: {
          primary: themeScss.paletteShadesDarkTextPrimary,
          secondary: themeScss.paletteShadesDarkTextSecondary,
          disabled: themeScss.paletteShadesDarkTextDisabled,
          hint: themeScss.paletteShadesDarkTextHint,
          icon: themeScss.paletteShadesDarkTextIcon,
          divider: themeScss.paletteShadesDarkTextDivider,
          lightDivider: themeScss.paletteShadesDarkTextLightDivider,
        },
        input: {
          bottomLine: themeScss.paletteShadesDarkInputBottomLine,
          helperText: themeScss.paletteShadesDarkInputHelperText,
          labelText: themeScss.paletteShadesDarkInputLabelText,
          inputText: themeScss.paletteShadesDarkInputInputText,
          disabled: themeScss.paletteShadesDarkInputDisabled,
        },
        action: {
          active: themeScss.paletteShadesDarkActionActive,
          disabled: themeScss.paletteShadesDarkActionDisabled,
        },
        background: {
          default: themeScss.paletteShadesDarkBackgroundDefault,
          paper: themeScss.paletteShadesDarkBackgroundPaper,
          appBar: themeScss.paletteShadesDarkBackgroundAppBar,
          contentFrame: themeScss.paletteShadesDarkBackgroundContentFrame,
        },
      },
      light: {
        text: {
          primary: themeScss.paletteShadesLightTextPrimary,
          secondary: themeScss.paletteShadesLightTextSecondary,
          disabled: themeScss.paletteShadesLightTextDisabled,
          hint: themeScss.paletteShadesLightTextHint,
          icon: themeScss.paletteShadesLightTextIcon,
          divider: themeScss.paletteShadesLightTextDivider,
          lightDivider: themeScss.paletteShadesLightTextLightDivider,
        },
        input: {
          bottomLine: themeScss.paletteShadesLightInputBottomLine,
          helperText: themeScss.paletteShadesLightInputHelperText,
          labelText: themeScss.paletteShadesLightInputLabelText,
          inputText: themeScss.paletteShadesLightInputInputText,
          disabled: themeScss.paletteShadesLightInputDisabled,
        },
        action: {
          active: themeScss.paletteShadesLightActionActive,
          disabled: themeScss.paletteShadesLightActionDisabled,
        },
        background: {
          default: themeScss.paletteShadesLightBackgroundDefault,
          paper: themeScss.paletteShadesLightBackgroundPaper,
          appBar: themeScss.paletteShadesLightBackgroundAppBar,
          contentFrame: themeScss.paletteShadesLightBackgroundContentFrame,
        },
      },
    },
    text: {
      primary: themeScss.paletteTextPrimary,
      secondary: themeScss.paletteTextSecondary,
      disabled: themeScss.paletteTextDisabled,
      hint: themeScss.paletteTextHint,
      icon: themeScss.paletteTextIcon,
      divider: themeScss.paletteTextDivider,
      lightDivider: themeScss.paletteTextLightDivider,
    },
    input: {
      bottomLine: themeScss.paletteInputBottomLine,
      helperText: themeScss.paletteInputHelperText,
      labelText: themeScss.paletteInputLabelText,
      inputText: themeScss.paletteInputInputText,
      disabled: themeScss.paletteInputDisabled,
    },
    action: {
      active: themeScss.paletteActionActive,
      disabled: themeScss.paletteActionDisabled,
    },
    background: {
      default: themeScss.paletteBackgroundDefault,
      paper: themeScss.paletteBackgroundPaper,
      appBar: themeScss.paletteBackgroundAppBar,
      contentFrame: themeScss.paletteBackgroundContentFrame,
    },
  },
  shape: {
    borderRadius: themeScss.shapeBorderRadius * 1,
  },
  typography: {
    fontFamily: themeScss.typographyFontFamily,
    fontSize: themeScss.typographyFontSize * 1,
    fontWeightLight: themeScss.typographyFontWeightLight,
    fontWeightRegular: themeScss.typographyFontWeightRegular,
    fontWeightMedium: themeScss.typographyFontWeightMedium,

    useNextVariants: true,

    // Deprecated typography variant names
    display4: {
      fontSize: themeScss.typographyDisplay4FontSize,
      fontWeight: themeScss.typographyDisplay4FontWeight,
      fontFamily: themeScss.typographyDisplay4FontFamily,
      letterSpacing: themeScss.typographyDisplay4LetterSpacing,
      lineHeight: themeScss.typographyDisplay4LineHeight,
      marginLeft: themeScss.typographyDisplay4MarginLeft,
      color: themeScss.typographyDisplay4Color,
    },
    display3: {
      fontSize: themeScss.typographyDisplay3FontSize,
      fontWeight: themeScss.typographyDisplay3FontWeight,
      fontFamily: themeScss.typographyDisplay3FontFamily,
      letterSpacing: themeScss.typographyDisplay3LetterSpacing,
      lineHeight: themeScss.typographyDisplay3LineHeight,
      marginLeft: themeScss.typographyDisplay3MarginLeft,
      color: themeScss.typographyDisplay3Color,
    },
    display2: {
      fontSize: themeScss.typographyDisplay2FontSize,
      fontWeight: themeScss.typographyDisplay2FontWeight,
      fontFamily: themeScss.typographyDisplay2FontFamily,
      lineHeight: themeScss.typographyDisplay2LineHeight,
      marginLeft: themeScss.typographyDisplay2MarginLeft,
      color: themeScss.typographyDisplay2Color,
    },
    display1: {
      fontSize: themeScss.typographyDisplay1FontSize,
      fontWeight: themeScss.typographyDisplay1FontWeight,
      fontFamily: themeScss.typographyDisplay1FontFamily,
      lineHeight: themeScss.typographyDisplay1LineHeight,
      marginLeft: themeScss.typographyDisplay1MarginLeft,
      color: themeScss.typographyDisplay1Color,
    },
    headline: {
      fontSize: themeScss.typographyHeadlineFontSize,
      fontWeight: themeScss.typographyHeadlineFontWeight,
      fontFamily: themeScss.typographyHeadlineFontFamily,
      lineHeight: themeScss.typographyHeadlineLineHeight,
      color: themeScss.typographyHeadlineColor,
    },
    title: {
      fontSize: themeScss.typographyTitleFontSize,
      fontWeight: themeScss.typographyTitleFontWeight,
      fontFamily: themeScss.typographyTitleFontFamily,
      lineHeight: themeScss.typographyTitleLineHeight,
      color: themeScss.typographyTitleColor,
    },
    subheading: {
      fontSize: themeScss.typographySubheadingFontSize,
      fontWeight: themeScss.typographySubheadingFontWeight,
      fontFamily: themeScss.typographySubheadingFontFamily,
      lineHeight: themeScss.typographySubheadingLineHeight,
      color: themeScss.typographySubheadingColor,
    },

    // Current typography variant names (as of Material-UI v3.8)
    h1: {
      fontSize: themeScss.typographyH1FontSize,
      fontWeight: themeScss.typographyH1FontWeight,
      fontFamily: themeScss.typographyH1FontFamily,
      letterSpacing: themeScss.typographyH1LetterSpacing,
      lineHeight: themeScss.typographyH1LineHeight,
      marginLeft: themeScss.typographyH1MarginLeft,
      color: themeScss.typographyH1Color,
    },
    h2: {
      fontSize: themeScss.typographyH2FontSize,
      fontWeight: themeScss.typographyH2FontWeight,
      fontFamily: themeScss.typographyH2FontFamily,
      letterSpacing: themeScss.typographyH2LetterSpacing,
      lineHeight: themeScss.typographyH2LineHeight,
      marginLeft: themeScss.typographyH2MarginLeft,
      color: themeScss.typographyH2Color,
    },
    h3: {
      fontSize: themeScss.typographyH3FontSize,
      fontWeight: themeScss.typographyH3FontWeight,
      fontFamily: themeScss.typographyH3FontFamily,
      lineHeight: themeScss.typographyH3LineHeight,
      marginLeft: themeScss.typographyH3MarginLeft,
      color: themeScss.typographyH3Color,
    },
    h4: {
      fontSize: themeScss.typographyH4FontSize,
      fontWeight: themeScss.typographyH4FontWeight,
      fontFamily: themeScss.typographyH4FontFamily,
      lineHeight: themeScss.typographyH4LineHeight,
      marginLeft: themeScss.typographyH4MarginLeft,
      color: themeScss.typographyH4Color,
    },
    h5: {
      fontSize: themeScss.typographyH5FontSize,
      fontWeight: themeScss.typographyH5FontWeight,
      fontFamily: themeScss.typographyH5FontFamily,
      lineHeight: themeScss.typographyH5LineHeight,
      color: themeScss.typographyH5Color,
    },
    h6: {
      fontSize: themeScss.typographyH6FontSize,
      fontWeight: themeScss.typographyH6FontWeight,
      fontFamily: themeScss.typographyH6FontFamily,
      lineHeight: themeScss.typographyH6LineHeight,
      color: themeScss.typographyH6Color,
    },
    subtitle1: {
      fontSize: themeScss.typographySubtitle1FontSize,
      fontWeight: themeScss.typographySubtitle1FontWeight,
      fontFamily: themeScss.typographySubtitle1FontFamily,
      lineHeight: themeScss.typographySubtitle1LineHeight,
      color: themeScss.typographySubtitle1Color,
    },

    body2: {
      fontSize: themeScss.typographyBody2FontSize,
      fontWeight: themeScss.typographyBody2FontWeight,
      fontFamily: themeScss.typographyBody2FontFamily,
      lineHeight: themeScss.typographyBody2LineHeight,
      color: themeScss.typographyBody2Color,
    },
    body1: {
      fontSize: themeScss.typographyBody1FontSize,
      fontWeight: themeScss.typographyBody1FontWeight,
      fontFamily: themeScss.typographyBody1FontFamily,
      lineHeight: themeScss.typographyBody1LineHeight,
      color: themeScss.typographyBody1Color,
    },
    caption: {
      fontSize: themeScss.typographyCaptionFontSize,
      fontWeight: themeScss.typographyCaptionFontWeight,
      fontFamily: themeScss.typographyCaptionFontFamily,
      lineHeight: themeScss.typographyCaptionLineHeight,
      color: themeScss.typographyCaptionColor,
    },
    button: {
      fontSize: themeScss.typographyButtonFontSize * 1,
      textTransform: themeScss.typographyButtonTextTransform,
      fontWeight: themeScss.typographyButtonFontWeight,
      fontFamily: themeScss.typographyButtonFontFamily,
    },
  },
  mixins: {
    toolbar: {
      minHeight: themeScss.mixinsToolbarMinHeight * 1,
    },
  },
  breakpoints: {
    values: {
      xs: themeScss.breakpointsValuesXS * 1,
      sm: themeScss.breakpointsValuesSM * 1,
      md: themeScss.breakpointsValuesMD * 1,
      lg: themeScss.breakpointsValuesLG * 1,
      xl: themeScss.breakpointsValuesXL * 1,
    },
  },
  shadows: [
    themeScss.shadows0,
    themeScss.shadows1,
    themeScss.shadows2,
    themeScss.shadows3,
    themeScss.shadows4,
    themeScss.shadows5,
    themeScss.shadows6,
    themeScss.shadows7,
    themeScss.shadows8,
    themeScss.shadows9,
    themeScss.shadows10,
    themeScss.shadows11,
    themeScss.shadows12,
    themeScss.shadows13,
    themeScss.shadows14,
    themeScss.shadows15,
    themeScss.shadows16,
    themeScss.shadows17,
    themeScss.shadows18,
    themeScss.shadows19,
    themeScss.shadows20,
    themeScss.shadows21,
    themeScss.shadows22,
    themeScss.shadows23,
    themeScss.shadows24,
  ],
  transitions: {
    easing: {
      easeInOut: themeScss.transitionsEasingEaseInOut,
      easeOut: themeScss.transitionsEasingEaseOut,
      easeIn: themeScss.transitionsEasingEaseIn,
      sharp: themeScss.transitionsEasingSharp,
    },
    duration: {
      shortest: themeScss.transitionsDurationShortest * 1,
      shorter: themeScss.transitionsDurationShorter * 1,
      short: themeScss.transitionsDurationShort * 1,
      standard: themeScss.transitionsDurationStandard * 1,
      complex: themeScss.transitionsDurationComplex * 1,
      enteringScreen: themeScss.transitionsDurationEnteringScreen * 1,
      leavingScreen: themeScss.transitionsDurationLeavingScreen * 1,
    },
  },
  spacing: {
    unitClose: themeScss.spacingUnitClose * 1,
    unit: themeScss.spacingUnit * 1,
  },
  zIndex: {
    mobileStepper: themeScss.zIndexMobileStepper,
    menu: themeScss.zIndexMenu,
    appBar: themeScss.zIndexAppBar,
    drawerOverlay: themeScss.zIndexDrawerOverlay,
    navDrawer: themeScss.zIndexNavDrawer,
    dialogOverlay: themeScss.zIndexDialogOverlay,
    dialog: themeScss.zIndexDialog,
    modal: themeScss.zIndexDialog,
    layer: themeScss.zIndexLayer,
    popover: themeScss.zIndexPopover,
    snackbar: themeScss.zIndexSnackbar,
    tooltip: themeScss.zIndexTooltip,
  },

  secondaryBaseColor: themeScss.secondaryBaseColor,
  brandPink: themeScss.brandPink,
  brandRed: themeScss.brandRed,
  brandGreen: themeScss.brandGreen,
  informationalBlue: themeScss.informationalBlue,
  success: themeScss.success,
  warning: themeScss.warning,
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': { // underline color when hovered
          borderBottom: `2px solid ${themeScss.primaryBaseColor}`,
        },
        '&:after': { // underline color when focused
          borderBottom: `2px solid ${themeScss.primaryBaseColor}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': { // label color when focused
          color: themeScss.primaryBaseColor,
        },
      },
    },
  },
});

export default theme;
