import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import ResponseLoader from './component';
import { setPageCount, setPage, setPanel, setflattenFormItemsCursor, setFormItems } from '../../../../actions/page';

const mapStateToProps = state => ({
  currentPage: state.page.current_page,
  currentPanel: state.page.current_panel,
  practiceId: state.practicePreferences.tenantId,
  practiceTitle: state.practicePreferences.title,
  practiceLogoURL: state.practicePreferences.logoURL,
  practiceName: state.practicePreferences.tenantName,
  preferencesLoaded: !!state.practicePreferences.tenantName,
  practicePreferences: state.practicePreferences,
  formItems: state.page.formItems,
});

const mapDispatchToProps = {
  destroy,
  setPage,
  setPanel,
  setPageCount,
  setflattenFormItemsCursor,
  setFormItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponseLoader);
