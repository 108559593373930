import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import {
  FormGrid,
} from 'motif';
import renderEmergencyContact from './emergencyContact';
import formStyles from '../../../common/styles.module.scss';

const EmergencyContactForm = ({ change }) => (
  <FormGrid container spacingV={8} className={formStyles.repeater}>
    <FieldArray
      name="emergency_contact"
      component={renderEmergencyContact}
      change={change}
    />
  </FormGrid>
);
EmergencyContactForm.propTypes = {
  change: PropTypes.func,
};
EmergencyContactForm.defaultProps = {
  change: undefined,
};

export default EmergencyContactForm;
