import React from 'react';
import classNames from 'classnames';
import { layout } from 'motif';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = () => (
  <div className={classNames(layout.flex, layout.centerContent)}>
    <CircularProgress />
  </div>
);

export default Loader;
