import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGrid,
  Typography,
  layout,
  ButtonGroup } from 'motif';
import { usePracticeContext } from '../../../../App/practiceContext';
import { AttachmentListItem } from './AttachmentListItem';
import { AgreeToTermsField } from './AgreeToTermsField';
import { AttachmentDropzone } from './AttachmentDropzone';
import { Error } from '../../../../Errors';
import { uploadAttachmentToS3 } from './helpers';

const Attachments = ({
  updateAttachmentStepButtonState,
  attachmentStepButtonState,
  attachments,
  change,
}) => {
  const attachmentsUploadedState = attachments.map(a => a.uploaded);
  useEffect(() => {
    const showUploadButton = attachmentsUploadedState.some(uploaded => !uploaded);
    updateAttachmentStepButtonState(showUploadButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsUploadedState]);

  const { publicProfile } = usePracticeContext();
  const [agreedToTerms, changeAgreedToTerms] = useState(attachments.length > 0);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const listRemove = (compKey) => {
    const attachmentsCopy = [...attachments];
    attachmentsCopy.forEach((attachment, index) => {
      if (attachment.compKey === compKey) {
        attachmentsCopy.splice(index, 1);
      }
    });
    change('attachments', attachmentsCopy);
  };

  const attachmentToS3 = async () => {
    setUploadInProgress(true);

    const attachmentsCopy = [...attachments];
    const uploads = attachmentsCopy.map(async (attachment, index) => {
      if (!attachment.uploaded) {
        const s3KeyName = await uploadAttachmentToS3(attachment);
        attachmentsCopy[index].s3KeyName = s3KeyName;
        attachmentsCopy[index].uploaded = true;
      }
    });
    try {
      await Promise.all(uploads);
    } catch (e) {
      setUploadInProgress(false);
      throw e;
    }

    change('attachments', attachmentsCopy);
    setUploadInProgress(false);
  };

  const buildUploadObject = (acceptedFile) => {
    const modifiedFile = acceptedFile;
    modifiedFile.compKey = `${modifiedFile.name}_${Math.floor(Math.random() * 1000)}`;
    updateAttachmentStepButtonState(true);
    return modifiedFile;
  };

  const handleDrop = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length) {
      Error.show('Files are either over 50mb, or invalid types. Valid file types are JPG, JPEG and PDF.');
      return;
    }
    const newAttachments = acceptedFiles.map(buildUploadObject);
    const attachmentsCopy = [...attachments, ...newAttachments];
    change('attachments', attachmentsCopy);
  };

  const renderUploadButton = () => (
    <FormGrid item xs={12} className={classNames(layout.floatRight, layout.marginTop)}>
      <ButtonGroup
        tertiary={{
        label: 'Upload',
        key: 'upload',
        onClick: attachmentToS3,
        blockingAction: true,
        id: 'upload-attachments',
        disabled: uploadInProgress,
      }}
      />
    </FormGrid>
  );

  return (
    <FormGrid container spacingV={8}>
      <FormGrid item xs={12}>
        <Typography>Attach your referral and other relevant documents.</Typography>
      </FormGrid>
      <FormGrid item xs={12}>
        <AttachmentDropzone
          onDrop={handleDrop}
          disabled={!agreedToTerms}
          disabledMessage="Please accept the terms below"
          uploadInProgress={uploadInProgress}
        />
      </FormGrid>
      <FormGrid item xs={12}>
        <AgreeToTermsField
          practiceSlug={publicProfile.slug}
          agreedToTerms={agreedToTerms}
          changeAgreedToTerms={changeAgreedToTerms}
        />
      </FormGrid>
      <FormGrid item xs={12}>
        {
          attachments && attachments.map((attachment, index) => (<AttachmentListItem
            key={attachment.compKey}
            index={index}
            attachment={attachment}
            listRemove={() => listRemove(attachment.compKey)}
          />))}
      </FormGrid>
      {attachmentStepButtonState && renderUploadButton()}
    </FormGrid>
  );
};

Attachments.propTypes = {
  updateAttachmentStepButtonState: PropTypes.func.isRequired,
  attachmentStepButtonState: PropTypes.bool,
  attachments: PropTypes.arrayOf(Object),
  change: PropTypes.func.isRequired,
};

Attachments.defaultProps = {
  attachmentStepButtonState: false,
  attachments: [],
};

export default Attachments;
