// TODO fix disabled eslint
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Typography, buttonStates, Callout } from 'motif';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Warning } from '@material-ui/icons';
import { fhirToForm, formToFhir, processFormItems } from '../transforms';
import styles from '../styles.module.scss';
import Loader from '../../../Loader';
import PatientDemographics, { defaultValues as PatientDemographicsDefaultValues } from '../../demographics';
import errorMessageMapping from '../../../../errorMessages';
import {
  patientTitle,
  patientFirstName,
  patientMiddleName,
  patientPreferredName,
  patientFamilyName,
  patientMaidenName,
  patientGender,
  patientBirthDate,
  patientPhoneNumber,
} from '../../../Helpers/fhir';
import {
  updateQuestionnaireResponse,
  createQuestionnaireResponse,
} from '../../../../actions/questionnaire-response';
import { getQuestionnairesWithName } from '../../../../actions/questionnaire';
import { now } from '../../../Helpers/dates';
import { findPanelIndexInFlattenFormItem, getExistPanelIndex } from '../../../Helpers/utils';
import { Error } from '../../../Errors';
import { useAppContext } from '../../../App/context';

/**
 * Custom hook to get questionnaire and patient information
 */
const useQuestionnaire = (questionnaireResponse) => {
  const [loading, setLoading] = useState(false);
  const [questionnaire, setQuestionnaire] = useState(undefined);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    if (!questionnaireResponse) return;
    setLoading(true);
    getQuestionnairesWithName(questionnaireResponse.questionnaire)
      .then((questionnaires) => {
        setLoading(false);
        if (!isSubscribed) return null;
        if (questionnaires.length === 0) {
          setErrors(errors => [...errors, 'Can\'t find questionnaire']);
          return;
        }
        if (questionnaires.length > 1) {
          setErrors(errors => [...errors, 'More than one questionnaire found']);
        }
        setQuestionnaire(questionnaires[0]);
      })
      .catch((err) => {
        setLoading(false);
        if (!isSubscribed) return null;
        setErrors(errors => [...errors, `Can't load form: ${err}`]);
      });

    return () => (isSubscribed = false);
  }, [questionnaireResponse]);

  return {
    questionnaire, loading, errors,
  };
};

/**
 * FormLoader fetches the questionnaireResponse (if any) and passes it to the form
 * It also submits form data back to the API.
 */
const FormLoader = ({
  practiceName,
  practiceTitle,
  practiceLogoURL,
  preferencesLoaded,
  practicePreferences,
  practiceId,
  patientId,
  questionnaireResponse,
  destroy,
  currentPage,
  currentPanel,
  setPageCount,
  setPage,
  setPanel,
  setFormItems,
  setflattenFormItemsCursor,
  formItems,
  patient,
}) => {
  const { cognitoUser } = useAppContext();
  const defaultValues = PatientDemographicsDefaultValues;
  const {
    questionnaire, loading, errors,
  } = useQuestionnaire(questionnaireResponse);
  const [initialValues, setInitialValues] = useState(undefined);
  const [submitButtonState, setSubmitButtonState] = useState(buttonStates.default);
  const pageCount = formItems.length;
  const isLastPage = (currentPage === pageCount - 1);

  useEffect(() => {
    if (questionnaire && practicePreferences) {
      const items = processFormItems(questionnaire, practicePreferences);
      setFormItems(items);
    }
  }, [questionnaire, practicePreferences, setFormItems]);

  // remove form from redux on unmount
  useEffect(() => () => { destroy(PatientDemographics.formName); }, [destroy]);

  useEffect(() => {
    if (!questionnaireResponse) {
      return undefined;
    }

    // Pre-populate form with basic patient information we already know
    defaultValues.email = cognitoUser ? cognitoUser.email : '';

    if (patient) {
      defaultValues.title = patientTitle(patient);
      defaultValues.given_name = patientFirstName(patient);
      defaultValues.middle_name = patientMiddleName(patient);
      defaultValues.preferred_name = patientPreferredName(patient);
      defaultValues.family_name = patientFamilyName(patient);
      defaultValues.maiden_name = patientMaidenName(patient);
      defaultValues.gender = patientGender(patient);
      defaultValues.birthdate = patientBirthDate(patient);
      defaultValues.phone[0].number = patientPhoneNumber(patient);
    }

    const initialValues = fhirToForm(questionnaireResponse, questionnaire, defaultValues);

    if (Object.keys(initialValues).length === 0) {
      return undefined;
    }

    setInitialValues(initialValues);

    let _currentPage = 0;
    let initialAnalyticIndex = 0;
    let panelIndex = 0;

    if (initialValues.current_page !== undefined && initialValues.current_panel !== undefined && formItems.length !== 0) {
      if (formItems[initialValues.current_page]) {
        _currentPage = initialValues.current_page;
      }

      initialAnalyticIndex = findPanelIndexInFlattenFormItem({
        formItems,
        currentPage: initialValues.current_page,
        currentPanel: initialValues.current_panel,
      });

      panelIndex = getExistPanelIndex({
        currentPageFormItems: formItems[_currentPage],
        currentPanel: initialValues.current_panel,
      });
    }

    setPage(_currentPage);
    setPanel(panelIndex);
    setflattenFormItemsCursor(initialAnalyticIndex);
    setPageCount(pageCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireResponse, questionnaire, formItems.length, pageCount]);

  const submitSuccess = (response) => {
    setSubmitButtonState(buttonStates.success);
    return response;
  };

  const submitFail = (err) => {
    setSubmitButtonState(buttonStates.error);
    const message = errorMessageMapping(err);
    const errorMessage = (
      <span>
        There was a problem saving the form. {message}.
      </span>
    );
    Error.show(errorMessage);
    return Promise.reject();
  };

  const submitFinal = () => {
    setTimeout(() => {
      setSubmitButtonState(buttonStates.default);
    }, 1000);
  };

  const sanityCheckPayloadForDifferentCharacters = (payload) => {
    // if the string contains a smart quote, change it to a normal quote.
    const changeAlternateQuotesToApostrophe = string => string.replace(/’|`/g, "'");
    const updatedPayload = { ...payload };
    const contactKeysToCheck = ['given_name', 'middle_name', 'maiden_name', 'family_name', 'preferred_name'];
    contactKeysToCheck.forEach((key) => {
      if (typeof payload[key] === 'string') {
        updatedPayload[key] = changeAlternateQuotesToApostrophe(payload[key]);
      }
    });
    if (payload.emergency_contact !== undefined) {
      const emergencyContactKeysToCheck = ['given_name', 'family_name', 'first_name'];
      emergencyContactKeysToCheck.forEach((key) => {
        payload.emergency_contact.forEach((_contact, i) => {
          if (typeof payload.emergency_contact[i][key] === 'string') {
            updatedPayload.emergency_contact[i][key] = changeAlternateQuotesToApostrophe(payload.emergency_contact[i][key]);
          }
        });
      });
    }
    const termsContactKeysToCheck = ['family_name', 'first_name'];
    termsContactKeysToCheck.forEach((key) => {
      if (payload.privacy !== undefined) {
        updatedPayload.privacy[key] = changeAlternateQuotesToApostrophe(payload.privacy[key]);
      }
      if (payload.fee !== undefined) {
        updatedPayload.fee[key] = changeAlternateQuotesToApostrophe(payload.fee[key]);
      }
    });
    return updatedPayload;
  };

  const handleSubmit = (formData) => {
    Error.clear();
    const formDataChecked = sanityCheckPayloadForDifferentCharacters(formData);
    setSubmitButtonState(buttonStates.busy);
    const isLastPanel = currentPanel === formItems.map(item => item.item.length)[currentPage] - 1;
    const _formData = { ...formDataChecked };

    if (isLastPanel) {
      // go to first panel on next page
      _formData.current_panel = 0;
      _formData.current_page = currentPage + 1;
    } else {
      _formData.current_panel = currentPanel + 1;
      _formData.current_page = currentPage;
    }

    const payload = formToFhir(_formData, questionnaire, patientId);
    const isComplete = isLastPage && isLastPanel;
    payload.status = isComplete ? 'completed' : 'in-progress';
    payload.authored = isComplete ? now() : undefined;

    if (questionnaireResponse && questionnaireResponse.id) {
      return updateQuestionnaireResponse(practiceId, patientId, payload)
        .then(submitSuccess)
        .catch(submitFail)
        .finally(submitFinal);
    }
    return createQuestionnaireResponse(practiceId, patientId, payload)
      .then(submitSuccess)
      .catch(submitFail)
      .finally(submitFinal);
  };

  if (errors && errors.length !== 0) {
    return (
      <>
        {
          errors && errors.map(err => (
            <Callout
              type="error"
              title={err}
              key={err}
              icon={<Warning />}
            />
          ))
        }
      </>
    );
  }

  if (loading || !formItems || !questionnaireResponse || !initialValues) {
    return <Loader />;
  }

  return (
    <form>
      {(currentPage === 0) &&
        <div className={styles.formTitleBar}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h6" className={styles.formTitle}>{practiceTitle}</Typography>
            </Grid>
            <Grid item>
              { !preferencesLoaded && <CircularProgress /> }
              { (preferencesLoaded && practiceLogoURL) && (
                <img src={practiceLogoURL} alt={practiceName} />
              )}
            </Grid>
          </Grid>
        </div>
      }
      <div className={styles.formContainer}>
        <PatientDemographics
          submitButtonState={submitButtonState}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      </div>
    </form>
  );
};

FormLoader.propTypes = {
  practiceName: PropTypes.string,
  practiceTitle: PropTypes.string,
  practiceLogoURL: PropTypes.string,
  preferencesLoaded: PropTypes.bool.isRequired,
  practicePreferences: PropTypes.instanceOf(Object),
  practiceId: PropTypes.string,
  patientId: PropTypes.string.isRequired,
  questionnaireResponse: PropTypes.instanceOf(Object).isRequired,
  patient: PropTypes.instanceOf(Object).isRequired,
  currentPage: PropTypes.number.isRequired,
  currentPanel: PropTypes.number.isRequired,
  setPageCount: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPanel: PropTypes.func.isRequired,
  setflattenFormItemsCursor: PropTypes.func.isRequired,
  setFormItems: PropTypes.func.isRequired,
  destroy: PropTypes.func,
  formItems: PropTypes.instanceOf(Object),
};

FormLoader.defaultProps = {
  destroy: undefined,
  practiceName: undefined,
  practiceTitle: undefined,
  practiceLogoURL: undefined,
  practiceId: undefined,
  practicePreferences: {},
  formItems: [],
};

export default FormLoader;
