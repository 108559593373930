import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  layout, Typography,
} from 'motif';
import classNames from 'classnames';
import Register from '../Auth/Register';
import OAuth2Callback from '../Auth/OAuth2Callback';
import ResponseLoader from '../form/common/ResponseLoader';
import PrivacyTerms from '../Static/privacy';
import Fee from '../Static/fee';
import Telehealth from '../Static/telehealth';
import styles from './styles.module.scss';
import ProtectedRoute from './ProtectedRoute';
import ProppedRoute from './ProppedRoute';
import { useAppContext } from './context';

export const Routes = () => {
  const { isLoggedIn } = useAppContext();

  return (
    <Switch>
      <Route exact path="/" render={() => (<Redirect to="/form" />)} />
      <Route exact path="/form/demographics" render={() => (<Redirect to="/form" />)} />
      <ProppedRoute
        exact
        path="/terms/:versionNumber/privacy"
        render={({ match }) => (
          <div className={classNames(layout.flex, layout.centerContent)}>
            <div className={styles.smallFormContainer}>
              <Typography variant="h5" gutterBottom>Privacy Consent</Typography>
              <PrivacyTerms versionNumber={match.params.versionNumber} preview={false} />
            </div>
          </div>
        )}
      />
      <ProppedRoute
        exact
        path="/terms/:versionNumber/fees"
        render={({ match }) => (
          <div className={classNames(layout.flex, layout.centerContent)}>
            <div className={styles.smallFormContainer}>
              <Typography variant="h5" gutterBottom>Financial Consent</Typography>
              <Fee versionNumber={match.params.versionNumber} preview={false} />
            </div>
          </div>
        )}
      />
      <ProppedRoute
        exact
        path="/terms/:versionNumber/telehealth"
        render={({ match }) => (
          <div className={classNames(layout.flex, layout.centerContent)}>
            <div className={styles.smallFormContainer}>
              <Typography variant="h5" gutterBottom>Patient Agreement: Telehealth Consultation</Typography>
              <Telehealth versionNumber={match.params.versionNumber} preview={false} />
            </div>
          </div>
        )}
      />
      <ProppedRoute
        exact
        path="/register/:token"
        render={(routeProps) => {
          if (isLoggedIn) {
            return <Redirect to="/" />;
          }
          return <Register {...routeProps} />;
        }}
      />
      <ProppedRoute exact path="/callback" render={OAuth2Callback} />
      <ProtectedRoute exact path="/form" render={ResponseLoader} />
      <ProppedRoute render={() => 'Page Not Found'} />
    </Switch>
  );
};
