import { API } from '../api';
import config from '../config';

const patientPortalApi = new API(config.PATIENT_PORTAL_API_URL_WITHOUT_PATH);

export const getQuestionnaireResponses = (practiceId, patientId) => patientPortalApi.get(`/api/v1/QuestionnaireResponses/tenantId/${practiceId}/patientId/${patientId}?withDeleted=true`);

export const createQuestionnaireResponse = (practiceId, patientId, data) => patientPortalApi.post(`/api/v1/QuestionnaireResponses/tenantId/${practiceId}/patientId/${patientId}`, data);

export const updateQuestionnaireResponse = (practiceId, patientId, data) => patientPortalApi.post(`/api/v1/QuestionnaireResponses/tenantId/${practiceId}/patientId/${patientId}`, data);
