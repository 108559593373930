import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import Hidden from '@material-ui/core/Hidden';
import { FormGrid, Typography } from 'motif';
import { FORMATS } from '../../../../Helpers/constants';
import { validateFormValue } from '../../../../Validator/index';
import { renderYesNoSwitch, renderInput } from '../../../../Helpers/reduxFormMotif';
import { medicareCardExpiryDateRule, medicareCardNumberRule, medicareCardReferenceNumberRule } from '../../../../Validator/rules';
import InputHint from '../../../common/InputHint';

const validMedicareCardNumber = value => validateFormValue(value, 'medicare_card_number', medicareCardNumberRule);
const validMedicareCardReferenceNumber = value => validateFormValue(value, 'medicare_card_reference_number', medicareCardReferenceNumberRule);
const validExpiryDate = value => validateFormValue(value, 'medicare_card_expiry_date', medicareCardExpiryDateRule);

const MedicareCard = ({ formData, change }) => {
  if (!formData.medicare_card) return null;
  const hasMedicareCard = formData.medicare_card.has_medicare_card;

  const handleHasMedicareCardChange = (changeState) => {
    if (changeState) return;
    // void the medicare card info if the user doesn't have a card
    Object.keys(formData.medicare_card).forEach(key => change(`medicare_card.${key}`, null));
  };

  return (
    <FormSection name="medicare_card">
      <FormGrid container>
        <FormGrid item xs={12}>
          <Typography variant="subtitle1">
            Do you have a medicare card?
          </Typography>
        </FormGrid>
        <FormGrid item xs={12}>
          <Field
            component={renderYesNoSwitch}
            name="has_medicare_card"
            onChange={handleHasMedicareCardChange}
          />
        </FormGrid>
        { hasMedicareCard &&
          <Fragment>
            <FormGrid item xs={12}>
              <Typography>
                Medicare
              </Typography>
            </FormGrid>
            <FormGrid item md={5} sm={6} xs={12}>
              <Field
                name="card_number"
                component={renderInput}
                label="Medicare number"
                required
                mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/]}
                discardMask
                noMargin
                validate={[validMedicareCardNumber]}
                endAdornment={(
                  <InputHint
                    title="Medicare Number"
                    content="You should be able to find this number on the front of your card."
                    imageSrc="/img/medicare_number.png"
                  />
                )}
              />
            </FormGrid>
            <FormGrid item md={3} sm={5} xs={12}>
              <Field
                name="reference_number"
                component={renderInput}
                label="Reference number"
                required
                mask={[/^[1-9]$/]}
                discardMask
                noMargin
                validate={[validMedicareCardReferenceNumber]}
                endAdornment={(
                  <InputHint
                    title="Reference Number"
                    content="You should be able to find this number next to your name."
                    imageSrc="/img/medicare_ref.png"
                  />
                )}
              />
            </FormGrid>
            <FormGrid item sm={4} xs={12}>
              <Field
                name="expiry_date"
                component={renderInput}
                label="Expiry date"
                required
                mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                placeholder={FORMATS.MONTH_YEAR}
                noMargin
                validate={[validExpiryDate]}
              />
            </FormGrid>
          </Fragment>
        }
        <Hidden only="xs"><FormGrid item sm={6} /></Hidden>
      </FormGrid>
    </FormSection>
  );
};
MedicareCard.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func,
};
MedicareCard.defaultProps = {
  formData: undefined,
  change: undefined,
};

export default MedicareCard;
