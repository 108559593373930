/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import { FormGrid } from 'motif';
import CircularProgress from '@material-ui/core/CircularProgress';
import { renderCheckBox, renderInput, renderSelect, renderTypeableSelect } from '../../../../Helpers/reduxFormMotif';
import { validateFormValue } from '../../../../Validator';
import { privateHealthInsuranceNumberRule, privateHealthInsuranceSelectRule, minLength, required } from '../../../../Validator/rules';
import { getAllHealthFunds } from '../../../../../actions/organization';

const PrivateHealthInsurance = ({ formData, change }) => {
  const [loading, setLoading] = useState(false);
  const [insuranceOrganizations, setInsuranceOrganizations] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);

    getAllHealthFunds()
      .then((organizations) => {
        setLoading(false);
        if (!isSubscribed) return null;

        if (organizations.length === 0) {
          return;
        }

        const filteredOrganizations = organizations.reduce((allOrganizations, organization) => {
          const { name } = organization;

          allOrganizations.push({
            name,
            value: name,
          });

          return allOrganizations;
        }, []);

        filteredOrganizations.sort((org1, org2) => {
          if (org1.name < org2.name) return -1;
          if (org1.name > org2.name) return 1;
          return 0;
        });

        filteredOrganizations.push({
          name: 'Other',
          value: 'Other',
        });

        setInsuranceOrganizations(filteredOrganizations);
      }).catch(() => {
        setLoading(false);
        if (!isSubscribed) return null;
      });

    return () => (isSubscribed = false);
  }, []);

  if (!formData.private_health) return null;
  const hasPrivateHealthInsurance = formData.private_health.has_private_health;

  const validFundNameSelected = value => validateFormValue(value, 'private_health.fund_name', privateHealthInsuranceSelectRule(insuranceOrganizations));
  const validMemberNumber = value => validateFormValue(value, 'private_health.membership_number', privateHealthInsuranceNumberRule);

  const handleIsPrivateHealthInsurancePatientChange = () => {
    if (!change) return;
    Object.keys(formData.private_health).forEach(key => change(`private_health.${key}`, null));
  };
  const handleOtherChange = () => {
    if (!change) return;
    change('private_health.other_fund_name', null);
  };

  return (
    <FormSection name="private_health">
      <Field
        label="Private Health"
        component={renderCheckBox}
        name="has_private_health"
        onChange={handleIsPrivateHealthInsurancePatientChange}
      />
      { hasPrivateHealthInsurance &&
        <FormGrid container>
          <FormGrid item md={5} sm={6} xs={12}>
            <Field
              component={renderTypeableSelect}
              name="fund_name"
              items={insuranceOrganizations}
              fullWidth
              filterItems
              label="Health Fund"
              required
              hideHelperText={false}
              noMargin
              validate={[validFundNameSelected]}
              disabled={loading}
              onChange={handleOtherChange}
              startAdornment={loading &&
                <CircularProgress size={28} />
              }
            />
          </FormGrid>
          {
             ['Other', 'Unknown'].includes(formData.private_health.fund_name) &&
             <FormGrid item md={5} sm={6} xs={12}>
               <Field
                 component={renderInput}
                 name="other_fund_name"
                 label="Other Health Fund"
                 required
                 hideHelperText={false}
                 noMargin
                 validate={[required, minLength(3)]}
               />
             </FormGrid>
          }
          <FormGrid item md={3} sm={5} xs={12}>
            <Field
              component={renderSelect}
              name="level_of_cover"
              label="Level of cover"
              data={[
                { name: 'GOLD+', value: 'GOLD+' },
                { name: 'GOLD', value: 'GOLD' },
                { name: 'SILVER+', value: 'SILVER+' },
                { name: 'SILVER', value: 'SILVER' },
                { name: 'BRONZE+', value: 'BRONZE+' },
                { name: 'BRONZE', value: 'BRONZE' },
                { name: 'BASIC+', value: 'BASIC+' },
                { name: 'BASIC', value: 'BASIC' },
              ]}
              hideEmpty
              noMargin
            />
          </FormGrid>
          <FormGrid item md={4} sm={6} xs={12}>
            <Field
              name="membership_number"
              component={renderInput}
              label="Membership number"
              required
              noMargin
              validate={[validMemberNumber]}
            />
          </FormGrid>
          <FormGrid item md={3} sm={5} xs={12}>
            <Field
              name="reference_number"
              component={renderInput}
              label="Reference number"
              mask={[/^[0-9]$/]}
              discardMask
              noMargin
            />
          </FormGrid>
        </FormGrid>
      }
    </FormSection>
  );
};
PrivateHealthInsurance.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func,
};
PrivateHealthInsurance.defaultProps = {
  formData: undefined,
  change: undefined,
};

export default PrivateHealthInsurance;
