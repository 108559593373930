import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  PrimaryButton,
  Typography,
  FormGrid,
  Colored,
  Hyperlink,
  layout,
} from 'motif';
import { Grid } from '@material-ui/core';
import styles from './styles.module.scss';
import { getLoginUrl } from './oauth2';
import { decodeHash } from './helpers';

const BackToLogin = ({
  registeredEmail,
  setWindowLocation,
  slug,
  match,
}) => {
  const { token } = match.params;
  const {
    practiceId, patientId,
  } = decodeHash(token);

  const handleClickNext = () => {
    setWindowLocation(getLoginUrl({
      state: {
        slug,
        practiceId,
        patientId,
      },
    }));
  };
  const handleClickForgotPassword = () => { setWindowLocation(getLoginUrl({ state: { slug }, endpoint: 'forgotPassword' })); };
  return (
    <div className={classNames(styles.smallFormContainer, styles.signUpContainer)}>
      <div className={layout.paddingLeft}>
        <FormGrid item container xs={12} spacingV={8}>
          <FormGrid item xs={12}>
            <Typography variant="h5" gutterBottom>Login</Typography>
          </FormGrid>
          <FormGrid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>Welcome to Secure Health Forms</Typography>
          </FormGrid>
          <FormGrid item xs={12}>
            <Typography>
              Patient is registered with email
              <br />
              <Colored color="secondary">{registeredEmail}</Colored>
            </Typography>
          </FormGrid>
          <FormGrid item xs={12} className={layout.paddingTop}>
            <Grid container spacing={0} justify="space-between">
              <Grid item>
                <Hyperlink href="#" onClick={handleClickForgotPassword} >Forgot your password?</Hyperlink>
              </Grid>
              <Grid item>
                <PrimaryButton label="Next" onClick={handleClickNext} />
              </Grid>
            </Grid>
          </FormGrid>
        </FormGrid>
      </div>
    </div>
  );
};
BackToLogin.propTypes = {
  registeredEmail: PropTypes.string.isRequired,
  setWindowLocation: PropTypes.func,
  slug: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
BackToLogin.defaultProps = {
  setWindowLocation: (url) => { window.location = url; },
};
export default BackToLogin;
