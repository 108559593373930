import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGrid,
} from 'motif';
import { Field, FormSection } from 'redux-form';
import { renderInput, renderCheckBox, renderSelect } from '../../../../Helpers/reduxFormMotif';
import { toUpperCase } from '../../../../Helpers/strings';
import { validateFormValue } from '../../../../Validator';
import { dvaCardNumberRule, dvaCardDisabilityRule } from '../../../../Validator/rules';

const validCardNumber = value => validateFormValue(value, 'dva_card.card_number', dvaCardNumberRule);
const validDisability = value => validateFormValue(value, 'dva_card.disability', dvaCardDisabilityRule);


const DvaCard = ({ formData, change }) => {
  const resetDVACard = () => {
    if (change) {
      change('dva_card.card_number', null);
      change('dva_card.card_colour', null);
      change('dva_card.disability', null);
    }
  };

  const resetDisability = (current) => {
    if (current !== 'White') {
      change('dva_card.disability', null);
    }
  };

  return (
    <FormSection name="dva_card">
      <Field
        label="DVA"
        component={renderCheckBox}
        name="has_dva_card"
        onChange={resetDVACard}
      />
      {(formData.dva_card && formData.dva_card.has_dva_card) &&
        <FormGrid container>
          <FormGrid item md={4} sm={7} xs={12}>
            <Field
              component={renderInput}
              name="card_number"
              label="Card number"
              normalize={toUpperCase}
              // DVA card format see http://meteor.aihw.gov.au/content/index.phtml/itemId/339127
              mask={[/[NVQWST]/i, ...Array.from({ length: 8 }, () => /[A-Za-z0-9\s]/)]}
              discardMask
              validate={[validCardNumber]}
              required
            />
          </FormGrid>
          <FormGrid item md={3} sm={5} xs={12}>
            <Field
              component={renderSelect}
              name="card_colour"
              label="Card Colour"
              data={[
                { name: 'White', value: 'White' },
                { name: 'Gold', value: 'Gold' },
                { name: 'Orange', value: 'Orange' },
              ]}
              onChange={resetDisability}
              hideEmpty
            />
          </FormGrid>
          {(formData.dva_card && formData.dva_card.card_colour === 'White') &&
            <FormGrid item md={5} sm={7} xs={12}>
              <Field
                component={renderInput}
                name="disability"
                label="Disability"
                validate={[validDisability]}
                required
              />
            </FormGrid>
          }
        </FormGrid>
      }
    </FormSection>
  );
};

DvaCard.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func,
};

DvaCard.defaultProps = {
  formData: {},
  change: undefined,
};

export default DvaCard;
