export const FORMATS = {
  DATE: 'DD/MM/YYYY',
  DATETIME: 'DD/MM/YYYY HH:mm',
  DATE_TIME: 'DD/MM/YYYY HH:mm',
  ISO_DATE: 'YYYY-MM-DD',
  ISO_8601: 'YYYY-MM-DDTHH:mm:ssZ',
  ISO_NO_TIMEZONE: 'YYYY-MM-DDTHH:mm:ss',
  TIME: 'HH:mm',
  TIME_12: 'h:mma',
  PARSE_DATE: 'D/M/YYYY',
  MONTH_YEAR: 'MM/YYYY',
  MONTH_YEAR_SHORT: 'MM/YY',
  MONTH_NAME_YEAR: 'MMMM YYYY',
  YEAR_MONTH: 'YYYY MM',
  DATE_AT_TIME: 'DD/MM/YYYY [at] h:mma',
  DATE_IN_WORDS: 'dddd, D MMMM YYYY',
  DATE_IN_WORDS_SHORT: 'D MMMM YYYY',
  DATE_IN_SHORT: 'DD/MM/YY',
};

export const MAX_FORM_PANELS = 99;

export const PAGE_LINKID = {
  medicalPage: '25',
};

export const ROOT_SCHEMA = {
  item: [
    {
      type: 'group',
      linkId: 'personal_details',
      text: 'Personal Details',
      item: [
        {
          type: 'group',
          linkId: 'contact_details',
          text: 'Contact Details',
        },
        {
          type: 'group',
          linkId: 'residential_address',
          text: 'Residential Address',
        },
        {
          type: 'group',
          linkId: 'emergency_contact',
          text: 'Emergency Contact',
        },
      ],
    },
    {
      type: 'group',
      linkId: 'insurance_billing_page',
      text: 'Insurance Billing Page',
      item: [
        {
          type: 'group',
          linkId: 'usual_gp',
          text: 'Usual GP',
        },
        {
          type: 'group',
          linkId: 'insurance_billing',
          text: 'Insurance & Billing',
        },
      ],
    },
  ],
};
