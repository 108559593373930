import React from 'react';
import { Field, FormSection } from 'redux-form';
import { FormGrid, Typography } from 'motif';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  renderInput,
  renderCheckBox,
} from '../../../../Helpers/reduxFormMotif';
import { usePracticeContext } from '../../../../App/practiceContext';
import { getLatestFinancialConsentVersionNumber } from '../../../../../models/practicePreferencesModel';
import {
  firstNameRule,
  lastNameRule,
  required,
  requiredWithMessage,
} from '../../../../Validator/rules';
import { validateFormValue } from '../../../../Validator/index';
import FeeTerms from '../../../../Static/fee';

const validFirstName = value => validateFormValue(value, 'given_name', firstNameRule);
const validLastName = value => validateFormValue(value, 'family_name', lastNameRule);

const Fee = (props) => {
  const { publicProfile } = usePracticeContext();
  const setDateTimeAndVersion = (hasAgreed) => {
    if (!props.formData.fee) {
      // eslint-disable-next-line no-param-reassign
      props.formData.fee = {};
    }
    // eslint-disable-next-line no-param-reassign
    props.formData.fee.date = hasAgreed ? moment().format() : '';
    // eslint-disable-next-line no-param-reassign
    props.formData.fee.agreed_version = getLatestFinancialConsentVersionNumber(publicProfile);
  };

  return (
    <FormSection name="fee">
      <FormGrid container spacingV={8}>
        <FormGrid item xs={12}>
          <FeeTerms preview />
        </FormGrid>
        <FormGrid item xs={12}>
          <Typography component="span">
            Patient/Authorised Person
          </Typography>
        </FormGrid>
        <FormGrid item xs={12} sm={6}>
          <Field
            name="first_name"
            component={renderInput}
            label="First name"
            required
            validate={[validFirstName, required]}
            autoComplete="given-name"
          />
        </FormGrid>
        <FormGrid item xs={12} sm={6}>
          <Field
            name="family_name"
            component={renderInput}
            label="Last name"
            required
            validate={[validLastName]}
            autoComplete="family-name"
          />
        </FormGrid>
        <FormGrid item xs={12} sm={6}>
          <Field
            label="I have read and agree to the above*"
            component={renderCheckBox}
            name="has_agreed"
            validate={[requiredWithMessage('You must agree to continue')]}
            onChange={setDateTimeAndVersion}
          />
        </FormGrid>
      </FormGrid>
    </FormSection>

  );
};

Fee.propTypes = {
  formData: PropTypes.instanceOf(Object),
};

Fee.defaultProps = {
  formData: {},
};

export default Fee;
