import moment from 'moment';
import { FORMATS } from './constants';

export const patientFirstName = (patient) => {
  if (!patient || !patient.name || !patient.name.length) return '';
  if (!patient.name[0].given || !patient.name[0].given.length) return '';
  return patient.name[0].given[0];
};

export const patientMiddleName = (patient) => {
  if (!patient || !patient.name || !patient.name.length) return '';
  if (!patient.name[0].given || !patient.name[0].given.length) return '';
  return patient.name[0].given[1];
};

const checkPatientNameUse = (patient, use) => {
  if (!patient || !patient.name || !patient.name.length) return '';
  let result = '';
  patient.name.forEach((nameObj) => {
    if (nameObj.use === use) {
      if (use === 'maiden') {
        result = nameObj.family || '';
      } else {
        result = (nameObj.given && nameObj.given[0]) || '';
      }
    }
  });
  return result;
};

export const patientPreferredName = patient => checkPatientNameUse(patient, 'nickname');

export const patientMaidenName = patient => checkPatientNameUse(patient, 'maiden');

export const patientFamilyName = (patient) => {
  if (!patient || !patient.name || !patient.name.length) return '';
  return patient.name[0].family;
};

export const patientTitle = (patient) => {
  if (!patient || !patient.name || !patient.name.length) return '';
  if (!patient.name[0].prefix || !patient.name[0].prefix.length) return '';
  return patient.name[0].prefix[0];
};

export const patientBirthDate = (patient) => {
  if (!patient.birthDate) return '';
  return moment(patient.birthDate, FORMATS.ISO_8601).format(FORMATS.DATE);
};

export const patientGender = (patient) => {
  if (!patient || !patient.gender) return '';
  return patient.gender;
};

export const patientPhoneNumber = (patient) => {
  if (!patient.telecom || !patient.telecom.length) return '';
  const mobilePhoneNumber = patient.telecom.find(t => t.system === 'phone' && t.use === 'mobile');
  if (!mobilePhoneNumber) return '';
  return mobilePhoneNumber.value;
};
