import { API } from '../api';
import { SYSTEM_IDENTIFIER_AU_VENDORID } from '../components/Helpers/namespaces';
import config from '../config';

const patientPortalApi = new API(config.PATIENT_PORTAL_API_URL_WITHOUT_PATH);

export const getAllHealthFunds = () => {
  const filter = {
    where: {
      'type.coding.code': 'ins',
      'identifier.use': 'usual',
      'identifier.system': SYSTEM_IDENTIFIER_AU_VENDORID,
      active: true,
    },
  };

  return patientPortalApi.get('/api/v1/Organizations', { filter });
};
