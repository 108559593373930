import React, { Component } from 'react';
import { Input } from 'motif';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FORMATS } from '../../Helpers/constants';

const parseDate = (value, format) => {
  if (!value) return [null, ''];
  const momentValue = moment(value, [FORMATS.ISO_DATE, FORMATS.DATE], true);
  if (momentValue.isValid()) return momentValue.format(format);

  return value;
};

class DateMask extends Component {
  constructor(props) {
    super(props);
    const formattedValue = parseDate(props.value, props.dateFormat);
    this.state = {
      formattedValue,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const formattedValue = parseDate(props.value, props.dateFormat);

    return (formattedValue !== state.textValue) ? { formattedValue } : null;
  }

  render() {
    const { dateFormat, ...rest } = this.props;
    const { formattedValue } = this.state;
    return (
      <Input
        placeholder={FORMATS.DATE}
        {...rest}
        value={formattedValue}
        format={dateFormat}
      />
    );
  }
}
/* eslint-disable react/forbid-prop-types */
DateMask.propTypes = {
  id: PropTypes.string,
  /** Called when the user selects a date, or types in the input. */
  onChange: PropTypes.func,
  /** Called when the input loses focus */
  onBlur: PropTypes.func,
  /** The name of the date picker field, will be used in change event */
  name: PropTypes.string,
  /** The currently selected date as a {moment} object. */
  value: PropTypes.any,
  /** adornments placed at the end of the input (e.g. Age in DateOfBirth) */
  endAdornment: PropTypes.node,
  /** remove margins applied to the select */
  noMargin: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  /** Autofocus on the input on first render? (note: this will not open the popup) */
  autoFocus: PropTypes.bool,
  readOnly: PropTypes.bool,
  dateFormat: PropTypes.string,
};
/* eslint-enable react/forbid-prop-types */

DateMask.defaultProps = {
  id: undefined,
  onChange: undefined,
  onBlur: undefined,
  name: undefined,
  value: '',
  endAdornment: undefined,
  noMargin: false,
  disabled: false,
  helperText: '',
  error: false,
  warning: false,
  autoFocus: false,
  readOnly: false,
  dateFormat: '',
};

export default DateMask;
