import React from 'react';
import { Info } from '@material-ui/icons';
import { Typography, Callout } from 'motif';
import { emitter } from './errorEmitter';

import styles from './styles.module.scss';

class Error extends React.Component {
  constructor(props) {
    super(props);
    ({ children: this.children } = props);
    this.ref = React.createRef();
  }

  state = {
    children: null,
  }

  componentDidMount() {
    emitter.on('calloutError/error', (errors) => {
      this.setState({ children: errors });
    });

    emitter.on('calloutError/clear', () => {
      this.setState({ children: null });
    });
    this.ref.current.scrollIntoView();
  }

  componentDidUpdate() {
    this.ref.current.scrollIntoView();
  }

  componentWillUnmount() {
    emitter.removeListener('calloutError/error');
    emitter.removeListener('calloutError/clear');
  }

  render() {
    const { children } = this.state;

    return (
      <div data-cy="calloutError" className={styles.errorContainer} ref={this.ref}>
        {children &&
        <div className={styles.errorSelector}>
          <Callout type="error" title="Error" icon={<Info />}>
            <Typography>{children}</Typography>
          </Callout>
        </div>
      }
      </div>
    );
  }
}

export default Error;
