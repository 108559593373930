import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Hyperlink } from 'motif';
import dompurify from 'dompurify';

const RenderExternalHTML = ({ content, style }) => (
  // eslint-disable-next-line react/no-danger
  <div className={style} dangerouslySetInnerHTML={{ __html: dompurify.sanitize(content) }} />
);
RenderExternalHTML.propTypes = {
  content: PropTypes.string,
  style: PropTypes.string,
};
RenderExternalHTML.defaultProps = {
  content: undefined,
  style: undefined,
};

const ShowLink = ({ preview, setPreview }) => {
  const message = `Show ${preview ? 'More' : 'Less'}`;
  return <Hyperlink onClick={() => { setPreview(!preview); }}> {message} </Hyperlink>;
};
ShowLink.propTypes = {
  preview: PropTypes.bool,
  setPreview: PropTypes.func.isRequired,
};
ShowLink.defaultProps = {
  preview: undefined,
};

const RenderPreviewText = ({
  preview: hasPreview, shortText, mainText, style,
}) => {
  const [preview, setPreview] = useState(hasPreview);

  if (!shortText && !mainText) {
    return (
      <LinearProgress variant="query" />
    );
  }

  return (
    <React.Fragment>
      <RenderExternalHTML content={shortText} style={style} />
      {!preview &&
        <RenderExternalHTML content={mainText} style={style} />
      }
      {hasPreview &&
        <ShowLink preview={preview} setPreview={setPreview} />
      }
    </React.Fragment>
  );
};
RenderPreviewText.propTypes = {
  preview: PropTypes.bool,
  shortText: PropTypes.string,
  mainText: PropTypes.string,
  style: PropTypes.string,
};
RenderPreviewText.defaultProps = {
  preview: undefined,
  shortText: undefined,
  mainText: undefined,
  style: undefined,
};

export {
  RenderPreviewText,
};
