import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AppBar from '../Layout/AppBar';

const ProppedRoute = ({ render: Component, ...rest }) => (
  <Route
    {...rest}
    render={renderProps => (
      <AppBar {...renderProps}>
        <Component {...renderProps} />
      </AppBar>
      )}
  />
);
ProppedRoute.propTypes = {
  render: PropTypes.func.isRequired,
};

export default ProppedRoute;
