export default {
  DEV_AS_ENVIRONMENT: process.env.REACT_APP_DEV_AS_ENVIRONMENT, // dev only
  PATIENT_PORTAL_UI_DOMAIN: process.env.REACT_APP_PATIENT_PORTAL_UI_DOMAIN,
  PATIENT_PORTAL_UI_SCHEME: process.env.REACT_APP_PATIENT_PORTAL_UI_DOMAIN_SCHEME || 'https',
  PATIENT_PORTAL_API_URL_WITHOUT_PATH: process.env.REACT_APP_PATIENT_PORTAL_API_URL_WITHOUT_PATH,
  PLATFORM_API_URL: process.env.REACT_APP_PLATFORM_API_URL,
  PRACTICE_PREFERENCES_API_URL_WITHOUT_PATH: process.env.REACT_APP_PRACTICE_PREFERENCES_API_URL_WITHOUT_PATH || '',
  PRACTICE_PREFERENCES_BASE_PATH: process.env.REACT_APP_PRACTICE_PREFERENCES_BASE_PATH,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  Environment: process.env.REACT_APP_ENVIRONMENT,
  PATIENT_PORTAL_UI_SENTRY_RELEASE_VERSION: process.env.REACT_APP_PATIENT_PORTAL_UI_SENTRY_RELEASE_VERSION,
  // base url or the oauth2 server
  COGNITO_USER_POOL_URL: process.env.REACT_APP_COGNITO_USER_POOL_URL,
  // url for getting token
  AUTH_ROLE_INTEGRATION_OAUTH2_TOKENS_URL: process.env.REACT_APP_AUTH_ROLE_INTEGRATION_OAUTH2_TOKENS_URL,
  // oauth2 param - COGNITO_USER_POOL_PATIENT_PORTAL_OAUTH2_CLIENT_ID
  COGNITO_USER_POOL_PATIENT_PORTAL_OAUTH2_CLIENT_ID: process.env.REACT_APP_COGNITO_USER_POOL_PATIENT_PORTAL_OAUTH2_CLIENT_ID,
  // oauth2 param - AUTH_ROLE_INTEGRATION_OAUTH2_REDIRECT_URL
  AUTH_ROLE_INTEGRATION_OAUTH2_REDIRECT_URL: process.env.REACT_APP_AUTH_ROLE_INTEGRATION_OAUTH2_REDIRECT_URL,
  // oauth2 param - PATIENT_PORTAL_UI_OAUTH2_LOGOUT_URL
  PATIENT_PORTAL_UI_OAUTH2_LOGOUT_URL: process.env.REACT_APP_PATIENT_PORTAL_UI_OAUTH2_LOGOUT_URL,
  DYNATRACE_DEBUG_LOGGING: process.env.REACT_APP_DYNATRACE_DEBUG_LOGGING,
};
