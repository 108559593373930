import React from 'react';
import PropTypes from 'prop-types';
import { FormGrid, Input, IconButton } from 'motif';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { inputStyleOverrides } from '../../../../Helpers/input';
import styles from './styles.module.scss';

export const AttachmentListItem = ({
  attachment,
  listRemove,
  index,
}) => {
  const removeFromListIcon = () => (
    <div className={styles.cancelAddition}>
      <IconButton
        id={`attachmentDelete${index}`}
        onClick={listRemove}
      >
        <Cancel />
      </IconButton>
    </div>
  );

  const alreadyUploadedIcon = () => (
    <div className={styles.checkCircle}>
      <CheckCircle color="primary" />
    </div>
  );

  return (
    <React.Fragment>
      <FormGrid item sm={12}>
        <Input
          id={`attachment${index}`}
          value={attachment.name}
          disabled
          label={`${attachment.path}`}
          {...inputStyleOverrides}
          endAdornment={attachment.uploaded ? alreadyUploadedIcon() : removeFromListIcon()}
        />
      </FormGrid>
    </React.Fragment>

  );
};

AttachmentListItem.propTypes = {
  attachment: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
    uploaded: PropTypes.bool,
  }).isRequired,
  listRemove: PropTypes.func.isRequired,
  index: PropTypes.number,
};

AttachmentListItem.defaultProps = {
  index: undefined,
};
