import { SET_PRACTICE_PREFERENCES, SET_PRACTICE_PREFERENCES_ERROR } from '../actions/practice-preferences';

const INITIAL_STATE = {};

const practicePreferences = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRACTICE_PREFERENCES:
      return {
        ...state,
        ...action.preferences,
      };
    case SET_PRACTICE_PREFERENCES_ERROR:
      return {
        ...state,
        loadingFailure: true,
        error: action.error,
      };
    default:
      return state;
  }
};

export default practicePreferences;
