import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { decodeHash } from './helpers';
import { getPatientRegistered } from '../../actions/patient';
import { usePracticeContext } from '../App/practiceContext';
import Loader from '../Loader';
import BackToLogin from './BackToLogin';
import SignUpForm from './SignUpForm';
import * as Analytics from '../../actions/analytics';
import { invalidRegistrationLink } from './ContactPractice';
import { Error } from '../Errors';

const Register = ({
  location, match,
}) => {
  const { publicProfile } = usePracticeContext();
  const [registeredData, setRegisteredData] = useState(null);
  const { registered, registeredEmail } = registeredData || {};

  useEffect(() => {
    const { patientId, valid: hashValid } = decodeHash(match.params.token);
    Analytics.trackEvent(Analytics.ANALYTICS_FEATURES.REGISTER, 'Sms Link Clicked');
    if (!hashValid) {
      invalidRegistrationLink();
      return;
    }
    getPatientRegistered(patientId).then((data) => {
      setRegisteredData(data);
    }).catch((err) => {
      Error.show(`Failed to check patient registration status. Error: ${err}`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!registeredData || !publicProfile) {
    return <Loader />;
  }

  if (registered) {
    return <BackToLogin registeredEmail={registeredEmail} match={match} slug={publicProfile.slug} />;
  }
  return <SignUpForm location={location} match={match} publicProfile={publicProfile} />;
};
Register.propTypes = {
  /** location object to allow access to url query string */
  location: PropTypes.shape({
    // code and state parameters are expected in search, state is expected to be a Base64 en
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Register;
