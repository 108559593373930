import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field, FormSection } from 'redux-form';
import { FormGrid, Typography } from 'motif';
import Hidden from '@material-ui/core/Hidden';
import { renderYesNoSwitch, renderInput, renderCheckBox } from '../../../../Helpers/reduxFormMotif';
import SectionAddress from '../ResidentialAddressForm/sectionAddress';
import { firstNameRule, lastNameRule, phoneNumberRule, required, requiredWithMessage } from '../../../../Validator/rules';
import { validateFormValue } from '../../../../Validator/index';
import TelehealthTerms from '../../../../Static/telehealth';
import styles from './styles.module.scss';
import { usePracticeContext } from '../../../../App/practiceContext';
import { getLatestTelehealthConsentVersionNumber } from '../../../../../models/practicePreferencesModel';

const validPhoneNumber = value => validateFormValue(value, 'phone_number', phoneNumberRule);
const validFaxNumber = (value) => {
  if (!value) return null;
  return validateFormValue(value, 'phone_number', phoneNumberRule);
};
const validFirstName = value => validateFormValue(value, 'given_name', firstNameRule);
const validLastName = value => validateFormValue(value, 'family_name', lastNameRule);

const Teleheath = ({ formData, change }) => {
  const { publicProfile } = usePracticeContext();
  if (!formData.telehealth) return null;
  const isTelehealthPatient = formData.telehealth.is_telehealth_patient;
  const handleIsTelehealthPatientChange = (changeState) => {
    if (changeState) return;
    Object.keys(formData.telehealth).forEach(key => change(`telehealth.${key}`, null));
  };

  const setDateTimeAndVersion = (hasAgreed) => {
    if (!formData.telehealth) {
      // eslint-disable-next-line no-param-reassign
      formData.telehealth = {};
    }
    // eslint-disable-next-line no-param-reassign
    formData.telehealth.date = hasAgreed ? moment().format() : '';
    // eslint-disable-next-line no-param-reassign
    formData.telehealth.agreed_version = getLatestTelehealthConsentVersionNumber(publicProfile);
  };

  const handleSelectSuburb = ({ locality, state, postcode } = {}) => {
    change('telehealth.pharmacy_address.suburb', locality);
    change('telehealth.pharmacy_address.state', state);
    change('telehealth.pharmacy_address.postcode', postcode);
  };

  return (
    <FormSection name="telehealth">
      <FormGrid container spacingV={8}>
        <FormGrid item xs={12}>
          <Typography>
            Are you a telehealth patient?
          </Typography>
        </FormGrid>
        <FormGrid item xs={12}>
          <Field
            component={renderYesNoSwitch}
            name="is_telehealth_patient"
            onChange={handleIsTelehealthPatientChange}
          />
        </FormGrid>
        { isTelehealthPatient &&
          <Fragment>
            <FormGrid item xs={12} sm={6}>
              <Field
                name="skype_address"
                component={renderInput}
                label="Skype Address"
                validate={[required]}
                required
              />
            </FormGrid>
            <Hidden only="xs"><FormGrid item sm={6} /></Hidden>
            <FormGrid item xs={12}>
              <Typography>
                Pharmacy details (for scripts to be sent)
              </Typography>
            </FormGrid>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="pharmacy_name"
                component={renderInput}
                label="Pharmacy Name"
                validate={[required]}
                required
              />
            </FormGrid>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="pharmacy_phone"
                component={renderInput}
                label="Pharmacy Phone"
                validate={[validPhoneNumber, required]}
                required
                type="tel"
              />
            </FormGrid>
            <FormGrid item xs={12} sm={4}>
              <Field
                name="pharmacy_fax"
                component={renderInput}
                label="Pharmacy Fax (if known)"
                validate={[validFaxNumber]}
              />
            </FormGrid>
            <FormGrid item xs={12}>
              <Typography>
                Pharmacy address
              </Typography>
            </FormGrid>
            <FormSection name="pharmacy_address">
              <SectionAddress
                name="pharmacy_address"
                onSelectSuburb={handleSelectSuburb}
              />
            </FormSection>
            <FormGrid item xs={12} className={styles.telehealthAgreement}>
              <Typography variant="subtitle2" style={{ marginTop: '10px' }}>Patient Agreement: Telehealth Consultations</Typography>
              <TelehealthTerms />
            </FormGrid>
            <FormGrid item xs={12}>
              <Typography component="span">
                Patient/Authorised Person
              </Typography>
            </FormGrid>
            <FormGrid item xs={12} sm={6}>
              <Field
                name="first_name"
                component={renderInput}
                label="First name"
                validate={[required, validFirstName]}
                required
                autoComplete="given-name"
              />
            </FormGrid>
            <FormGrid item xs={12} sm={6}>
              <Field
                name="family_name"
                component={renderInput}
                label="Last name"
                validate={[required, validLastName]}
                required
                autoComplete="family-name"
              />
            </FormGrid>
            <FormGrid item xs={12} sm={6}>
              <Field
                label="I have read and agree to the above*"
                component={renderCheckBox}
                name="has_agreed"
                validate={[requiredWithMessage('You must agree to continue')]}
                onChange={setDateTimeAndVersion}
              />
            </FormGrid>
          </Fragment>
            }
      </FormGrid>
    </FormSection>
  );
};

Teleheath.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
};

Teleheath.defaultProps = {
  formData: {},
};

export default Teleheath;
